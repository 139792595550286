import React, { useState, useEffect, useRef} from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../auth/userProvider/AuthProvider';
import QrScanner from 'qr-scanner';
import { doc, getDoc, addDoc, updateDoc, collection } from 'firebase/firestore';
import { firestore } from '../../../firebase';
import { IoCheckmarkCircle, IoCloseCircle, IoWarning } from 'react-icons/io5'; // Importing additional icon for warnings

QrScanner.WORKER_PATH = null;

const QRScanner = () => {
  const [verificationResult, setVerificationResult] = useState('Ready to scan...');
  const [ticketDetails, setTicketDetails] = useState(null);
  const [scanning, setScanning] = useState(true);
  const [scannedOrderId, setScannedOrderId] = useState(null);
  const videoRef = useRef(null);
  const qrScannerRef = useRef(null);
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  useEffect(() => {
    const initializeScanner = async () => {
      if (scanning && videoRef.current) {
        try {
          qrScannerRef.current = new QrScanner(
            videoRef.current,
            result => handleScan(result),
            {
              highlightScanRegion: true,
              highlightCodeOutline: true,
              maxScansPerSecond: 3,
              preferredCamera: 'environment',
            }
          );
          await qrScannerRef.current.start();
        } catch (error) {
          console.error('Failed to start QR scanner:', error);
          setVerificationResult('Error starting QR scanner. Please check camera permissions.');
        }
      }
    };

    initializeScanner();

    return () => {
      if (qrScannerRef.current) {
        qrScannerRef.current.destroy();
      }
    };
  }, [scanning]);

  const handleScan = async (result) => {
    if (result) {
      setScanning(false);
      qrScannerRef.current.stop();

      try {
        const qrData = JSON.parse(result.data);
        setScannedOrderId(qrData.id);
        const ticketRef = doc(firestore, 'payments', qrData.id);
        const ticketDoc = await getDoc(ticketRef);

        if (ticketDoc.exists()) {
          const data = ticketDoc.data();

          // Check if the current user is the organizer of the event
          if (data.organizerId !== currentUser.uid) {
            setVerificationResult('You do not have permission to verify this ticket.');
            return;
          }

          if (data.verified) {
            setVerificationResult('Ticket already verified. Please cross-check the user.');
          } else {
            // Update the ticket as verified in Firestore
            await updateDoc(ticketRef, { verified: true });

            // Update the local state with the updated data
            const updatedData = { ...data, verified: true };
            setTicketDetails(updatedData);

            // Log verification to verificationLogs collection with the updated verified status
            const logData = {
              customer_name: updatedData.user.name,
              orderId: scannedOrderId,
              user: updatedData.user,
              tickets: updatedData.tickets,
              qrCodeUrl: updatedData.qrCodeUrl,
              status: updatedData.status,
              verified: updatedData.verified,
              totalAmountPaid: updatedData.amount_total,
              created: updatedData.created,
              currency: updatedData.currency,
              customer_email: updatedData.user.email,
              customer_id: updatedData.user.uid,
              organizerId: updatedData.organizerId, // Ensure this is logged
              verificationTime: new Date(),
            };

            await addDoc(collection(firestore, 'verificationLogs'), logData);
            setVerificationResult('Ticket verified successfully!');
          }
        } else {
          setVerificationResult('No such ticket found.');
        }
      } catch (error) {
        console.error('Error processing QR code:', error);
        setVerificationResult('Error processing QR data. Please ensure the QR code is correct.');
      }
    }
  };

  console.log(ticketDetails);

  const resetScanner = () => {
    setScanning(true);
    setVerificationResult('Ready to scan...');
    setTicketDetails(null);
    setScannedOrderId(null);
  };

  return (
    <div className="min-h-screen w-full text-white flex p-2 justify-center bg-gray-900">
    <div className="w-full max-w-4xl mx-auto">
      <div className="bg-gray-800 rounded-lg shadow-xl p-6 md:p-8">
        <div className="flex flex-col md:flex-row md:space-x-8">
          <div className="w-full md:w-1/2 mb-6 md:mb-0">
            {scanning ? (
              <div className="relative aspect-square w-full">
                <video ref={videoRef} className="absolute inset-0 w-full h-full object-cover rounded-lg" />
                <div className="absolute inset-0 border-2 border-blue-500 opacity-50 rounded-lg"></div>
              </div>
            ) : (
              <button
                onClick={resetScanner}
                className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-4 px-6 rounded-lg shadow-lg transition duration-300 ease-in-out"
              >
                Scan Again
              </button>
            )}
            <p className="text-center text-sm mt-4 flex justify-center items-center gap-2">
              {verificationResult.includes('already') || verificationResult.includes('permission') ? (
                <IoWarning className="text-yellow-500 text-2xl" />
              ) : (
                <IoCheckmarkCircle className="text-green-500 text-2xl" />
              )}
              {verificationResult}
            </p>
          </div>
          
          <div className="w-full md:w-1/2">
            {ticketDetails ? (
              <div className="space-y-4">
                <h3 className="text-2xl font-bold text-blue-400">
                  {ticketDetails.user.eventTitle}
                </h3>
                <p className="text-lg text-gray-300">{ticketDetails.user.eventLocation}</p>
                <div className="grid grid-cols-2 gap-4 text-sm">
                  <div>
                    <p className="text-gray-400">Date</p>
                    <p className="font-semibold">{ticketDetails.user.eventDate}</p>
                  </div>
                  <div>
                    <p className="text-gray-400">Time</p>
                    <p className="font-semibold">{ticketDetails.user.eventTime}</p>
                  </div>
                  <div>
                    <p className="text-gray-400">Email</p>
                    <p className="font-semibold truncate">{ticketDetails.user.email}</p>
                  </div>
                  <div>
                    <p className="text-gray-400">Quantity</p>
                    <p className="font-semibold">{ticketDetails.tickets.reduce((total, ticket) => total + ticket.quantity, 0)}</p>
                  </div>
                </div>
                <div className="border-t border-gray-700 pt-4 mt-4">
                  <div className="flex justify-between items-center">
                    <span className="text-gray-400">Total Price</span>
                    <span className="text-xl font-bold text-green-400">
                      £{ticketDetails.amount_total} 
                    </span>
                  </div>
                </div>
                <div className="bg-gray-700 rounded-full px-4 py-2 inline-block">
                  <span className="text-sm font-medium">
                    Status: {ticketDetails.status.charAt(0).toUpperCase() + ticketDetails.status.slice(1)}
                  </span>
                </div>
              </div>
            ) : (
              <div className="flex items-center justify-center h-full">
                <p className="text-lg text-gray-400">Scan a QR code to view ticket details</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <button
        onClick={() => { navigate('/business/events/verificaton-logs') }}
        className="w-full bg-red-600 hover:bg-red-700 text-white font-bold py-4 px-6 rounded-lg shadow-lg transition duration-300 ease-in-out mt-2"
      >
        View Logs
      </button>
    </div>
  </div>
  
  );
};

export default QRScanner;
