// src/slices/restaurantsSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import restaurants from '../../components/Student/Explore/list_of_resturants.json';

// Async thunk for fetching restaurants from JSON
const fetchRestaurants = createAsyncThunk('restaurants/fetchRestaurants', async () => {
  // If you later switch to Firebase, uncomment the below code
  /*
  const querySnapshot = await getDocs(collection(firestore, 'restaurants'));
  const fetchedRestaurants = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  return fetchedRestaurants;
  */

  // Returning the imported JSON data instead for now
  return restaurants;
});

// Create restaurants slice
const restaurantsSlice = createSlice({
  name: 'restaurants',
  initialState: {
    list: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRestaurants.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchRestaurants.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.list = action.payload;
      })
      .addCase(fetchRestaurants.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

// Export the reducer and the fetch function
export default restaurantsSlice.reducer;
export { fetchRestaurants };  // Export the fetchRestaurants thunk
