import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Line, Pie, PolarArea } from 'react-chartjs-2';
import { Gauge  } from 'react-circular-gauge';
import 'chart.js/auto';

const WeatherDashboard = () => {
  const [weatherData, setWeatherData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // API endpoint (replace with your API key if necessary)
  const encodedUrl = encodeURIComponent('https://api.weatherapi.com/v1/current.json?key=fdc33007428f499db1c101209242109&q=london&aqi=no');
  const API_URL = `https://api.allorigins.win/get?url=${encodedUrl}`;
  
  useEffect(() => {
    axios.get(API_URL)
      .then(response => {
        // AllOrigins wraps the response in a `contents` property
        const data = JSON.parse(response.data.contents);
        setWeatherData(data);
        setLoading(false);
      })
      .catch(error => {
        setError('Failed to fetch weather data');
        setLoading(false);
      });
  }, []);
  

  // Show loading message
  if (loading) {
    return <div className="text-center p-6">Loading weather data...</div>;
  }

  // Show error message if the fetch fails
  if (error) {
    return <div className="text-center p-6 text-red-500">{error}</div>;
  }

  const {
    location,
    current: {
      temp_c,
      feelslike_c,
      condition,
      wind_mph,
      wind_kph,
      wind_degree,
      wind_dir,
      gust_mph,
      gust_kph,
      pressure_mb,
      pressure_in,
      precip_mm,
      precip_in,
      humidity,
      cloud,
      windchill_c,
      windchill_f,
      heatindex_c,
      heatindex_f,
      dewpoint_c,
      dewpoint_f,
      vis_km,
      vis_miles,
      uv,
    },
  } = weatherData;

  // Temperature and Feels Like Gauge configurations
  const tempOptions = {
    value: temp_c,
    maxValue: 50,
    width: 150,
    height: 150,
    units: '°C',
    label: 'Temperature',
  };

  const feelsLikeOptions = {
    value: feelslike_c,
    maxValue: 50,
    width: 150,
    height: 150,
    units: '°C',
    label: 'Feels Like',
  };

  // Wind Data for Polar Area Chart
  const windData = {
    labels: ['Wind Speed (mph)', 'Wind Gusts (mph)'],
    datasets: [
      {
        label: 'Wind Data',
        data: [wind_mph, gust_mph],
        backgroundColor: ['rgba(75, 192, 192, 0.6)', 'rgba(153, 102, 255, 0.6)'],
      },
    ],
  };

  // Humidity and Cloud Cover for Pie Chart
  const pieData = {
    labels: ['Humidity %', 'Cloud Cover %'],
    datasets: [
      {
        data: [humidity, cloud],
        backgroundColor: ['#36A2EB', '#FFCE56'],
        hoverBackgroundColor: ['#36A2EB', '#FFCE56'],
      },
    ],
  };

  // Pressure Data for Line Chart
  const pressureData = {
    labels: ['Pressure (mb)', 'Pressure (in)'],
    datasets: [
      {
        label: 'Pressure',
        data: [pressure_mb, pressure_in],
        fill: false,
        borderColor: 'rgba(75,192,192,1)',
        lineTension: 0.1,
      },
    ],
  };

  // Precipitation Data for Polar Area Chart
  const precipData = {
    labels: ['Precipitation (mm)', 'Precipitation (in)'],
    datasets: [
      {
        label: 'Precipitation',
        data: [precip_mm, precip_in],
        backgroundColor: ['rgba(75, 192, 192, 0.6)', 'rgba(255, 99, 132, 0.6)'],
      },
    ],
  };

  // Visibility Data for Polar Area Chart
  const visibilityData = {
    labels: ['Visibility (km)', 'Visibility (miles)'],
    datasets: [
      {
        label: 'Visibility',
        data: [vis_km, vis_miles],
        backgroundColor: ['rgba(75, 192, 192, 0.6)', 'rgba(255, 206, 86, 0.6)'],
      },
    ],
  };

  return (
    <div className="max-w-screen-xl mx-auto p-6 bg-white rounded-lg shadow-lg">
      {/* Location Information */}
      <div className="mb-6 text-center">
        <h2 className="text-3xl font-bold">{`Weather Dashboard: ${location.name}, ${location.region}, ${location.country}`}</h2>
        <p className="text-lg text-gray-500">{`Latitude: ${location.lat}, Longitude: ${location.lon}`}</p>
        <p className="text-lg text-gray-500">{`Local Time: ${location.localtime}`}</p>
      </div>

      {/* Temperature, Feels Like, and Condition */}
      <div className="flex justify-center space-x-10 mb-10">
        <div className="text-center">
          <Gauge  {...tempOptions} />
          <p className="mt-2 text-lg font-medium">Temperature (°C)</p>
        </div>
        <div className="text-center">
          <Gauge  {...feelsLikeOptions} />
          <p className="mt-2 text-lg font-medium">Feels Like (°C)</p>
        </div>
        <div className="text-center">
          <img src={condition.icon} alt={condition.text} className="inline-block w-16 h-16" />
          <p className="mt-2 text-lg font-medium">{condition.text}</p>
        </div>
      </div>

      {/* Wind Data in Polar Area Chart */}
      <div className="mb-10 text-center">
        <h3 className="text-2xl font-semibold mb-4">Wind Information</h3>
        <PolarArea data={windData} />
        <p className="mt-2 text-lg text-gray-600">{`Wind Direction: ${wind_dir} (${wind_degree}°)`}</p>
        <p className="mt-2 text-lg text-gray-600">{`Wind Speed: ${wind_mph} mph (${wind_kph} kph)`}</p>
      </div>

      {/* Humidity and Cloud Cover in Pie Chart */}
      <div className="flex justify-center space-x-10 mb-10">
        <div className="text-center">
          <h3 className="text-2xl font-semibold mb-4">Humidity and Cloud Cover</h3>
          <Pie data={pieData} />
        </div>
      </div>

      {/* Pressure and Precipitation Data */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-10 mb-10">
        <div className="text-center">
          <h3 className="text-2xl font-semibold mb-4">Pressure</h3>
          <Line data={pressureData} />
        </div>
        <div className="text-center">
          <h3 className="text-2xl font-semibold mb-4">Precipitation</h3>
          <PolarArea data={precipData} />
        </div>
      </div>

      {/* Visibility Data */}
      <div className="text-center mb-10">
        <h3 className="text-2xl font-semibold mb-4">Visibility</h3>
        <PolarArea data={visibilityData} />
      </div>

      {/* UV Index, Dew Point, and Wind Chill */}
      <div className="flex justify-center space-x-10">
        <div className="text-center">
          <h3 className="text-2xl font-semibold mb-4">UV Index</h3>
          <p className="text-lg font-medium text-gray-700">{uv}</p>
        </div>
        <div className="text-center">
          <h3 className="text-2xl font-semibold mb-4">Dew Point</h3>
          <p className="text-lg font-medium text-gray-700">{`${dewpoint_c}°C (${dewpoint_f}°F)`}</p>
        </div>
        <div className="text-center">
          <h3 className="text-2xl font-semibold mb-4">Wind Chill</h3>
          <p className="text-lg font-medium text-gray-700">{`${windchill_c}°C (${windchill_f}°F)`}</p>
        </div>
      </div>

      {/* Heat Index */}
      <div className="text-center mt-10">
        <h3 className="text-2xl font-semibold mb-4">Heat Index</h3>
        <p className="text-lg font-medium text-gray-700">{`${heatindex_c}°C (${heatindex_f}°F)`}</p>
      </div>
    </div>
  );
};

export default WeatherDashboard;
