import React, { useEffect, useState } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { useAuth } from '../../../auth/userProvider/AuthProvider';
import TicketCard from './TicketCard';
import { firestore } from '../../../firebase';
import { ClipLoader } from 'react-spinners';

const MyBookings = () => {
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const { currentUser } = useAuth();

  useEffect(() => {
    const fetchBookings = async () => {
      if (!currentUser) return;

      try {
        const q = query(
          collection(firestore, 'transactions'),
          where('checkout.customerId', '==', currentUser.uid)
        );
        const querySnapshot = await getDocs(q);

        const fetchedBookings = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        setBookings(fetchedBookings);
      } catch (error) {
        console.error('Error fetching bookings:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBookings();
  }, [currentUser]);

  return (
    <div className="bg-gray-900 min-h-screen">
      <h1 className="text-2xl text-white sticky font-bold mb-6">My Bookings</h1>
      {loading ? (
        <div className="flex justify-center items-center h-64">
          <ClipLoader color="#fff" loading={loading} size={50} />
        </div>
      ) : bookings.length > 0 ? (
        bookings.map(booking => (
          <div key={booking.id} className="mb-4">
            <TicketCard
              ticketId={booking.transactionId || booking.id}
              date={booking.eventDetails?.eventDate || 'N/A'}
              month={new Date(booking.checkout.created?.toDate()).toLocaleDateString('en-US', { month: 'short' })}
              eventName={booking.eventDetails?.eventTitle || 'Event Title Unavailable'}
              eventDay={booking.eventDetails?.eventDate || 'N/A'}
              eventTime={booking.eventDetails?.eventTime || 'Time Unavailable'}
              location={booking.eventDetails?.eventLocation || 'Location Unavailable'}
              qrCodeUrl={booking.qrCodeUrl} 
              booking_time={booking.checkout.created}
              status={booking.status || 'N/A'}
            />
          </div>
        ))  
      ) : (
        <p className="text-white">No bookings found.</p>
      )}
    </div>
  );
};

export default MyBookings;
