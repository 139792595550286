import React, { useMemo } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// Load the Stripe public key from environment variables
const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;

const StripeProvider = ({ children }) => {
  // Use useMemo to avoid recreating the stripePromise on every render
  const stripePromise = useMemo(() => {
    if (!stripePublicKey) {
      console.error("Stripe public key is missing. Please check your environment variables.");
      return null; // Optionally handle the case where the key is missing
    }
    return loadStripe(stripePublicKey);
  }, [stripePublicKey]);

  // Render the Elements component only if the stripePromise is valid
  return stripePromise ? (
    <Elements stripe={stripePromise}>
      {children}
    </Elements>
  ) : (
    <div>Error loading payment gateway. Please try again later.</div> // Handle missing or invalid stripePromise
  );
};

export default StripeProvider;
