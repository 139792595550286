export const updated_acc=[
    {
        "city": "Bangor",
        "location": "Bangor",
        "room_name": "Ty Willis House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2020-12/iQ-Student-Accommodation-Bangor-Ty-Willis-Bedrooms-Copper_En_Suite%282%29.jpg?itok=49ogZ2cQ",
        "room_link": "https://www.iqstudentaccommodation.com/bangor/ty-willis-house/copper-en-suite-plus",
        "details": [
            "Private room",
            "Small double bed",
            "13 m²",
            "Private bathroom",
            "From £105 / week"
        ],
        "id": "75cd02fdbbf14352a0f4158894bbcf14"
    },
    {
        "city": "Bangor",
        "location": "Bangor",
        "room_name": "Ty Willis House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2020-12/iQ-Student-Accommodation-Bangor-Ty-Willis-Bedrooms-Copper_En_Suite%282%29.jpg?itok=49ogZ2cQ",
        "room_link": "https://www.iqstudentaccommodation.com/bangor/ty-willis-house/copper-en-suite",
        "details": [
            "Private room",
            "Small double bed",
            "13 m²",
            "Private bathroom",
            "From £114 / week"
        ],
        "id": "c297577181744c9ba407944437bfec15"
    },
    {
        "city": "Bangor",
        "location": "Bangor",
        "room_name": "Ty Willis House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-01/iQ-Student-Accommodation-Bangor-Ty-Willis-Bedrooms-Bronze_En_Suite_Single%285%29.jpg?itok=52Z09xa_",
        "room_link": "https://www.iqstudentaccommodation.com/bangor/ty-willis-house/bronze-en-suite-single",
        "details": [
            "Private room",
            "Single bed",
            "12 m²",
            "Private bathroom",
            "From £125 / week"
        ],
        "id": "b54e3a30d45c43879d71239b0323b668"
    },
    {
        "city": "Bangor",
        "location": "Bangor",
        "room_name": "Ty Willis House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2020-12/iQ-Student-Accommodation-Bangor-Ty-Willis-Bedrooms-Bronze_En_Suite%284%29.jpg?itok=8WS19F_M",
        "room_link": "https://www.iqstudentaccommodation.com/bangor/ty-willis-house/bronze-en-suite",
        "details": [
            "Private room",
            "Small double bed",
            "13 m²",
            "Private bathroom",
            "From £133 / week"
        ],
        "id": "87aa4d9ed3f24f48b80a4493be2cc070"
    },
    {
        "city": "Bangor",
        "location": "Bangor",
        "room_name": "Ty Willis House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2020-12/iQ-Student-Accommodation-Bangor-Ty-Willis-Bedrooms-Bronze_En_Suite%284%29.jpg?itok=8WS19F_M",
        "room_link": "https://www.iqstudentaccommodation.com/bangor/ty-willis-house/bronze-en-suite-premium",
        "details": [
            "Private room",
            "Small double bed",
            "13 m²",
            "Private bathroom",
            "From £133 / week"
        ],
        "id": "e5387e70d4894906832978e7bc7c6a89"
    },
    {
        "city": "Bangor",
        "location": "Bangor",
        "room_name": "Ty Willis House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2020-12/iQ-Student-Accommodation-Bangor-Ty-Willis-Bedrooms-Bronze_En_Suite%284%29.jpg?itok=8WS19F_M",
        "room_link": "https://www.iqstudentaccommodation.com/bangor/ty-willis-house/bronze-en-suite-deluxe",
        "details": [
            "Private room",
            "Small double bed",
            "13 m²",
            "Private bathroom",
            "From £135 / week"
        ],
        "id": "cea2a02fd0bb49ec980099909cc753cd"
    },
    {
        "city": "Bangor",
        "location": "Bangor",
        "room_name": "Ty Willis House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-11/iQ-Student-Accommodation-Bangor-Ty-Willis-Bedrooms-Bronze_En_Suite%283%29.jpg?itok=0PnGofP0",
        "room_link": "https://www.iqstudentaccommodation.com/bangor/ty-willis-house/bronze-en-suite-plus",
        "details": [
            "Private room",
            "Small double bed",
            "13 m²",
            "Private bathroom",
            "From £135 / week"
        ],
        "id": "0355c2f35aae4c20877007132881215c"
    },
    {
        "city": "Bangor",
        "location": "Bangor",
        "room_name": "Ty Willis House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2020-12/iQ-Student-Accommodation-Bangor-Ty-Willis-Bedrooms-Silver_En_Suite%283%29.jpg?itok=y_3kWibj",
        "room_link": "https://www.iqstudentaccommodation.com/bangor/ty-willis-house/silver-en-suite-plus",
        "details": [
            "Private room",
            "Small double bed",
            "15 m²",
            "Private bathroom",
            "From £143 / week"
        ],
        "id": "afa06f21161041308ab12c35f4a2d57c"
    },
    {
        "city": "Bangor",
        "location": "Bangor",
        "room_name": "Ty Willis House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2020-12/iQ-Student-Accommodation-Bangor-Ty-Willis-Bedrooms-Silver_En_Suite%283%29.jpg?itok=y_3kWibj",
        "room_link": "https://www.iqstudentaccommodation.com/bangor/ty-willis-house/silver-en-suite",
        "details": [
            "Private room",
            "Small double bed",
            "15 m²",
            "Private bathroom",
            "From £155 / week"
        ],
        "id": "43e9acd613104b6f8f2885540ffdb201"
    },
    {
        "city": "Bangor",
        "location": "Bangor",
        "room_name": "Ty Willis House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2020-12/iQ-Student-Accommodation-Bangor-Ty-Willis-Bedrooms-Gold_En_Suite%282%29.jpg?itok=TlmXHS8y",
        "room_link": "https://www.iqstudentaccommodation.com/bangor/ty-willis-house/gold-en-suite",
        "details": [
            "Private room",
            "Small double bed",
            "18 m²",
            "Private bathroom",
            "From £166 / week"
        ],
        "id": "65c86de98dd44172b6e4a518563f1bb3"
    },
    {
        "city": "Bangor",
        "location": "Bangor",
        "room_name": "Ty Willis House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2020-12/iQ-Student-Accommodation-Bangor-Ty-Willis-Bedrooms-Platinum_En_Suite%282%29.jpg?itok=loPDXj4Y",
        "room_link": "https://www.iqstudentaccommodation.com/bangor/ty-willis-house/platinum-en-suite",
        "details": [
            "Private room",
            "Small double bed",
            "19-21 m²",
            "Private bathroom",
            "From £175 / week"
        ],
        "id": "c83ddecf954a483388cf4653cae7559f"
    },
    {
        "city": "Bangor",
        "location": "Bangor",
        "room_name": "Ty Willis House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2020-12/iQ-Student-Accommodation-Bangor-Ty-Willis-Bedrooms-Bronze_Studio%284%29.jpg?itok=vf4MVegP",
        "room_link": "https://www.iqstudentaccommodation.com/bangor/ty-willis-house/bronze-studio",
        "details": [
            "Private studio",
            "Double bed",
            "15 m²",
            "Kitchenette",
            "From £183 / week"
        ],
        "id": "e2e3f310d04242cb917e379c34de0d0d"
    },
    {
        "city": "Bath",
        "location": "Bath",
        "room_name": "Twerton Mill",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-07/iQ-Student-Accomodation-Bath-Twerton-Mill-Bedrooms-Bronze_Studio_B305%283%29.jpg?itok=-JaZubtD",
        "room_link": "https://www.iqstudentaccommodation.com/bath/twerton-mill/studio",
        "details": [
            "Private studio",
            "Small double bed",
            "Private bathroom",
            "Private kitchen",
            ""
        ],
        "id": "6e224f4e5dcc4cc38a863a395cb53313"
    },
    {
        "city": "Bath",
        "location": "Bath",
        "room_name": "Twerton Mill",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2018-08/ShowFlat-Studio2_3.jpg?itok=FUFAUdDd",
        "room_link": "https://www.iqstudentaccommodation.com/bath/twerton-mill/accessible-studio",
        "details": [
            "Private studio",
            "Small double bed",
            "Study desk and chair",
            "Underbed storage",
            ""
        ],
        "id": "0277d49c4f324c29b6221bd750f3804a"
    },
    {
        "city": "Bath",
        "location": "Bath",
        "room_name": "Twerton Mill",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-07/iQ-Student-Accomodation-Bath-Twerton-Mill-Bedrooms-Silver_En_Suite_B202E%285%29_5.jpg?itok=3i43eJbU",
        "room_link": "https://www.iqstudentaccommodation.com/bath/twerton-mill/en-suite",
        "details": [
            "Private room",
            "Small double bed",
            "Private bathroom",
            "Shared kitchen",
            ""
        ],
        "id": "8939f4fb394f4647b459ee986b46199e"
    },
    {
        "city": "Bath",
        "location": "Bath",
        "room_name": "Twerton Mill",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2018-08/EnsuiteRoom.jpg?itok=1bpHvrxD",
        "room_link": "https://www.iqstudentaccommodation.com/bath/twerton-mill/townhouse-shared-bathroom",
        "details": [
            "Private room",
            "Small double bed",
            "Shared bathroom",
            "Study desk and chair",
            ""
        ],
        "id": "1e9a52da6dfe40919ee4ffe2e5a563dd"
    },
    {
        "city": "Birmingham",
        "location": "Birmingham",
        "room_name": "Penworks House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2023-04/iQ-Student-Accommodation-Birmingham-Penworks-House-Bedrooms-Copper-Studio-Bronze_En_Suite_Plus_0.jpg?itok=WhVx2HsO",
        "room_link": "https://www.iqstudentaccommodation.com/birmingham/penworks-house/bronze-en-suite-plus",
        "details": [
            "Private room",
            "Small double bed",
            "Shared kitchen",
            "Private bathroom",
            "From £201 / week"
        ],
        "id": "ea048208a61a4955842eb6050497c35e"
    },
    {
        "city": "Birmingham",
        "location": "Birmingham",
        "room_name": "Penworks House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-02/iQ-Student-Accommodation-Birmingham-Penworks-House-Bedrooms-Copper-Studio-623%283%29.jpg?itok=t-2BthbW",
        "room_link": "https://www.iqstudentaccommodation.com/birmingham/penworks-house/silver-en-suite-premium",
        "details": [
            "Limited availability",
            "Private room",
            "Small double bed",
            "13 m²",
            "Private bathroom",
            "From £213 / week"
        ],
        "id": "4e88140820c5470da48667259af84e55"
    },
    {
        "city": "Birmingham",
        "location": "Birmingham",
        "room_name": "Penworks House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2023-04/iQ-Student-Accommodation-Birmingham-Penworks-House-Bedrooms-Copper-Studio-Platinum_En_Suite_Plus.jpg?itok=r3sQ0lpF",
        "room_link": "https://www.iqstudentaccommodation.com/birmingham/penworks-house/platinum-en-suite-plus",
        "details": [
            "Limited availability",
            "Private room",
            "Small double bed",
            "Private bathroom",
            "TV in shared space",
            "From £233 / week"
        ],
        "id": "ab63aa932fd94ab3bd9ffbe25f45e486"
    },
    {
        "city": "Birmingham",
        "location": "Birmingham",
        "room_name": "Penworks House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-02/IQ5CAA~1.JPG?itok=fUy9j93e",
        "room_link": "https://www.iqstudentaccommodation.com/birmingham/penworks-house/platinum-en-suite-premium",
        "details": [
            "Limited availability",
            "Private room",
            "Small double bed",
            "Shared kitchen",
            "Private bathroom",
            "From £206 / week"
        ],
        "id": "ac3f23e3bfad4661878910454737e8a7"
    },
    {
        "city": "Birmingham",
        "location": "Birmingham",
        "room_name": "Penworks House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-02/iQ-Student-Accommodation-Birmingham-Penworks-House-Bedrooms-Copper-Studio-Copper_Studio_Plus_0.jpg?itok=dB18pF4L",
        "room_link": "https://www.iqstudentaccommodation.com/birmingham/penworks-house/copper-studio",
        "details": [
            "Limited availability",
            "Private studio",
            "Small double bed",
            "17 m²",
            "TV in bedroom",
            "From £228 / week"
        ],
        "id": "95868691237b43dc94343602e91d02a3"
    },
    {
        "city": "Birmingham",
        "location": "Birmingham",
        "room_name": "Penworks House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-02/iQ-Student-Accommodation-Birmingham-Penworks-House-Bedrooms-Copper-Studio-Silver_En_Suite_Plus%285%29_1.jpg?itok=swVYWdd0",
        "room_link": "https://www.iqstudentaccommodation.com/birmingham/penworks-house/silver-en-suite-plus",
        "details": [
            "Limited availability",
            "Private room",
            "Small double bed",
            "Private bathroom",
            "Shared kitchen",
            "From £207 / week"
        ],
        "id": "a222796ddc1b40f3b740c67f9bb0895e"
    },
    {
        "city": "Birmingham",
        "location": "Birmingham",
        "room_name": "Penworks House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-11/iQ-Student-Accomodation-Birmingham-Broderick-House-Bedrooms-Level-4-138d%283%29.jpg?itok=S_I36Pb9",
        "room_link": "https://www.iqstudentaccommodation.com/birmingham/broderick-house/platinum-en-suite",
        "details": [
            "Limited availability",
            "Private room",
            "Small double bed",
            "Newly refurbished",
            "Canal view",
            "From £205 / week"
        ],
        "id": "a32c71fdd67044ff9788e7942de44c8f"
    },
    {
        "city": "Birmingham",
        "location": "Birmingham",
        "room_name": "Penworks House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-11/IQ-STU~3_2.JPG?itok=x3xm3wcD",
        "room_link": "https://www.iqstudentaccommodation.com/birmingham/broderick-house/diamond-en-suite",
        "details": [
            "Limited availability",
            "Private room",
            "Small double bed",
            "Newly refurbished",
            "Dual aspect view",
            "From £206 / week"
        ],
        "id": "7862f2013668439fb148c8969411e3a5"
    },
    {
        "city": "Birmingham",
        "location": "Birmingham",
        "room_name": "Penworks House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-02/iQ-Student-Accommodation-Birmingham-Studio-51-Bedrooms-2_Bed_Apartment%284%29.jpg?itok=922KrbyK",
        "room_link": "https://www.iqstudentaccommodation.com/birmingham/studios-51/two-bed-apartment",
        "details": [
            "Limited availability",
            "Twin room",
            "Small double bed",
            "10 m²",
            "Shared bathroom",
            "From £195 / week"
        ],
        "id": "0627a4d190f04a7c9641fd740c5a4cac"
    },
    {
        "city": "Birmingham",
        "location": "Birmingham",
        "room_name": "Penworks House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2023-04/iQ-Student-Accommodation-Birmingham-Studio-51-Bedrooms-Gold_Studio%289%29.jpg?itok=54-qTf9n",
        "room_link": "https://www.iqstudentaccommodation.com/birmingham/studios-51/gold-studio",
        "details": [
            "Limited availability",
            "Private studio",
            "Small double bed",
            "Accessible bedroom available",
            "Private bathroom",
            "From £236 / week"
        ],
        "id": "91d5f919d27b470a88871c8e2042a005"
    },
    {
        "city": "Birmingham",
        "location": "Birmingham",
        "room_name": "Penworks House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-02/iQ-Student-Accommodation-Birmingham-Penworks-House-Bedrooms-Copper-Studio-Bronze_En_Suite%285%29.jpg?itok=nAgp0vo4",
        "room_link": "https://www.iqstudentaccommodation.com/birmingham/penworks-house/bronze-en-suite",
        "details": [
            "Private room",
            "Small double bed",
            "Private bathroom",
            "Shared kitchen",
            "From £179 / week"
        ],
        "id": "7d0e50790f6f4f42a99c6e24a4cc7f51"
    },
    {
        "city": "Birmingham",
        "location": "Birmingham",
        "room_name": "Penworks House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2023-04/IQ-STU_3.jpg?itok=QD1lQtse",
        "room_link": "https://www.iqstudentaccommodation.com/birmingham/penworks-house/bronze-en-suite-premium/",
        "details": [
            "Private room",
            "Small double bed",
            "Study desk and chair",
            "Underbed storage",
            "From £185 / week"
        ],
        "id": "f0531ff9279f473c83d3782b6caaa568"
    },
    {
        "city": "Bradford",
        "location": "Bradford",
        "room_name": "Arkwright House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2023-07/iQ-Student-Accommodation-Bradford-Arkwright-House-02212023_161643_0.jpg?itok=1egJHM5x",
        "room_link": "https://www.iqstudentaccommodation.com/bradford/arkwright-house/bronze-en-suite",
        "details": [
            "Limited availability",
            "Private room",
            "Small double bed",
            "13 m²",
            "Private bathroom",
            "From £96 / week"
        ],
        "id": "14bc84c275284680be4bf00c50bdb1b3"
    },
    {
        "city": "Bradford",
        "location": "Bradford",
        "room_name": "Arkwright House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2020-11/iQ-Student-Accommodation-Bradford-Arkwright-Bedrooms-Silver_En_Suite%283%29_0.jpg?itok=NDrtxLQ4",
        "room_link": "https://www.iqstudentaccommodation.com/bradford/arkwright-house/silver-en-suite",
        "details": [
            "Limited availability",
            "Private room",
            "Small double bed",
            "13 m²",
            "Private bathroom",
            "From £100 / week"
        ],
        "id": "3cca0aa1a2e04859a5ec3b58a49365f5"
    },
    {
        "city": "Bradford",
        "location": "Bradford",
        "room_name": "Arkwright House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2023-07/iQ-Student-Accommodation-Bradford-Arkwright-House-02212023_161539.jpg?itok=nQuYuyDy",
        "room_link": "https://www.iqstudentaccommodation.com/bradford/arkwright-house/bronze-en-suite-plus",
        "details": [
            "Limited availability",
            "Private room",
            "Double bed",
            "14-16 m²",
            "Accessible bedroom available",
            "From £102 / week"
        ],
        "id": "76cef2ad9a014538b260b6306514d96b"
    },
    {
        "city": "Bradford",
        "location": "Bradford",
        "room_name": "Arkwright House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2024-08/C25B.jpg?itok=wyuyCzoG",
        "room_link": "https://www.iqstudentaccommodation.com/bradford/arkwright-house/silver-en-suite-premium",
        "details": [
            "Limited availability",
            "Private room",
            "Small double bed",
            "13 m²",
            "Private bathroom",
            "From £102 / week"
        ],
        "id": "2c6776d398a745da9edfcd1e964eddaf"
    },
    {
        "city": "Bradford",
        "location": "Bradford",
        "room_name": "Arkwright House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2023-07/iQ-Student-Accommodation-Bradford-Arkwright-House-02212023_161757_1.jpg?itok=RFyjpMEJ",
        "room_link": "https://www.iqstudentaccommodation.com/bradford/arkwright-house/gold-en-suite",
        "details": [
            "Private room",
            "Small double bed",
            "13 m²",
            "Private bathroom",
            "From £110 / week"
        ],
        "id": "0f71acd7ce8c4402907fee2b38c4049b"
    },
    {
        "city": "Bradford",
        "location": "Bradford",
        "room_name": "Arkwright House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2020-11/iQ-Student-Accommodation-Bradford-Arkwright-Bedrooms-Silver_En_Suite_Plus%282%29.jpg?itok=NghfKFnC",
        "room_link": "https://www.iqstudentaccommodation.com/bradford/arkwright-house/silver-en-suite-plus",
        "details": [
            "Limited availability",
            "Private room",
            "Double bed",
            "14-16 m²",
            "Private bathroom",
            "From £115 / week"
        ],
        "id": "455d715678404cb28c8595b064abd8ab"
    },
    {
        "city": "Bradford",
        "location": "Bradford",
        "room_name": "Arkwright House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2024-08/25A-10_2.jpg?itok=sxN98qh_",
        "room_link": "https://www.iqstudentaccommodation.com/bradford/arkwright-house/silver-en-suite-deluxe",
        "details": [
            "Limited availability",
            "Private room",
            "Double bed",
            "14-16 m²",
            "Private bathroom",
            "From £117 / week"
        ],
        "id": "da58a1c6b03549aa91697d78a33ef0af"
    },
    {
        "city": "Bradford",
        "location": "Bradford",
        "room_name": "Arkwright House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2024-08/E12A-2_0.jpg?itok=x5ZTFvaP",
        "room_link": "https://www.iqstudentaccommodation.com/bradford/arkwright-house/gold-en-suite-plus",
        "details": [
            "Limited availability",
            "Private room",
            "Double bed",
            "14-16 m²",
            "Private bathroom",
            "From £123 / week"
        ],
        "id": "e3be2fd54f1c43dd94489b567f05ad08"
    },
    {
        "city": "Bradford",
        "location": "Bradford",
        "room_name": "Arkwright House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2020-11/iQ-Student-Accommodation-Bradford-Arkwright-Bedrooms-Bronze_Studio%283%29.jpg?itok=LkxfY_GM",
        "room_link": "https://www.iqstudentaccommodation.com/bradford/arkwright-house/bronze-studio",
        "details": [
            "Private studio",
            "Double bed",
            "18-21 m²",
            "TV in bedroom",
            "From £209 / week"
        ],
        "id": "feab4a98769a422ebf1f970e2de0c920"
    },
    {
        "city": "Brighton",
        "location": "Brighton",
        "room_name": "Abacus House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2024-09/56-1-10.jpg?itok=3CWwmQ4L",
        "room_link": "https://www.iqstudentaccommodation.com/brighton/abacus-house/silver-en-suite",
        "details": [
            "Private room",
            "Small double bed",
            "15-16 m²",
            "Newly refurbished",
            "From £290 / week"
        ],
        "id": "9f2220700e894c42901aee18afb8d99a"
    },
    {
        "city": "Brighton",
        "location": "Brighton",
        "room_name": "Abacus House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2022-02/iQ-Student-Accommodation-Egham-Podium-Bedrooms-Duplicate-Rooms-W0014-S011-IL03-S302-Large_Two_Bedroom_Apartment%288%29_0.jpg?itok=XpEX48FU",
        "room_link": "https://www.iqstudentaccommodation.com/brighton/iq-brighton/silver-two-bedroom-apartment",
        "details": [
            "Limited availability",
            "Private apartment",
            "Small double bed",
            "35-39 m²",
            "Shared bathroom",
            "From £290 / week"
        ],
        "id": "342b3ba42980466794424f8bf5032290"
    },
    {
        "city": "Brighton",
        "location": "Brighton",
        "room_name": "Abacus House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2022-02/iQ-Student-Accommodation-Egham-Podium-Bedrooms-Standard_Two_Bedroom_Apartment%284%29_2.jpg?itok=3IMQF1H7",
        "room_link": "https://www.iqstudentaccommodation.com/brighton/iq-brighton/bronze-two-bed-apartment-0",
        "details": [
            "Limited availability",
            "Private apartment",
            "Small double bed",
            "39.2 m²",
            "Shared bathroom",
            "From £285 / week"
        ],
        "id": "d64f74a72ddf44c4b8e4673460a9622b"
    },
    {
        "city": "Brighton",
        "location": "Brighton",
        "room_name": "Abacus House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2020-12/iQ-Student-Accommodation-Brighton-Sawmills-Bedrooms-Gold_Studio%287%29.jpg?itok=yEpF7Wqs",
        "room_link": "https://www.iqstudentaccommodation.com/brighton/sawmills/gold-studio",
        "details": [
            "Limited availability",
            "Private studio",
            "Single bed",
            "11-12 m²",
            "Kitchenette",
            "From £305 / week"
        ],
        "id": "5ca24693c5a7455b8f1124df17673235"
    },
    {
        "city": "Brighton",
        "location": "Brighton",
        "room_name": "Abacus House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-03/iQ-Student-Accommodation-Brighton-Abacus-House-Bedrooms-Bronze_En_Suite_Plus%282%29_0.jpg?itok=2HE8PoRX",
        "room_link": "https://www.iqstudentaccommodation.com/brighton/abacus-house/bronze-en-suite-plus",
        "details": [
            "Limited availability",
            "Private room",
            "Small double bed",
            "13-14 m²",
            "Study desk and chair",
            "From £288 / week"
        ],
        "id": "c7de5899791f4113a0b4d3f6bc77219a"
    },
    {
        "city": "Brighton",
        "location": "Brighton",
        "room_name": "Abacus House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2022-02/iQ-Student-Accommodation-Brighton-Scape-Bedrooms-Standard_En_Suite%281%29_0.jpg?itok=lMzuXkhb",
        "room_link": "https://www.iqstudentaccommodation.com/brighton/iq-brighton/copper-en-suite",
        "details": [
            "Limited availability",
            "Private room",
            "Small double bed",
            "13.1 m²",
            "Shared kitchen",
            "From £258 / week"
        ],
        "id": "ed558c6e74c34207829073a1cef550a1"
    },
    {
        "city": "Brighton",
        "location": "Brighton",
        "room_name": "Abacus House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2022-02/iQ-Student-Accommodation-Brighton-Scape-Bedrooms-Large_King_Studio%284%29_0.jpg?itok=r07S1YwA",
        "room_link": "https://www.iqstudentaccommodation.com/brighton/iq-brighton/platinum-studio-plus",
        "details": [
            "Limited availability",
            "Private studio",
            "King size bed",
            "21.9 m²",
            "Dual occupancy available",
            "From £404 / week"
        ],
        "id": "11947e1567024c64b6466f018a036c53"
    },
    {
        "city": "Brighton",
        "location": "Brighton",
        "room_name": "Abacus House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2023-04/iQ-Student-Accommodation-Brighton-Sawmills-Bedrooms-Accessible_Gold_Studio%287%29.jpg?itok=oTw8YFfE",
        "room_link": "https://www.iqstudentaccommodation.com/brighton/sawmills/accessible-gold-studio",
        "details": [
            "Limited availability",
            "Private studio",
            "11-12 m²",
            "Wheelchair accessible",
            "Fully accessible",
            "From £305 / week"
        ],
        "id": "9181246aa92b4fa0a59b35f7a06c7cfe"
    },
    {
        "city": "Brighton",
        "location": "Brighton",
        "room_name": "Abacus House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2024-09/98-1-14_0.jpg?itok=OU8rqh5S",
        "room_link": "https://www.iqstudentaccommodation.com/brighton/abacus-house/bronze-en-suite-01",
        "details": [
            "Limited availability",
            "Private room",
            "Small double bed",
            "13-14 m²",
            "Newly refurbished",
            "From £281 / week"
        ],
        "id": "9bb5cf22b1cd4937af28744cdb3bce27"
    },
    {
        "city": "Brighton",
        "location": "Brighton",
        "room_name": "Abacus House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-04/iQ-Student-Accommodation-Brighton-Abacus-House-Bedrooms-Gold_Studio%286%29_0.jpg?itok=m1kb25-i",
        "room_link": "https://www.iqstudentaccommodation.com/brighton/abacus-house/gold-studio",
        "details": [
            "Limited availability",
            "Private studio",
            "Double bed",
            "21-25 m²",
            "Newly refurbished",
            "From £405 / week"
        ],
        "id": "538ad9178536425894d074e73cd6066c"
    },
    {
        "city": "Brighton",
        "location": "Brighton",
        "room_name": "Abacus House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-04/iQ-Student-Accommodation-Brighton-Abacus-House-Bedrooms-Gold_Studio%286%29_0.jpg?itok=m1kb25-i",
        "room_link": "https://www.iqstudentaccommodation.com/brighton/abacus-house/gold-studio-plus",
        "details": [
            "Limited availability",
            "Private studio",
            "Double bed",
            "21-25 m²",
            "Recently refurbished",
            "From £413 / week"
        ],
        "id": "64feba1a245c45f2bd068d6bf5f8a6a4"
    },
    {
        "city": "Brighton",
        "location": "Brighton",
        "room_name": "Abacus House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2020-12/iQ-Student-Accommodation-Brighton-Sawmills-Bedrooms-Platinum_Studio%2814%29.jpg?itok=wnIVFTC5",
        "room_link": "https://www.iqstudentaccommodation.com/brighton/sawmills/platinum-studio",
        "details": [
            "Limited availability",
            "Private studio",
            "Small double bed",
            "17-18 m²",
            "Kitchenette",
            "From £330 / week"
        ],
        "id": "662e021c225b44b6bf4829f43cb76361"
    },
    {
        "city": "Bristol",
        "location": "Bristol",
        "room_name": "Bristol",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2024-08/iQ-Student-Accommodation-Bristol-Bedrooms-Gold_En_Suite%202.jpg?itok=UtvAGoae",
        "room_link": "https://www.iqstudentaccommodation.com/bristol/iq-bristol/gold-en-suite",
        "details": [
            "Private room",
            "Small double bed",
            "Newly refurbished",
            "Shared kitchen",
            "From £374 / week"
        ],
        "id": "334aceda03ee4beab176b80d1155f413"
    },
    {
        "city": "Bristol",
        "location": "Bristol",
        "room_name": "Bristol",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2022-09/iQ-Student-Accommodation-Bristol-Water-Lane-House-Ultra-Studio_Plus_216%284%29.jpg?itok=fGZPid8E",
        "room_link": "https://www.iqstudentaccommodation.com/bristol/water-lane-house/platinum-studio-plus",
        "details": [
            "Private studio",
            "Small double bed",
            "18.5 m²",
            "Private bathroom",
            "From £435 / week"
        ],
        "id": "079827d0cebc4dcf9a6b8d2a3e44244a"
    },
    {
        "city": "Bristol",
        "location": "Bristol",
        "room_name": "Bristol",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2024-08/iQ-Student-Accommodation-Bristol-Bedrooms-Silver_En_Suite%204.jpg?itok=4EXOw6g4",
        "room_link": "https://www.iqstudentaccommodation.com/bristol/iq-bristol/silver-en-suite",
        "details": [
            "Private room",
            "Small double bed",
            "14 m²",
            "Newly refurbished",
            "From £365 / week"
        ],
        "id": "d22fb28957e948f68542ce7ff937b0ae"
    },
    {
        "city": "Bristol",
        "location": "Bristol",
        "room_name": "Bristol",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2022-09/iQ-Student-Accommodation-Bristol-Water-Lane-House-Ultra-Studio_Plus_216%284%29.jpg?itok=fGZPid8E",
        "room_link": "https://www.iqstudentaccommodation.com/bristol/water-lane-house/platinum-studio",
        "details": [
            "Limited availability",
            "Private studio",
            "Small double bed",
            "18.5 m²",
            "Private bathroom",
            "From £430 / week"
        ],
        "id": "166c3e98e99b4de182bfaa2187fa5e2f"
    },
    {
        "city": "Bristol",
        "location": "Bristol",
        "room_name": "Bristol",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2020-11/iQ-Student-Accommodation-Bristol-Bedrooms-Platinum_En_Suite%283%29.jpg?itok=7fRGrGkc",
        "room_link": "https://www.iqstudentaccommodation.com/bristol/iq-bristol/platinum-en-suite",
        "details": [
            "Limited availability",
            "Private room",
            "Small double bed",
            "Newly refurbished",
            "Shared kitchen",
            "From £406 / week"
        ],
        "id": "3615f7547a604d3e94a9a521527de3af"
    },
    {
        "city": "Bristol",
        "location": "Bristol",
        "room_name": "Bristol",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2020-11/iQ-Student-Accommodation-Bristol-Bedrooms-Bronze_Studio%289%29.jpg?itok=TxiVZZuu",
        "room_link": "https://www.iqstudentaccommodation.com/bristol/iq-bristol/bronze-studio",
        "details": [
            "Limited availability",
            "Private studio",
            "Small double bed",
            "Newly refurbished",
            "Kitchenette",
            "From £399 / week"
        ],
        "id": "0eb42b16e73449ec9b8f02207d856a8a"
    },
    {
        "city": "Bristol",
        "location": "Bristol",
        "room_name": "Bristol",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2022-11/IQ14FB~1_0.JPG?itok=KD5-PvRG",
        "room_link": "https://www.iqstudentaccommodation.com/bristol/water-lane-house/bronze-studio",
        "details": [
            "Limited availability",
            "Private studio",
            "Small double bed",
            "17-18 m²",
            "Private bathroom",
            "From £371 / week"
        ],
        "id": "cdeb4c866bc644959021a04c1206a353"
    },
    {
        "city": "Bristol",
        "location": "Bristol",
        "room_name": "Bristol",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2022-09/iQ-Student-Accommodation-Bristol-Water-Lane-House-Premier_Studio_Plus_Corner_336%284%29.jpg?itok=d6Mzl3Ob",
        "room_link": "https://www.iqstudentaccommodation.com/bristol/water-lane-house/gold-studio-plus",
        "details": [
            "Limited availability",
            "Private studio",
            "Small double bed",
            "18 m²",
            "Private bathroom",
            "From £433 / week"
        ],
        "id": "a72fa82dddb6407299a05fce0da0b54e"
    },
    {
        "city": "Bristol",
        "location": "Bristol",
        "room_name": "Bristol",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2024-05/Bedroom%20-26_0.jpg?itok=y8W2vNy2",
        "room_link": "https://www.iqstudentaccommodation.com/bristol/iq-bristol/platinum-studio",
        "details": [
            "Limited availability",
            "Private studio",
            "Double bed",
            "20-23 m²",
            "Newly refurbished",
            "From £423 / week"
        ],
        "id": "6bd6ec64a1034b41a4afe1975fb3101e"
    },
    {
        "city": "Bristol",
        "location": "Bristol",
        "room_name": "Bristol",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2024-08/iQ-Student-Accommodation-Bristol-Bedrooms-Silver_Studio%206_0.jpg?itok=R3IaITZw",
        "room_link": "https://www.iqstudentaccommodation.com/bristol/iq-bristol/silver-studio",
        "details": [
            "Limited availability",
            "Private studio",
            "Double bed",
            "18 m²",
            "Newly refurbished",
            "From £403 / week"
        ],
        "id": "e188d13ed519406eb3931f0ccd5e897f"
    },
    {
        "city": "Bristol",
        "location": "Bristol",
        "room_name": "Bristol",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2022-09/iQ-Student-Accommodation-Bristol-Water-Lane-House-Premier_Studio_Plus_Corner_336%284%29.jpg?itok=d6Mzl3Ob",
        "room_link": "https://www.iqstudentaccommodation.com/bristol/water-lane-house/silver-studio-plus",
        "details": [
            "Limited availability",
            "Private studio",
            "Small double bed",
            "Private bathroom",
            "Private kitchen",
            "From £402 / week"
        ],
        "id": "49a253774d5e4b8e9b2b571e991e8056"
    },
    {
        "city": "Bristol",
        "location": "Bristol",
        "room_name": "Bristol",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2023-05/iQ-Student-Accommodation-Bristol-Water-Lane-House-Premier_Studio_Plus_325%288%29.jpg?itok=CpJMFMq4",
        "room_link": "https://www.iqstudentaccommodation.com/bristol/water-lane-house/bronze-studio-plus",
        "details": [
            "Private studio",
            "Small double bed",
            "17-18 m²",
            "Private kitchen",
            "From £381 / week"
        ],
        "id": "e3e5c9e6ce944d09815060f2e0a60fe8"
    },
    {
        "city": "Dundee",
        "location": "Dundee",
        "room_name": "Parker House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-09/iQ-Student-Accommodation-Dundee-Parker-House-Bedrooms-Addition-Bronze_En_Suite%282%29.jpg?itok=MTHXhS1r",
        "room_link": "https://www.iqstudentaccommodation.com/dundee/parker-house/bronze-en-suite",
        "details": [
            "Private room",
            "Single bed",
            "10 m²",
            "Shared kitchen",
            "From £162 / week"
        ],
        "id": "a04d86fa45104e4795da70c18415a597"
    },
    {
        "city": "Dundee",
        "location": "Dundee",
        "room_name": "Parker House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-09/iQ-Student-Accommodation-Dundee-Parker-House-Bedrooms-Addition-Bronze_En_Suite_Plus%282%29.jpg?itok=xT75FyG8",
        "room_link": "https://www.iqstudentaccommodation.com/dundee/parker-house/bronze-en-suite-plus",
        "details": [
            "Private room",
            "Single bed",
            "10 m²",
            "Shared kitchen",
            "From £168 / week"
        ],
        "id": "cf75ee663356480fa5ee07cca3de9654"
    },
    {
        "city": "Dundee",
        "location": "Dundee",
        "room_name": "Parker House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-03/DSC_0068.jpg?itok=fr2FEDWd",
        "room_link": "https://www.iqstudentaccommodation.com/dundee/parker-house/bronze-studio-plus",
        "details": [
            "Private studio",
            "Double bed",
            "Microwave",
            "Shower",
            "From £246 / week"
        ],
        "id": "97f7262257a847e9aaa8b06e61cd68c2"
    },
    {
        "city": "Dundee",
        "location": "Dundee",
        "room_name": "Parker House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-02/iQ-Student-Accommodation-Dundee-Parker-House-Bedrooms-Gold_En_Suite%282%29.jpg?itok=6hLENVGU",
        "room_link": "https://www.iqstudentaccommodation.com/dundee/parker-house/gold-en-suite",
        "details": [
            "Private room",
            "Small double bed",
            "Shared kitchen",
            "Study desk and chair",
            "From £190 / week"
        ],
        "id": "007a24ecf36240268cbca0b800a384e4"
    },
    {
        "city": "Dundee",
        "location": "Dundee",
        "room_name": "Parker House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-09/iQ-Student-Accommodation-Dundee-Parker-House-Bedrooms-Addition-Gold_En_Suite_Plus%285%29.jpg?itok=t-EeIqJf",
        "room_link": "https://www.iqstudentaccommodation.com/dundee/parker-house/gold-en-suite-plus",
        "details": [
            "Private room",
            "Small double bed",
            "Shower",
            "Shared kitchen",
            "From £183 / week"
        ],
        "id": "57c32563b02840dfa2eb64f1ea973ce0"
    },
    {
        "city": "Dundee",
        "location": "Dundee",
        "room_name": "Parker House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-03/iQ-Student-Accommodation-Dundee-Parker-House-Bedrooms-E2%285%29.jpg?itok=UwxF6rf1",
        "room_link": "https://www.iqstudentaccommodation.com/dundee/parker-house/one-bed-apartment",
        "details": [
            "Private apartment",
            "Double bed",
            "Microwave",
            "Shower",
            "From £259 / week"
        ],
        "id": "1dc2eb7e1e404233a45c43c1d55a1e64"
    },
    {
        "city": "Dundee",
        "location": "Dundee",
        "room_name": "Parker House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-02/iQ-Student-Accommodation-Dundee-Parker-House-Bedrooms-Platinum_En_Suite%283%29.jpg?itok=goWKf2Om",
        "room_link": "https://www.iqstudentaccommodation.com/dundee/parker-house/platinum-en-suite",
        "details": [
            "Private room",
            "Double bed",
            "14 m²",
            "Shared kitchen",
            "From £200 / week"
        ],
        "id": "bfee60aac59a4bfda143fa17acdaf569"
    },
    {
        "city": "Dundee",
        "location": "Dundee",
        "room_name": "Parker House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-02/iQ-Student-Accommodation-Dundee-Parker-House-Bedrooms-Platinum_En_Suite_Plus%282%29.jpg?itok=AUxGEbVn",
        "room_link": "https://www.iqstudentaccommodation.com/dundee/parker-house/platinum-en-suite-plus",
        "details": [
            "Private room",
            "Double bed",
            "14 m²",
            "Private bathroom",
            "From £204 / week"
        ],
        "id": "4f4333b775e94bfe89e1f53f93febd6a"
    },
    {
        "city": "Dundee",
        "location": "Dundee",
        "room_name": "Parker House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-03/iQ-Student-Accommodation-Dundee-Parker-House-Bedrooms-E2%285%29.jpg?itok=UwxF6rf1",
        "room_link": "https://www.iqstudentaccommodation.com/dundee/parker-house/premium-one-bed-apartment",
        "details": [
            "Private apartment",
            "Double Bed",
            "Newly refurbished",
            "Microwave",
            "From £293 / week"
        ],
        "id": "8c377d93dddf4bc4bc99713db9f6ea10"
    },
    {
        "city": "Dundee",
        "location": "Dundee",
        "room_name": "Parker House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-02/iQ-Student-Accommodation-Dundee-Parker-House-Bedrooms-Silver_En_Suite%282%29.jpg?itok=Zsx28F54",
        "room_link": "https://www.iqstudentaccommodation.com/dundee/parker-house/silver-en-suite",
        "details": [
            "Private room",
            "Small double bed",
            "10 m²",
            "Private bathroom",
            "From £168 / week"
        ],
        "id": "4d830d55fa3f412fa14b14fc2a82ca7d"
    },
    {
        "city": "Dundee",
        "location": "Dundee",
        "room_name": "Parker House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-02/iQ-Student-Accommodation-Dundee-Parker-House-Bedrooms-Silver_En_Suite_Plus%282%29.jpg?itok=qwGd5O8B",
        "room_link": "https://www.iqstudentaccommodation.com/dundee/parker-house/silver-en-suite-plus",
        "details": [
            "Private room",
            "Small double bed",
            "10 m²",
            "Shared kitchen",
            "From £172 / week"
        ],
        "id": "3f985f89d9cc4437ac6b09bc61cc7820"
    },
    {
        "city": "Edinburgh",
        "location": "Edinburgh",
        "room_name": "Fountainbridge",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-01/iQ-Student-Accommodation-Edinburgh-Fountainbridge-Bedrooms-Silver_En_Suite_Plus%282%29.jpg?itok=2X5mFBlq",
        "room_link": "https://www.iqstudentaccommodation.com/edinburgh/fountainbridge/silver-en-suite",
        "details": [
            "Private room",
            "Small double bed",
            "13 m²",
            "Private bathroom",
            "From £264 / week"
        ],
        "id": "2e05b819737a447cbcb3dd162d220731"
    },
    {
        "city": "Edinburgh",
        "location": "Edinburgh",
        "room_name": "Fountainbridge",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2022-11/iQ-Student-Accommodation-Edinburgh-Grove-Bedrooms-Addition-Silver_En_Suite_Plus%285%29.jpg?itok=-GSCHzt2",
        "room_link": "https://www.iqstudentaccommodation.com/edinburgh/grove/silver-en-suite",
        "details": [
            "Private room",
            "Small double bed",
            "10 m²",
            "Recently refurbished",
            "From £269 / week"
        ],
        "id": "0647e4c976f94cbfa968d9062df9ffdb"
    },
    {
        "city": "Edinburgh",
        "location": "Edinburgh",
        "room_name": "Fountainbridge",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-01/iQ-Student-Accommodation-Edinburgh-Fountainbridge-Bedrooms-Gold_En_Suite_Plus%282%29.jpg?itok=g8HxcpaL",
        "room_link": "https://www.iqstudentaccommodation.com/edinburgh/fountainbridge/gold-en-suite",
        "details": [
            "Limited availability",
            "Private room",
            "Small double bed",
            "15 m²",
            "Shared kitchen",
            "From £276 / week"
        ],
        "id": "19e7c1eb3cbf41ccb9e4e70160c53632"
    },
    {
        "city": "Edinburgh",
        "location": "Edinburgh",
        "room_name": "Fountainbridge",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2022-10/iQ-Student-Accommodation-Edinburgh-Elliott-House-NEW-Copper_Studio%285%29.jpg?itok=MjmuY-of",
        "room_link": "https://www.iqstudentaccommodation.com/edinburgh/elliott-house/copper-studio",
        "details": [
            "Limited availability",
            "Private studio",
            "Small double bed",
            "16-18 m²",
            "Private bathroom",
            "From £283 / week"
        ],
        "id": "07d383e4d6bc4f71b1ea69f7ff57a0d2"
    },
    {
        "city": "Edinburgh",
        "location": "Edinburgh",
        "room_name": "Fountainbridge",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-09/iQ-Student-Accommodation-Edinburgh-Fountainbridge-Bedrooms-Addition-Bronze_Studio_Plus%282%29.jpg?itok=64sRn6Fm",
        "room_link": "https://www.iqstudentaccommodation.com/edinburgh/fountainbridge/bronze-studio",
        "details": [
            "Limited availability",
            "Private studio",
            "Double bed",
            "18 m²",
            "Private kitchen",
            "From £323 / week"
        ],
        "id": "4eaf69b0e2334e37ad2f02be6cb020e2"
    },
    {
        "city": "Edinburgh",
        "location": "Edinburgh",
        "room_name": "Fountainbridge",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2024-10/iQ-Student-Accommodation-Edinburgh-Elliott-House-NEW-Silver_Studio_Plus%283%29_0.jpg?itok=NZNIdyCr",
        "room_link": "https://www.iqstudentaccommodation.com/edinburgh/elliott-house/silver-studio",
        "details": [
            "Limited availability",
            "Private studio",
            "Small double bed",
            "18-19 m²",
            "Recently refurbished",
            "From £326 / week"
        ],
        "id": "ade8299e27ef48fb969b573600308269"
    },
    {
        "city": "Edinburgh",
        "location": "Edinburgh",
        "room_name": "Fountainbridge",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2024-10/iQ-Student-Accommodation-Edinburgh-Elliott-House-NEW-Gold_Studio_Plus%284%29.jpg?itok=ocq36C-N",
        "room_link": "https://www.iqstudentaccommodation.com/edinburgh/elliott-house/gold-studio",
        "details": [
            "Limited availability",
            "Private studio",
            "Small double bed",
            "20-22 m²",
            "Recently refurbished",
            "From £340 / week"
        ],
        "id": "47f58e08638f4b45a1a6b32d47ab1be7"
    },
    {
        "city": "Edinburgh",
        "location": "Edinburgh",
        "room_name": "Fountainbridge",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2022-10/iQ-Student-Accommodation-Edinburgh-Elliott-House-NEW-Platinum_En_Suite%282%29.jpg?itok=p7MGvN3X",
        "room_link": "https://www.iqstudentaccommodation.com/edinburgh/elliott-house/platinum-en-suite",
        "details": [
            "Limited availability",
            "Private room",
            "Small double bed",
            "26-28 m²",
            "Private bathroom",
            "From £353 / week"
        ],
        "id": "09a388415c88433bb6c9640134aecd60"
    },
    {
        "city": "Edinburgh",
        "location": "Edinburgh",
        "room_name": "Fountainbridge",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-01/iQ-Student-Accommodation-Edinburgh-Fountainbridge-Bedrooms-Silver_Studio_Plus%284%29.jpg?itok=2uvWBjYa",
        "room_link": "https://www.iqstudentaccommodation.com/edinburgh/fountainbridge/silver-studio",
        "details": [
            "Limited availability",
            "Private studio",
            "Double bed",
            "20 m²",
            "Limited Free Dual Occupancy",
            "From £353 / week"
        ],
        "id": "20f491b78bec492c9929c5671e136905"
    },
    {
        "city": "Edinburgh",
        "location": "Edinburgh",
        "room_name": "Fountainbridge",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-01/iQ-Student-Accommodation-Edinburgh-Fountainbridge-Bedrooms-Gold_Studio_Plus%286%29.jpg?itok=3Ottf0l3",
        "room_link": "https://www.iqstudentaccommodation.com/edinburgh/fountainbridge/gold-studio",
        "details": [
            "Limited availability",
            "Private studio",
            "Double bed",
            "22 m²",
            "Private kitchen",
            "From £361 / week"
        ],
        "id": "3167fcd23af8401c909a7c99a20892a0"
    },
    {
        "city": "Edinburgh",
        "location": "Edinburgh",
        "room_name": "Fountainbridge",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2024-10/iQ-Student-Accommodation-Edinburgh-Elliott-House-NEW-Platinum_Studio_Plus%284%29_0.jpg?itok=2u3wnFeH",
        "room_link": "https://www.iqstudentaccommodation.com/edinburgh/elliott-house/platinum-studio",
        "details": [
            "Limited availability",
            "Private studio",
            "Small double bed",
            "24-25 m²",
            "Recently refurbished",
            "From £362 / week"
        ],
        "id": "059eb2caa0df4d0f892e96a836012ddf"
    },
    {
        "city": "Edinburgh",
        "location": "Edinburgh",
        "room_name": "Fountainbridge",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-01/iQ-Student-Accommodation-Edinburgh-Fountainbridge-Bedrooms-Platinum_Studio_Plus%284%29.jpg?itok=i95zF3ID",
        "room_link": "https://www.iqstudentaccommodation.com/edinburgh/fountainbridge/platinum-studio-0",
        "details": [
            "Limited availability",
            "Private studio",
            "Double bed",
            "25 m²",
            "Dual occupancy available",
            "From £376 / week"
        ],
        "id": "7aab302bfeb04123befff205c3e6acbd"
    },
    {
        "city": "Egham",
        "location": "Egham",
        "room_name": "Podium",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2022-02/iQ-Student-Accommodation-Egham-Podium-Bedrooms-Medium_Two_Bedroom_Apartment%288%29_0.jpg?itok=J3ZL2nYF",
        "room_link": "https://www.iqstudentaccommodation.com/egham/the-podium/silver-two-bed-apartment",
        "details": [
            "Limited availability",
            "Private apartment",
            "Small double bed",
            "41.6 m²",
            "Shared bathroom",
            "From £286 / week"
        ],
        "id": "cae8c8b639794a2f8f1d926228dff8b0"
    },
    {
        "city": "Egham",
        "location": "Egham",
        "room_name": "Podium",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2022-02/iQ-Student-Accommodation-Egham-Podium-Bedrooms-Duplicate-Rooms-W0014-S011-IL03-S302-Large_Two_Bedroom_Apartment%285%29.jpg?itok=AP4XwYTw",
        "room_link": "https://www.iqstudentaccommodation.com/egham/the-podium/gold-two-bedroom-apartment",
        "details": [
            "Limited availability",
            "Private apartment",
            "Shared bathroom",
            "50.2 m²",
            "Small double bed",
            "From £296 / week"
        ],
        "id": "c39e98a5753046c5bce90439f0c4f128"
    },
    {
        "city": "Egham",
        "location": "Egham",
        "room_name": "Podium",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2022-02/iQ-Student-Accommodation-Egham-The-Pad-Bedrooms-Standard_En_Suite%286%29.jpg?itok=r5Xg9JA5",
        "room_link": "https://www.iqstudentaccommodation.com/egham/the-pad/bronze-en-suite",
        "details": [
            "Private room",
            "13.5 m²",
            "Small double bed",
            "Shared kitchen",
            "From £252 / week"
        ],
        "id": "e4e80ae453814e11acc62115adfb0384"
    },
    {
        "city": "Egham",
        "location": "Egham",
        "room_name": "Podium",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2022-02/iQ-Student-Accommodation-Egham-The-Pad-Bedrooms-Standard_En_Suite%282%29.jpg?itok=mhiSusDJ",
        "room_link": "https://www.iqstudentaccommodation.com/egham/the-pad/bronze-en-suite-plus",
        "details": [
            "Private room",
            "Small double bed",
            "13.5 m²",
            "Study desk and chair",
            "From £254 / week"
        ],
        "id": "3fdf0d4764fe42d1ba4a756b9478501e"
    },
    {
        "city": "Egham",
        "location": "Egham",
        "room_name": "Podium",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2022-02/iQ-Student-Accommodation-Egham-The-Pad-Bedrooms-Medium_En_Suite%286%29_0.jpg?itok=9SCoMo5H",
        "room_link": "https://www.iqstudentaccommodation.com/egham/the-pad/silver-en-suite",
        "details": [
            "Private room",
            "Small double bed",
            "15 m²",
            "Shared kitchen",
            "From £257 / week"
        ],
        "id": "8c79b3b4c74e4603ba8531a65e4a5982"
    },
    {
        "city": "Egham",
        "location": "Egham",
        "room_name": "Podium",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2022-02/iQ-Student-Accommodation-Egham-The-Pad-Bedrooms-Large_En_Suite%283%29.jpg?itok=0QWyZNNc",
        "room_link": "https://www.iqstudentaccommodation.com/egham/the-pad/gold-en-suite",
        "details": [
            "Private room",
            "16-17 m²",
            "Small double bed",
            "Small double bed",
            "From £262 / week"
        ],
        "id": "0114a0b826b44805b8de8524dcdddaf2"
    },
    {
        "city": "Egham",
        "location": "Egham",
        "room_name": "Podium",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2022-02/iQ-Student-Accommodation-Egham-The-Pad-Bedrooms-Medium_En_Suite%286%29_0.jpg?itok=9SCoMo5H",
        "room_link": "https://www.iqstudentaccommodation.com/egham/the-pad/silver-en-suite-plus",
        "details": [
            "Private room",
            "Small double bed",
            "15 m²",
            "Study desk and chair",
            "From £263 / week"
        ],
        "id": "5517be3d374d49f6b26f09c4b7c17e09"
    },
    {
        "city": "Egham",
        "location": "Egham",
        "room_name": "Podium",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2022-02/iQ-Student-Accommodation-Egham-The-Pad-Bedrooms-Large_En_Suite%282%29.jpg?itok=NlPjluuz",
        "room_link": "https://www.iqstudentaccommodation.com/egham/the-pad/gold-en-suite-plus",
        "details": [
            "Private room",
            "Small double bed",
            "16-17 m²",
            "Study desk and chair",
            "From £268 / week"
        ],
        "id": "426664ed7d0e4ca8a4ab98d23eb144aa"
    },
    {
        "city": "Egham",
        "location": "Egham",
        "room_name": "Podium",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2022-02/iQ-Student-Accommodation-Egham-Podium-Bedrooms-Standard_En_Suite%283%29.jpg?itok=HJjEdP5G",
        "room_link": "https://www.iqstudentaccommodation.com/egham/the-podium/bronze-en-suite",
        "details": [
            "Private room",
            "Small double bed",
            "14.8 m²",
            "Study desk and chair",
            "From £270 / week"
        ],
        "id": "f1c868d01b6f47e0b8a74f1c73fcf455"
    },
    {
        "city": "Egham",
        "location": "Egham",
        "room_name": "Podium",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2022-02/iQ-Student-Accommodation-Egham-The-Pad-Bedrooms-Standard_Studio%285%29.jpg?itok=oy6nR2Hc",
        "room_link": "https://www.iqstudentaccommodation.com/egham/the-pad/bronze-studio",
        "details": [
            "Private studio",
            "Small double bed",
            "15.6 m²",
            "Kitchenette",
            "From £276 / week"
        ],
        "id": "9180f03fc6484d0b84476cf90e1fa195"
    },
    {
        "city": "Egham",
        "location": "Egham",
        "room_name": "Podium",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2023-04/iQ-Student-Accommodation-Egham-Podium-Bedrooms-Medium_En_Suite%286%29.jpg?itok=qiC0jzMA",
        "room_link": "https://www.iqstudentaccommodation.com/egham/the-podium/silver-en-suite",
        "details": [
            "Private room",
            "Small double bed",
            "17.4 m²",
            "Shared kitchen",
            "From £280 / week"
        ],
        "id": "4ae2f268405c41a39715b87324bee1ad"
    },
    {
        "city": "Egham",
        "location": "Egham",
        "room_name": "Podium",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2023-04/iQ-Student-Accommodation-Egham-Podium-Bedrooms-Standard_Two_Bedroom_Apartment%285%29.jpg?itok=9ilCK_95",
        "room_link": "https://www.iqstudentaccommodation.com/egham/the-podium/bronze-two-bedroom-apartment",
        "details": [
            "Private apartment",
            "Small double bed",
            "32.5 m²",
            "Shared bathroom",
            "From £285 / week"
        ],
        "id": "2dba13bbfedc411cbd6af6a8695fe37d"
    },
    {
        "city": "Glasgow",
        "location": "Glasgow",
        "room_name": "Elgin Place",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-09/iQ-Student-Accommodation-Glasgow-Elgin-Place-Bedrooms-Addition-Bronze_En_Suite%282%29.jpg?itok=UfipASoD",
        "room_link": "https://www.iqstudentaccommodation.com/glasgow/elgin-place/bronze-en-suite",
        "details": [
            "Private room",
            "Small double bed",
            "14 m²",
            "Shared kitchen",
            "From £251 / week"
        ],
        "id": "d47708ae84d743ca85a8b1e9878012ff"
    },
    {
        "city": "Glasgow",
        "location": "Glasgow",
        "room_name": "Elgin Place",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2020-12/iQ-Student-Accommodation-Glasgow-Elgin-Place-Bedrooms-Silver_Studio_Plus%289%29.jpg?itok=Oc2eJQgk",
        "room_link": "https://www.iqstudentaccommodation.com/glasgow/elgin-place/silver-studio-plus",
        "details": [
            "Private studio",
            "Double bed",
            "20 m²",
            "TV in bedroom",
            "From £301 / week"
        ],
        "id": "cdfe2406088d45dc8352a474e7260274"
    },
    {
        "city": "Glasgow",
        "location": "Glasgow",
        "room_name": "Elgin Place",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-09/iQ-Student-Accommodation-Glasgow-Elgin-Place-Bedrooms-Addition-Bronze_Studio%284%29.jpg?itok=CNLbyrEB",
        "room_link": "https://www.iqstudentaccommodation.com/glasgow/elgin-place/bronze-studio",
        "details": [
            "Private studio",
            "Double bed",
            "18 m²",
            "Microwave",
            "From £283 / week"
        ],
        "id": "8463bc147cea4618a7e8a29f7a00ce85"
    },
    {
        "city": "Glasgow",
        "location": "Glasgow",
        "room_name": "Elgin Place",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2020-12/iQ-Student-Accommodation-Glasgow-Elgin-Place-Bedrooms-Bronze_Studio_Plus%284%29.jpg?itok=CnTeNGPy",
        "room_link": "https://www.iqstudentaccommodation.com/glasgow/elgin-place/bronze-studio-plus",
        "details": [
            "Private studio",
            "Double bed",
            "18 m²",
            "Microwave",
            "From £296 / week"
        ],
        "id": "1b06205c73634f68873931cc75c4a299"
    },
    {
        "city": "Glasgow",
        "location": "Glasgow",
        "room_name": "Elgin Place",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-09/iQ-Student-Accommodation-Glasgow-Elgin-Place-Bedrooms-Addition-Gold_Studio%283%29.jpg?itok=_9v0IlCF",
        "room_link": "https://www.iqstudentaccommodation.com/glasgow/elgin-place/gold-studio",
        "details": [
            "Private studio",
            "Double bed",
            "Dual occupancy available",
            "TV in bedroom",
            "From £348 / week"
        ],
        "id": "bb9b811b4d0f4ff18a27e13f4f43a033"
    },
    {
        "city": "Glasgow",
        "location": "Glasgow",
        "room_name": "Elgin Place",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-09/iQ-Student-Accommodation-Glasgow-Elgin-Place-Bedrooms-Addition-Gold_Studio_Plus%284%29.jpg?itok=auEUylST",
        "room_link": "https://www.iqstudentaccommodation.com/glasgow/elgin-place/gold-studio-plus",
        "details": [
            "Private studio",
            "Double bed",
            "25 m²",
            "Dual occupancy available",
            "From £352 / week"
        ],
        "id": "d5e1d44d080640a0a0b5dd3846180e10"
    },
    {
        "city": "Glasgow",
        "location": "Glasgow",
        "room_name": "Elgin Place",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2020-12/iQ-Student-Accommodation-Glasgow-Elgin-Place-Bedrooms-One_Bed_Apartment%287%29.jpg?itok=SdTTgnei",
        "room_link": "https://www.iqstudentaccommodation.com/glasgow/elgin-place/one-bed-apartment",
        "details": [
            "Private apartment",
            "Double bed",
            "44 m²",
            "Dual occupancy available",
            "From £428 / week"
        ],
        "id": "9f851b59101d4899a2e2c80a35f7664e"
    },
    {
        "city": "Glasgow",
        "location": "Glasgow",
        "room_name": "Elgin Place",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-09/iQ-Student-Accommodation-Glasgow-Elgin-Place-Bedrooms-Addition-Gold_Studio_Plus%284%29.jpg?itok=auEUylST",
        "room_link": "https://www.iqstudentaccommodation.com/glasgow/elgin-place/platinum-studio-plus",
        "details": [
            "Private studio",
            "Double bed",
            "25 m²",
            "Dual occupancy available",
            "From £395 / week"
        ],
        "id": "f3a5fd4ecf55437995ea9cb52e8732f2"
    },
    {
        "city": "Glasgow",
        "location": "Glasgow",
        "room_name": "Elgin Place",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2020-12/iQ-Student-Accommodation-Glasgow-Elgin-Place-Bedrooms-Silver_En_Suite%286%29_0.jpg?itok=FjRhyBL6",
        "room_link": "https://www.iqstudentaccommodation.com/glasgow/elgin-place/silver-en-suite",
        "details": [
            "Private room",
            "Small double bed",
            "14 m²",
            "Shared kitchen",
            "From £256 / week"
        ],
        "id": "7aa55a302b52430289326aa834ec609e"
    },
    {
        "city": "Glasgow",
        "location": "Glasgow",
        "room_name": "Elgin Place",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2020-12/iQ-Student-Accommodation-Glasgow-Elgin-Place-Bedrooms-Silver_Studio%286%29.jpg?itok=vB5Pqovn",
        "room_link": "https://www.iqstudentaccommodation.com/glasgow/elgin-place/silver-studio",
        "details": [
            "Private studio",
            "Double Bed",
            "20 m²",
            "Shower",
            "From £293 / week"
        ],
        "id": "7237cd4067ff4349aef2ee0754cecb19"
    },
    {
        "city": "Huddersfield",
        "location": "Huddersfield",
        "room_name": "Aspley House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2023-02/iQ-Student-Accommodation-Huddersfield-Aspley-House-Bedrooms-Bedroom%283%29.jpg?itok=FqN7vcjD",
        "room_link": "https://www.iqstudentaccommodation.com/huddersfield/aspley-house/silver-en-suite-plus",
        "details": [
            "Private room",
            "Small double bed",
            "Private bathroom",
            "Shared kitchen",
            "From £130 / week"
        ],
        "id": "8fe7da380d0e407abadf5e4a458786d7"
    },
    {
        "city": "Huddersfield",
        "location": "Huddersfield",
        "room_name": "Aspley House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2023-05/iQ-Student-Accommodation-Huddersfield-Castings-Bronze-En-Suite-and-Bronze-En-Suite-Plus-Bronze_En_Suite_Plus%286%29_0.jpg?itok=y45fvaH2",
        "room_link": "https://www.iqstudentaccommodation.com/huddersfield/castings/bronze-en-suite-plus",
        "details": [
            "Private room",
            "Small double bed",
            "Wheelchair accessible",
            "Wardrobe",
            "From £103 / week"
        ],
        "id": "64bf333cbda446e798dc255a7c6b093c"
    },
    {
        "city": "Huddersfield",
        "location": "Huddersfield",
        "room_name": "Aspley House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2023-03/iQ-Student-Accommodation-Huddersfield-Castings-Bronze-En-Suite-and-Bronze-En-Suite-Plus-Bronze_En_Suite%283%29.jpg?itok=TV093B1l",
        "room_link": "https://www.iqstudentaccommodation.com/huddersfield/castings/copper-en-suite-plus",
        "details": [
            "Private room",
            "Small double bed",
            "Study desk and chair",
            "Wardrobe",
            "From £100 / week"
        ],
        "id": "28ff54263d784ba6bca2481d824c9ba3"
    },
    {
        "city": "Huddersfield",
        "location": "Huddersfield",
        "room_name": "Aspley House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2020-12/iQ-Student-Accommodation-Huddersfield-Castings-Bedrooms-Gold_Studio%283%29.jpg?itok=dLs10oPP",
        "room_link": "https://www.iqstudentaccommodation.com/huddersfield/castings/gold-studio",
        "details": [
            "Private studio",
            "Small double bed",
            "TV in bedroom",
            "Study desk and chair",
            "From £211 / week"
        ],
        "id": "ccc08928191b4fb5a6ebc8be972163ad"
    },
    {
        "city": "Huddersfield",
        "location": "Huddersfield",
        "room_name": "Aspley House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2023-05/iQ-Student-Accommodation-Huddersfield-Castings-Silver-En-Suite-Silver_En_Suite%286%29.jpg?itok=7afUBaOL",
        "room_link": "https://www.iqstudentaccommodation.com/huddersfield/castings/silver-en-suite-plus",
        "details": [
            "Private room",
            "Small double bed",
            "Wardrobe",
            "Study desk and chair",
            "From £112 / week"
        ],
        "id": "7a50bef8e10243398dee58eeaf38727f"
    },
    {
        "city": "Huddersfield",
        "location": "Huddersfield",
        "room_name": "Aspley House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2020-12/iQ-Student-Accommodation-Huddersfield-Castings-Bedrooms-Silver_Studio%285%29.jpg?itok=rPwM7OqK",
        "room_link": "https://www.iqstudentaccommodation.com/huddersfield/castings/silver-studio",
        "details": [
            "Private studio",
            "Small double bed",
            "Wheelchair accessible",
            "TV in bedroom",
            "From £187 / week"
        ],
        "id": "792e0f2f6c3642a4a4c358c01e5e4020"
    },
    {
        "city": "Huddersfield",
        "location": "Huddersfield",
        "room_name": "Aspley House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2020-11/iQ-Student-Accommodation-Huddersfield-Little-Aspley-Bedrooms-Bronze_En_Suite_Plus%284%29_0.jpg?itok=M_HWTFCi",
        "room_link": "https://www.iqstudentaccommodation.com/huddersfield/little-aspley-house/bronze-en-suite",
        "details": [
            "Private room",
            "Small double bed",
            "12-14 m²",
            "Shared kitchen",
            "From £98 / week"
        ],
        "id": "f7ac98407c6f4251b9be5ef6c336fe4c"
    },
    {
        "city": "Huddersfield",
        "location": "Huddersfield",
        "room_name": "Aspley House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2024-04/iQ-Student-Accommodation-Huddersfield-Little-Aspley-Bronze-En-Suite-Plus-and-Silver-En-Suite-Plus-Bedroom_0.jpg?itok=9Hxg908G",
        "room_link": "https://www.iqstudentaccommodation.com/huddersfield/little-aspley-house/bronze-en-suite-plus",
        "details": [
            "Private room",
            "Small double bed",
            "Shared kitchen",
            "Private bathroom",
            "From £103 / week"
        ],
        "id": "27c65a57875e4c0aa4f4f3a2cebf4951"
    },
    {
        "city": "Huddersfield",
        "location": "Huddersfield",
        "room_name": "Aspley House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2023-02/iQ-Student-Accommodation-Huddersfield-Little-Aspley-Bronze-En-Suite-Plus-and-Silver-En-Suite-Plus-F1-Bedroom%284%29_1.jpg?itok=QrL8Zl8M",
        "room_link": "https://www.iqstudentaccommodation.com/huddersfield/little-aspley-house/bronze-studio",
        "details": [
            "Private studio",
            "Small double bed",
            "Microwave",
            "Dual occupancy available",
            "From £211 / week"
        ],
        "id": "1ee673abdd98452c845238cc95f1c9f8"
    },
    {
        "city": "Huddersfield",
        "location": "Huddersfield",
        "room_name": "Aspley House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2020-11/iQ-Student-Accommodation-Huddersfield-Little-Aspley-Bedrooms-Copper_En_Suite%287%29_0.jpg?itok=8Bb6tmfG",
        "room_link": "https://www.iqstudentaccommodation.com/huddersfield/little-aspley-house/copper-en-suite",
        "details": [
            "Private room",
            "Small double bed",
            "Shared kitchen",
            "Shower",
            "From £95 / week"
        ],
        "id": "cb89b6f0a3bf4238842f2e4c5541b4f9"
    },
    {
        "city": "Huddersfield",
        "location": "Huddersfield",
        "room_name": "Aspley House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2020-11/iQ-Student-Accommodation-Huddersfield-Little-Aspley-Bedrooms-Bedroom%282%29.jpg?itok=uxT_iWj1",
        "room_link": "https://www.iqstudentaccommodation.com/huddersfield/little-aspley-house/silver-en-suite",
        "details": [
            "Private room",
            "Small double bed",
            "12-14 m²",
            "Shared kitchen",
            "From £122 / week"
        ],
        "id": "8323d9981cec4cd39a883db548b83fe2"
    },
    {
        "city": "Huddersfield",
        "location": "Huddersfield",
        "room_name": "Aspley House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2024-04/IQ-STU~1.JPG?itok=T-Frs2xa",
        "room_link": "https://www.iqstudentaccommodation.com/huddersfield/little-aspley-house/silver-en-suite-plus",
        "details": [
            "Private room",
            "Small double bed",
            "12-14 m²",
            "Shared kitchen",
            "From £136 / week"
        ],
        "id": "da146e4602ae4de6a3df7fa64a9051e8"
    },
    {
        "city": "Leeds",
        "location": "Leeds",
        "room_name": "Leeds",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2024-10/64A_1.jpg?itok=ZPulfiKw",
        "room_link": "https://www.iqstudentaccommodation.com/leeds/iq-leeds/silver-en-suite-plus",
        "details": [
            "Limited availability",
            "Private room",
            "Small double bed",
            "14 m²",
            "Private bathroom",
            "From £155 / week"
        ],
        "id": "0658d97e0f964c608bb141974d041e05"
    },
    {
        "city": "Leeds",
        "location": "Leeds",
        "room_name": "Leeds",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-09/IQ8326~1_0.JPG?itok=URAPv19R",
        "room_link": "https://www.iqstudentaccommodation.com/leeds/iq-leeds/silver-en-suite",
        "details": [
            "Limited availability",
            "Private room",
            "Small double bed",
            "14 m²",
            "Private bathroom",
            "From £159 / week"
        ],
        "id": "38bb64d9ecc24acb9a5bd0fe01f4eceb"
    },
    {
        "city": "Leeds",
        "location": "Leeds",
        "room_name": "Leeds",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-09/IQEA02~1_0.JPG?itok=xN57qi3V",
        "room_link": "https://www.iqstudentaccommodation.com/leeds/iq-leeds/gold-en-suite",
        "details": [
            "Limited availability",
            "Private room",
            "Small double bed",
            "14 m²",
            "Private bathroom",
            "From £161 / week"
        ],
        "id": "268bf791775d44d2947a025b3065f3c8"
    },
    {
        "city": "Leeds",
        "location": "Leeds",
        "room_name": "Leeds",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-09/iQ-Student-Accommodation-Leeds-Bedrooms-Platinum_En_Suite%284%29.jpg?itok=ygGODcfS",
        "room_link": "https://www.iqstudentaccommodation.com/leeds/iq-leeds/platinum-en-suite",
        "details": [
            "Private room",
            "Double bed",
            "14 m²",
            "TV in shared space",
            "From £170 / week"
        ],
        "id": "e46af07c0ee648888c24d022ec5230cf"
    },
    {
        "city": "Leeds",
        "location": "Leeds",
        "room_name": "Leeds",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2023-05/iQ-Accommodation-Leeds-Marsden-House-Bedrooms-Bronze_En_Suite%286%29.jpg?itok=5CkTyOhy",
        "room_link": "https://www.iqstudentaccommodation.com/leeds/marsden-house/bronze-en-suite",
        "details": [
            "Private room",
            "Small double bed",
            "13 m²",
            "Lower Floor",
            "From £186 / week"
        ],
        "id": "cb7f5fb72f51441b9166d921e9bd3c48"
    },
    {
        "city": "Leeds",
        "location": "Leeds",
        "room_name": "Leeds",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-01/iQ-Accommodation-Leeds-Marsden-House-Bedrooms-Bronze_En_Suite%283%29_0.jpg?itok=PLNmxFbk",
        "room_link": "https://www.iqstudentaccommodation.com/leeds/marsden-house/bronze-en-suite-plus",
        "details": [
            "Limited availability",
            "Private room",
            "Small double bed",
            "13 m²",
            "Upper floors",
            "From £198 / week"
        ],
        "id": "b7b67cb4a94d415ab9b424fe8bafebce"
    },
    {
        "city": "Leeds",
        "location": "Leeds",
        "room_name": "Leeds",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-09/iQ-Student-Accommodation-Leeds-Altus-House-Floors-3-5-10-Bronze_En_Suite%282%29.jpg?itok=DEtAYAtn",
        "room_link": "https://www.iqstudentaccommodation.com/leeds/altus-house/bronze-en-suite",
        "details": [
            "Limited availability",
            "Private room",
            "Small double bed",
            "13 m²",
            "Private bathroom",
            "From £222 / week"
        ],
        "id": "75da8217b3f943f0a504df525d08a86a"
    },
    {
        "city": "Leeds",
        "location": "Leeds",
        "room_name": "Leeds",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-09/iQ-Student-Accommodation-Leeds-Altus-House-Floors-3-5-10-Bronze_En_Suite_Plus%282%29.jpg?itok=u5rr9sMC",
        "room_link": "https://www.iqstudentaccommodation.com/leeds/altus-house/bronze-en-suite-plus",
        "details": [
            "Private room",
            "Small double bed",
            "14 m²",
            "Private bathroom",
            "From £223 / week"
        ],
        "id": "77bc00c6b1fd487f99fe9ba94a6bb3b3"
    },
    {
        "city": "Leeds",
        "location": "Leeds",
        "room_name": "Leeds",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-09/iQ-Student-Accommodation-Leeds-Altus-House-Floors-3-5-10-Silver_En_Suite_Plus%286%29.jpg?itok=to5C5JoH",
        "room_link": "https://www.iqstudentaccommodation.com/leeds/altus-house/silver-en-suite-plus",
        "details": [
            "Private room",
            "Small double bed",
            "14 m²",
            "Private bathroom",
            "From £230 / week"
        ],
        "id": "d57cf87f0ea441369b6265307ba73d3d"
    },
    {
        "city": "Leeds",
        "location": "Leeds",
        "room_name": "Leeds",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-09/IQ-STU~3_9.JPG?itok=9FIWmdao",
        "room_link": "https://www.iqstudentaccommodation.com/leeds/altus-house/gold-en-suite",
        "details": [
            "Limited availability",
            "Private room",
            "Small double bed",
            "13 m²",
            "Private bathroom",
            "From £240 / week"
        ],
        "id": "3d5806cb3eaa4ca38016613a962ca976"
    },
    {
        "city": "Leeds",
        "location": "Leeds",
        "room_name": "Leeds",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-09/IQ-Student-Accommodation-Leeds-Altus-House-Floors-27-28-32-36-Gold_En_Suite_Plus%282%29.jpg?itok=ofUeMB9V",
        "room_link": "https://www.iqstudentaccommodation.com/leeds/altus-house/gold-en-suite-plus",
        "details": [
            "Private room",
            "Small double bed",
            "14 m²",
            "Private bathroom",
            "From £241 / week"
        ],
        "id": "bed541e6c2cd4d5490980b3a1e951b35"
    },
    {
        "city": "Leeds",
        "location": "Leeds",
        "room_name": "Leeds",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-09/iQ-Student-Accommodation-Leeds-Altus-House-Floors-3-5-10-Silver_En_Suite_Premium%282%29.jpg?itok=ddZIVfgf",
        "room_link": "https://www.iqstudentaccommodation.com/leeds/altus-house/silver-en-suite-premium",
        "details": [
            "Limited availability",
            "Private room",
            "Small double bed",
            "17 m²",
            "Private bathroom",
            "From £243 / week"
        ],
        "id": "28569aea84bc47279504f0b92d12b3b3"
    },
    {
        "city": "Leicester",
        "location": "Leicester",
        "room_name": "Opal Court",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2022-03/iQ-Student-Accommodation-Leicester-Opal-Court-Bedrooms-B3-Gold-B57e-Gold-Gold_En_Suite_B57E%282%29.jpg?itok=8s08VzQQ",
        "room_link": "https://www.iqstudentaccommodation.com/leicester/opal-court/gold-en-suite",
        "details": [
            "Limited availability",
            "Private room",
            "Double bed",
            "11 m²",
            "Shower",
            "From £169 / week"
        ],
        "id": "a8c11a96d2aa466ba471cb22d1b41211"
    },
    {
        "city": "Leicester",
        "location": "Leicester",
        "room_name": "Opal Court",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2022-03/IQ-STU~3.JPG?itok=KQIGCpsn",
        "room_link": "https://www.iqstudentaccommodation.com/leicester/opal-court/bronze-en-suite",
        "details": [
            "Private room",
            "Small double bed",
            "11 m²",
            "Shower",
            "From £152 / week"
        ],
        "id": "8047cd8334c746d882686453188c5199"
    },
    {
        "city": "Leicester",
        "location": "Leicester",
        "room_name": "Opal Court",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2022-03/IQ9DA4~1.JPG?itok=zHQIcyh5",
        "room_link": "https://www.iqstudentaccommodation.com/leicester/opal-court/bronze-en-suite-plus",
        "details": [
            "Private room",
            "Small double bed",
            "13.5 m²",
            "Shower",
            "From £157 / week"
        ],
        "id": "33604fe3ca9a4e03a460edab63544665"
    },
    {
        "city": "Leicester",
        "location": "Leicester",
        "room_name": "Opal Court",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2022-03/iQ-Student-Accommodation-Leicester-Opal-Court-Bedrooms-B3-Bronze-B57e-Bronze-Bronze_Studio_B3%283%29.jpg?itok=Te05vxUG",
        "room_link": "https://www.iqstudentaccommodation.com/leicester/opal-court/bronze-studio",
        "details": [
            "Private studio",
            "Double bed",
            "25 m²",
            "Separate kitchen area",
            "From £190 / week"
        ],
        "id": "ece1663aaff440bfa67491a01edf0e70"
    },
    {
        "city": "Leicester",
        "location": "Leicester",
        "room_name": "Opal Court",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2022-03/iQ-Student-Accommodation-Leicester-Opal-Court-Bedrooms-A19-Copy-Gold_En_Suite_Plus_A19A%282%29.jpg?itok=2wgOW8if",
        "room_link": "https://www.iqstudentaccommodation.com/leicester/opal-court/gold-en-suite-plus",
        "details": [
            "Private room",
            "Double bed",
            "13.5 m²",
            "Shower",
            "From £165 / week"
        ],
        "id": "52c73da8d58f4a8db6f9d1d3c1be91ac"
    },
    {
        "city": "Leicester",
        "location": "Leicester",
        "room_name": "Opal Court",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2022-03/iQ-Student-Accommodation-Leicester-Opal-Court-Bedrooms-B3-Gold-B57e-Gold-Gold_Studio_B3%283%29.jpg?itok=aWfjP1Iq",
        "room_link": "https://www.iqstudentaccommodation.com/leicester/opal-court/gold-studio",
        "details": [
            "Private studio",
            "Double bed",
            "25 m²",
            "Dual occupancy available",
            "From £200 / week"
        ],
        "id": "fc1d194ec15a4352ab9a9a16b85b5db6"
    },
    {
        "city": "Leicester",
        "location": "Leicester",
        "room_name": "Opal Court",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2022-03/IQ-STU~2.JPG?itok=UDRhiJbJ",
        "room_link": "https://www.iqstudentaccommodation.com/leicester/opal-court/silver-en-suite",
        "details": [
            "Private room",
            "Small double bed",
            "11 m²",
            "Shower",
            "From £166 / week"
        ],
        "id": "c83760d0549141788318834bb497ff2a"
    },
    {
        "city": "Leicester",
        "location": "Leicester",
        "room_name": "Opal Court",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2022-03/IQ-STU~3_2.JPG?itok=T-Lr-JZ3",
        "room_link": "https://www.iqstudentaccommodation.com/leicester/opal-court/silver-en-suite-plus",
        "details": [
            "Private room",
            "Double bed",
            "13.5 m²",
            "Shower",
            "From £160 / week"
        ],
        "id": "8fda58e8764e48cebb0e8e29a8eb5edb"
    },
    {
        "city": "Leicester",
        "location": "Leicester",
        "room_name": "Opal Court",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2022-03/iQ-Student-Accommodation-Leicester-Opal-Court-Bedrooms-B3-Silver-B57e-Silver-Silver_Studio_B3%283%29.jpg?itok=ysO1PH8z",
        "room_link": "https://www.iqstudentaccommodation.com/leicester/opal-court/silver-studio",
        "details": [
            "Private studio",
            "Double bed",
            "25 m²",
            "Dual occupancy available",
            "From £197 / week"
        ],
        "id": "7a0219ab1ae74d128f223239119f3822"
    },
    {
        "city": "Leicester",
        "location": "Leicester",
        "room_name": "Opal Court",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2022-03/iQ-Student-Accommodation-Leicester-Opal-Court-Bedrooms-2-Bed-Apartment-Bedroom%281%29.jpg?itok=XTALuCAy",
        "room_link": "https://www.iqstudentaccommodation.com/leicester/opal-court/two-bed-apartment",
        "details": [
            "Private apartment",
            "Small double bed",
            "13.5 m²",
            "Shared bathroom",
            "From £168 / week"
        ],
        "id": "2702db9c8a3e4039886bee4561f5ec08"
    },
    {
        "city": "Leicester",
        "location": "Leicester",
        "room_name": "Opal Court",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2020-12/Leicester-Grosvenor-House-Bedrooms-Bronze_En_Suite%2811%29.jpg?itok=rvhJAL0r",
        "room_link": "https://www.iqstudentaccommodation.com/leicester/grosvenor-house/bronze-en-suite",
        "details": [
            "Private room",
            "Small double bed",
            "15-24 m²",
            "Shower",
            "From £142 / week"
        ],
        "id": "14c59d1beea0472fbeda7c3734ed7b79"
    },
    {
        "city": "Leicester",
        "location": "Leicester",
        "room_name": "Opal Court",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2020-12/Leicester-Grosvenor-House-Bedrooms-Bronze_En_Suite%2811%29.jpg?itok=rvhJAL0r",
        "room_link": "https://www.iqstudentaccommodation.com/leicester/grosvenor-house/bronze-en-suite-plus",
        "details": [
            "Private room",
            "Small double bed",
            "15-24 m²",
            "Shower",
            "From £132 / week"
        ],
        "id": "026fb04a626f4af39f8c17f4e15ae3fa"
    },
    {
        "city": "Lincoln",
        "location": "Lincoln",
        "room_name": "Pavilions",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-09/iQ-Student-Accommodation-Lincoln-Pavilions-Bedrooms-D1-D2-Bronze_En_Suite_Plus%282%29.jpg?itok=2U3USxlN",
        "room_link": "https://www.iqstudentaccommodation.com/lincoln/pavilions/bronze-en-suite-plus",
        "details": [
            "Limited availability",
            "Private room",
            "Small double bed",
            "15 m²",
            "Shared kitchen",
            "From £142 / week"
        ],
        "id": "d0dcc51a6b2a4f2e99d24eaa22b57ac0"
    },
    {
        "city": "Lincoln",
        "location": "Lincoln",
        "room_name": "Pavilions",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-09/iQ-Student-Accommodation-Lincoln-Pavilions-Bedrooms-A4-Copper_En_Suite%282%29.jpg?itok=uGhVcJ2n",
        "room_link": "https://www.iqstudentaccommodation.com/lincoln/pavilions/copper-en-suite",
        "details": [
            "Limited availability",
            "Private room",
            "Small double bed",
            "Shared kitchen",
            "Shower",
            "From £127 / week"
        ],
        "id": "1aba34b6aa7049e092f6ff99494f7797"
    },
    {
        "city": "Lincoln",
        "location": "Lincoln",
        "room_name": "Pavilions",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2022-10/IQBF5F~1.JPG?itok=tt5alLHG",
        "room_link": "https://www.iqstudentaccommodation.com/lincoln/pavilions/copper-en-suite-plus",
        "details": [
            "Private room",
            "Small double bed",
            "Shared kitchen",
            "Shower",
            "From £130 / week"
        ],
        "id": "a9c79e6814434da7b9891c8ad6532321"
    },
    {
        "city": "Lincoln",
        "location": "Lincoln",
        "room_name": "Pavilions",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-09/iQ-Student-Accommodation-Lincoln-Pavilions-Bedrooms-D1-D2-Gold_Studio_Deluxe%284%29.jpg?itok=I5dui4r7",
        "room_link": "https://www.iqstudentaccommodation.com/lincoln/pavilions/gold-studio-deluxe",
        "details": [
            "Limited availability",
            "Private studio",
            "Double bed",
            "Kitchenette",
            "TV in bedroom",
            "From £187 / week"
        ],
        "id": "42059d27ffe04cc4a703d9f45a9f2773"
    },
    {
        "city": "Lincoln",
        "location": "Lincoln",
        "room_name": "Pavilions",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2022-10/IQ-LIN~3_1.JPG?itok=Qs8HWBMa",
        "room_link": "https://www.iqstudentaccommodation.com/lincoln/pavilions/bronze-en-suite-premium",
        "details": [
            "Private room",
            "Small double bed",
            "15 m²",
            "Shared kitchen",
            "From £146 / week"
        ],
        "id": "d8b7089738b54e86895d7dcae9a4cb4c"
    },
    {
        "city": "Lincoln",
        "location": "Lincoln",
        "room_name": "Pavilions",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-09/iQ-Student-Accommodation-Lincoln-Pavilions-Bedrooms-A1-C1-C2-Gold_En_Suite%282%29.jpg?itok=m_vi_gtd",
        "room_link": "https://www.iqstudentaccommodation.com/lincoln/pavilions/gold-en-suite-plus",
        "details": [
            "Private room",
            "Small double bed",
            "Shared kitchen",
            "Shower",
            "From £184 / week"
        ],
        "id": "3088585292414498b80d067894f08edd"
    },
    {
        "city": "Lincoln",
        "location": "Lincoln",
        "room_name": "Pavilions",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-09/iQ-Student-Accommodation-Lincoln-Pavilions-Bedrooms-A1-C1-C2-Gold_En_Suite%282%29.jpg?itok=m_vi_gtd",
        "room_link": "https://www.iqstudentaccommodation.com/lincoln/pavilions/gold-en-suite",
        "details": [
            "Private room",
            "Small double bed",
            "Shared kitchen",
            "Shower",
            "From £179 / week"
        ],
        "id": "54dace96aea14b468792bb444fb957d6"
    },
    {
        "city": "Lincoln",
        "location": "Lincoln",
        "room_name": "Pavilions",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-09/iQ-Student-Accommodation-Lincoln-Pavilions-Bedrooms-D1-D2-Silver_En_Suite_Plus%281%29.jpg?itok=trtkIwTF",
        "room_link": "https://www.iqstudentaccommodation.com/lincoln/pavilions/silver-en-suite-plus",
        "details": [
            "Private room",
            "Small double bed",
            "Shared kitchen",
            "Shower",
            "From £170 / week"
        ],
        "id": "3151fb70210b42c0b2fa4a37897e2c54"
    },
    {
        "city": "Lincoln",
        "location": "Lincoln",
        "room_name": "Pavilions",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2022-10/iQ-Lincoln-Pavilions-4-IQ-Apartments-Block-D2-Apartment-18-Flat-B-Lincoln-2_Bed_Apt_Plus%282%29.jpg?itok=EOHT2Y92",
        "room_link": "https://www.iqstudentaccommodation.com/lincoln/pavilions/two-bed-apartment-plus",
        "details": [
            "Limited availability",
            "Private apartment",
            "Small double bed",
            "En suite",
            "TV in shared space",
            "From £191 / week"
        ],
        "id": "d5710831e90a468eb4d2ba754f5c376c"
    },
    {
        "city": "Lincoln",
        "location": "Lincoln",
        "room_name": "Pavilions",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-02/iQ-Student-Accommodation-Lincoln-Hayes-Wharf-Bedrooms-Bronze_En_Suite%282%29.jpg?itok=vP575dbL",
        "room_link": "https://www.iqstudentaccommodation.com/lincoln/hayes-wharf/bronze-en-suite",
        "details": [
            "Limited availability",
            "Private room",
            "Small double bed",
            "15 m²",
            "Shower",
            "From £124 / week"
        ],
        "id": "25e5bb6f59f3438dabd1f8a5dde42474"
    },
    {
        "city": "Lincoln",
        "location": "Lincoln",
        "room_name": "Pavilions",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-02/iQ-Student-Accommodation-Lincoln-Hayes-Wharf-Bedrooms-Gold_En_Suite%282%29_0.jpg?itok=OQjFojsa",
        "room_link": "https://www.iqstudentaccommodation.com/lincoln/hayes-wharf/gold-en-suite",
        "details": [
            "Limited availability",
            "Private room",
            "Small double bed",
            "22-24 m²",
            "Shower",
            "From £156 / week"
        ],
        "id": "c8af499ccba144078c5ea82ab81e60d6"
    },
    {
        "city": "Lincoln",
        "location": "Lincoln",
        "room_name": "Pavilions",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-02/iQ-Student-Accommodation-Lincoln-Hayes-Wharf-Bedrooms-Gold_En_Suite_Plus.jpg?itok=qcSI6ZPo",
        "room_link": "https://www.iqstudentaccommodation.com/lincoln/hayes-wharf/gold-en-suite-plus",
        "details": [
            "Limited availability",
            "Private room",
            "Small double bed",
            "22-24 m²",
            "Shower",
            "From £161 / week"
        ],
        "id": "1af0d7ac33174b1498cfb540b32c8181"
    },
    {
        "city": "Liverpool",
        "location": "Liverpool",
        "room_name": "Great Newton House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-01/iQ-Student-Accommodation-Liverpool-Great-Newton-House-Bedrooms-Gold_En_Suite%287%29_0.jpg?itok=p9J8IM4R",
        "room_link": "https://www.iqstudentaccommodation.com/liverpool/great-newton-house/silver-en-suite",
        "details": [
            "Limited availability",
            "Private room",
            "Small double bed",
            "14 m²",
            "Private bathroom",
            "From £150 / week"
        ],
        "id": "9924374f794446bdb3704224a1f74b07"
    },
    {
        "city": "Liverpool",
        "location": "Liverpool",
        "room_name": "Great Newton House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-01/iQ-Student-Accommodation-Liverpool-Great-Newton-House-Bedrooms-Gold_En_Suite%287%29_0.jpg?itok=p9J8IM4R",
        "room_link": "https://www.iqstudentaccommodation.com/liverpool/great-newton-house/silver-en-suite-premium",
        "details": [
            "Limited availability",
            "Private room",
            "Small double bed",
            "14 m²",
            "Private bathroom",
            "From £152 / week"
        ],
        "id": "7c00dc64935c42baba59ac7b8c82d11d"
    },
    {
        "city": "Liverpool",
        "location": "Liverpool",
        "room_name": "Great Newton House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2022-01/gold%20ensuite.jpg?itok=K6GI-Alm",
        "room_link": "https://www.iqstudentaccommodation.com/liverpool/great-newton-house/gold-en-suite",
        "details": [
            "Private room",
            "Small double bed",
            "14 m²",
            "Private bathroom",
            "From £153 / week"
        ],
        "id": "ffb87662734a40628b5c6aa4f42634f2"
    },
    {
        "city": "Liverpool",
        "location": "Liverpool",
        "room_name": "Great Newton House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-01/iQ-Student-Accommodation-Liverpool-Great-Newton-House-Bedrooms-Silver_En_Suite_Plus%286%29.jpg?itok=bg2VxG46",
        "room_link": "https://www.iqstudentaccommodation.com/liverpool/great-newton-house/silver-en-suite-plus",
        "details": [
            "Limited availability",
            "Private room",
            "Small double bed",
            "14-16 m²",
            "Private bathroom",
            "From £159 / week"
        ],
        "id": "81b93f9ca50e401ba4c063cb5d445316"
    },
    {
        "city": "Liverpool",
        "location": "Liverpool",
        "room_name": "Great Newton House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2020-12/iQ-Student-Accommodation-Liverpool-Great-Newton-House-Bedrooms-Gold_Two_Bed_Apartment%286%29.jpg?itok=Nu3se435",
        "room_link": "https://www.iqstudentaccommodation.com/liverpool/great-newton-house/gold-two-bed-apartment",
        "details": [
            "Limited availability",
            "Private apartment",
            "Small double bed",
            "15 m²",
            "Shared kitchen",
            "From £205 / week"
        ],
        "id": "087e2e6fd2364567a8b56c414d355793"
    },
    {
        "city": "Liverpool",
        "location": "Liverpool",
        "room_name": "Great Newton House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2022-05/iQ-Student-Accommodation-Liverpool-Great-Newton-House-Bedrooms-Gold_En_Suite%284%29_0.jpg?itok=A9dTA7j4",
        "room_link": "https://www.iqstudentaccommodation.com/liverpool/great-newton-house/bronze-en-suite",
        "details": [
            "Private room",
            "Small double bed",
            "14 m²",
            "Private bathroom",
            "From £140 / week"
        ],
        "id": "2eca1a5e46d547ceb685a9fe854d7b8d"
    },
    {
        "city": "Liverpool",
        "location": "Liverpool",
        "room_name": "Great Newton House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-01/iQ-Student-Accommodation-Liverpool-Great-Newton-House-Bedrooms-Gold_En_Suite%287%29_0.jpg?itok=p9J8IM4R",
        "room_link": "https://www.iqstudentaccommodation.com/liverpool/great-newton-house/bronze-en-suite-premium",
        "details": [
            "Private room",
            "Small double bed",
            "14 m²",
            "Private bathroom",
            "From £143 / week"
        ],
        "id": "90c8e84b47db4b06962689c2b749f173"
    },
    {
        "city": "Liverpool",
        "location": "Liverpool",
        "room_name": "Great Newton House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2022-05/iQ-Student-Accommodation-Liverpool-Great-Newton-House-Bedrooms-Gold_En_Suite%284%29_0.jpg?itok=A9dTA7j4",
        "room_link": "https://www.iqstudentaccommodation.com/liverpool/great-newton-house/bronze-en-suite-plus",
        "details": [
            "Private room",
            "Small double bed",
            "14 m²",
            "Private bathroom",
            "From £147 / week"
        ],
        "id": "e78f8e3f415945909fbacd31d7057145"
    },
    {
        "city": "Liverpool",
        "location": "Liverpool",
        "room_name": "Great Newton House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2022-05/iQ-Student-Accommodation-Liverpool-Great-Newton-House-Bedrooms-Gold_En_Suite%284%29_0.jpg?itok=A9dTA7j4",
        "room_link": "https://www.iqstudentaccommodation.com/liverpool/great-newton-house/bronze-en-suite-deluxe",
        "details": [
            "Private room",
            "Small double bed",
            "14 m²",
            "Private bathroom",
            "From £150 / week"
        ],
        "id": "01f9505269754707bb64790f31ac8d75"
    },
    {
        "city": "Liverpool",
        "location": "Liverpool",
        "room_name": "Great Newton House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2020-12/iQ-Student-Accommodation-Liverpool-Great-Newton-House-Bedrooms-Gold_En_Suite_Plus%286%29.jpg?itok=qHDyNjcK",
        "room_link": "https://www.iqstudentaccommodation.com/liverpool/great-newton-house/gold-3-bed-apartment",
        "details": [
            "Private apartment",
            "Small double bed",
            "14 m²",
            "Shared kitchen",
            "From £155 / week"
        ],
        "id": "5cefd9906e6a40cb89a1dac7a1c72296"
    },
    {
        "city": "Liverpool",
        "location": "Liverpool",
        "room_name": "Great Newton House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2020-12/iQ-Student-Accommodation-Liverpool-Great-Newton-House-Bedrooms-Gold_Mini_Studio_Plus%287%29.jpg?itok=saH3zlD2",
        "room_link": "https://www.iqstudentaccommodation.com/liverpool/great-newton-house/gold-3-bed-apt-plus",
        "details": [
            "Private apartment",
            "Small double bed",
            "14-16 m²",
            "Private bathroom",
            "From £160 / week"
        ],
        "id": "7a2494e0af3043da9162e742f992c32e"
    },
    {
        "city": "Liverpool",
        "location": "Liverpool",
        "room_name": "Great Newton House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2022-01/plus.jpg?itok=B8akeANy",
        "room_link": "https://www.iqstudentaccommodation.com/liverpool/great-newton-house/gold-en-suite-plus",
        "details": [
            "Private room",
            "Small double bed",
            "14-16 m²",
            "Private bathroom",
            "From £160 / week"
        ],
        "id": "bdc0fcea453748feb05de5e06150a261"
    },
    {
        "city": "London",
        "location": "London",
        "room_name": "Will Wyatt Court",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-06/iQ-Student-Accommodation-London-Will-Wyatt-Silver_En_Suite%286%29.jpg?itok=m6J21iG0",
        "room_link": "https://www.iqstudentaccommodation.com/london/will-wyatt-court/silver-en-suite",
        "details": [
            "Private room",
            "Small double bed",
            "11 m²",
            "Private bathroom",
            "From £365 / week"
        ],
        "id": "ce5ad1defbfe41afae52266b9c372c62"
    },
    {
        "city": "London",
        "location": "London",
        "room_name": "Will Wyatt Court",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2019-08/Silver%20Studio%20Deluxe%20%20-%20Shoreditch%20%2813%20of%2034%29_0.jpg?itok=6nQJ9UbB",
        "room_link": "https://www.iqstudentaccommodation.com/london/shoreditch/silver-studio-plus",
        "details": [
            "Private studio",
            "Small double bed",
            "19-21 m²",
            "Limited Free Dual Occupancy",
            "From £506 / week"
        ],
        "id": "6d3fd96a09c64a3b904d0045defa41c7"
    },
    {
        "city": "London",
        "location": "London",
        "room_name": "Will Wyatt Court",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2022-02/iQ-Student-Accommodation-Mile-End-Room-101-Duplicate-Standard_Studio%282%29_0.jpg?itok=haOG9des",
        "room_link": "https://www.iqstudentaccommodation.com/london/east-court/bronze-studio-plus",
        "details": [
            "Private studio",
            "Small double bed",
            "14 m²",
            "Private bathroom",
            "From £440 / week"
        ],
        "id": "2bfab0a7c7924105a6ac900dfba6eebb"
    },
    {
        "city": "London",
        "location": "London",
        "room_name": "Will Wyatt Court",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2023-11/IQ-STU_4_12.JPG?itok=gwRKTv9f",
        "room_link": "https://www.iqstudentaccommodation.com/london/east-court/silver-studio-deluxe",
        "details": [
            "Private studio",
            "Small double bed",
            "13 m²",
            "Newly refurbished",
            "From £428 / week"
        ],
        "id": "8052ed5ec6d24ca0b88d6758e5fa545e"
    },
    {
        "city": "London",
        "location": "London",
        "room_name": "Will Wyatt Court",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2024-02/Bronze_En_Suite_Plus-Refurb_C4B%282%29.jpg?itok=gHodvoV5",
        "room_link": "https://www.iqstudentaccommodation.com/london/tufnell-house/bronze-en-suite-plus",
        "details": [
            "Private room",
            "Double bed",
            "11 m²",
            "Newly refurbished",
            "From £353 / week"
        ],
        "id": "6f96c6987abe451da04f1b0c44e36be7"
    },
    {
        "city": "London",
        "location": "London",
        "room_name": "Will Wyatt Court",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2024-02/iQ-Student-Accommodation-London-City-Bedrooms-Bronze_Studio_Plus%284%29.jpg?itok=TMNtguYX",
        "room_link": "https://www.iqstudentaccommodation.com/london/city/gold-studio-plus",
        "details": [
            "Private studio",
            "Small double bed",
            "17-18 m²",
            "Newly refurbished",
            "From £517 / week"
        ],
        "id": "aec57a8eda834732815f79abad21c5dd"
    },
    {
        "city": "London",
        "location": "London",
        "room_name": "Will Wyatt Court",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2024-04/Room%20124%20-%201_0.jpg?itok=RgMX0emb",
        "room_link": "https://www.iqstudentaccommodation.com/london/vega/silver-studio",
        "details": [
            "Private studio",
            "Small double bed",
            "15 m²",
            "Floors 10-24",
            "From £521 / week"
        ],
        "id": "67f429a6be424f4dbf35a7b6ec107363"
    },
    {
        "city": "London",
        "location": "London",
        "room_name": "Will Wyatt Court",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2023-12/iQ-Student-Accommodation-London-East-Court-Bedrooms-Standard_Studio_M%285%29.jpg?itok=jXNbgdE_",
        "room_link": "https://www.iqstudentaccommodation.com/london/east-court/bronze-studio-deluxe",
        "details": [
            "Private studio",
            "Small double bed",
            "14 m²",
            "Newly refurbished",
            "From £416 / week"
        ],
        "id": "84b5554e16384c20ab299bb893c7ce6f"
    },
    {
        "city": "London",
        "location": "London",
        "room_name": "Will Wyatt Court",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-01/iQ-Student-Accommodation-London-Shoreditch-Bedrooms-Gold_Studio_Premium%286%29_0.jpg?itok=QTgg8UDI",
        "room_link": "https://www.iqstudentaccommodation.com/london/shoreditch/gold-studio",
        "details": [
            "Private studio",
            "Small double bed",
            "20-23 m²",
            "Dual occupancy available",
            "From £542 / week"
        ],
        "id": "41632a3ecd614cd2872d73c7bbe53b49"
    },
    {
        "city": "London",
        "location": "London",
        "room_name": "Will Wyatt Court",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2022-10/iQ-Student-Accommodation-London-Bankside-Bedrooms-Bronze_Studio_Deluxe%284%29.jpg?itok=K-zDcm5p",
        "room_link": "https://www.iqstudentaccommodation.com/london/bankside/bronze-studio-plus",
        "details": [
            "Private studio",
            "Small double bed",
            "17-19 m²",
            "Recently refurbished",
            "From £536 / week"
        ],
        "id": "ef0f89a02c6b4cddb6a86f9337788175"
    },
    {
        "city": "London",
        "location": "London",
        "room_name": "Will Wyatt Court",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2024-08/E6C-8-min.jpg?itok=00k7jj8H",
        "room_link": "https://www.iqstudentaccommodation.com/london/tufnell-house/gold-en-suite",
        "details": [
            "Limited availability",
            "Private room",
            "Small double bed",
            "14 m²",
            "Private bathroom",
            "From £404 / week"
        ],
        "id": "90158075c1c84d8990b9eab44ce239b8"
    },
    {
        "city": "London",
        "location": "London",
        "room_name": "Will Wyatt Court",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2022-02/iQ-Student-Accommodation-Mile-End-Room-W124-Standard_Window_Seat_Studio%288%29.jpg?itok=hZ6mwTQ5",
        "room_link": "https://www.iqstudentaccommodation.com/london/east-court/silver-studio-premium",
        "details": [
            "Limited availability",
            "Private studio",
            "Small double bed",
            "13 m²",
            "Recently refurbished",
            "From £422 / week"
        ],
        "id": "f20f384fb2024c8bb813a0709f9de04e"
    },
    {
        "city": "Manchester",
        "location": "Manchester",
        "room_name": "Lambert & Fairfield House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2022-10/IQ-STU~4_2.JPG?itok=qn-JgxMY",
        "room_link": "https://www.iqstudentaccommodation.com/manchester/lambert-fairfield-house/silver-en-suite",
        "details": [
            "Private room",
            "Small double bed",
            "11 m²",
            "Private bathroom",
            "From £235 / week"
        ],
        "id": "909753642a294a1bb4cbd550e7bd949a"
    },
    {
        "city": "Manchester",
        "location": "Manchester",
        "room_name": "Lambert & Fairfield House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2023-01/iQ-Student-Accommodation-Manchester-Manchester-Gardens-Copper_En_Suite_Plus_B1A%283%29.jpg?itok=_C01ezJQ",
        "room_link": "https://www.iqstudentaccommodation.com/manchester/manchester-gardens/silver-en-suite",
        "details": [
            "Private room",
            "Small double bed",
            "12 m²",
            "Private bathroom",
            "From £237 / week"
        ],
        "id": "c2909ac4668249e1a00c88c07c0bebf9"
    },
    {
        "city": "Manchester",
        "location": "Manchester",
        "room_name": "Lambert & Fairfield House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2023-11/iQ-Student-Accommodation-Huddersfield-Little-Aspley-Bedrooms-Bronze_En_Suite_Plus%284%29_2.jpg?itok=ZvGgs8Pk",
        "room_link": "https://www.iqstudentaccommodation.com/manchester/wilmslow-park/silver-en-suite",
        "details": [
            "Limited availability",
            "Private room",
            "Small double bed",
            "Private bathroom",
            "Shared kitchen",
            "From £260 / week"
        ],
        "id": "d9986948d2f341958a18c6527c0a569a"
    },
    {
        "city": "Manchester",
        "location": "Manchester",
        "room_name": "Lambert & Fairfield House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2023-11/iQ-Student-Accommodation-Manchester-Wilmslow-Park-Bedrooms-Silver_En_Suite_07A%282%29.jpg?itok=IMEGdtKg",
        "room_link": "https://www.iqstudentaccommodation.com/manchester/wilmslow-park/gold-en-suite",
        "details": [
            "Private room",
            "Small double bed",
            "Private bathroom",
            "Shared kitchen",
            "From £264 / week"
        ],
        "id": "a7e8ae26636247cea295b24097562f2d"
    },
    {
        "city": "Manchester",
        "location": "Manchester",
        "room_name": "Lambert & Fairfield House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-07/iQ-Student-Accommodation-Manchester-Wilmslow-Park-Bedrooms-Gold_En_Suite_27A%284%29_1.jpg?itok=A0MSaPMH",
        "room_link": "https://www.iqstudentaccommodation.com/manchester/wilmslow-park/platinum-en-suite",
        "details": [
            "Limited availability",
            "Private room",
            "Small double bed",
            "Private bathroom",
            "Shared kitchen",
            "From £268 / week"
        ],
        "id": "ef0a9cfe5b5e4e489e26bf95ce20638a"
    },
    {
        "city": "Manchester",
        "location": "Manchester",
        "room_name": "Lambert & Fairfield House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2023-11/iQ-Student-Accommodation-Manchester-Wilmslow-Park-Bedrooms-Silver_En_Suite_07A%284%29_0.jpg?itok=YHj0JRZm",
        "room_link": "https://www.iqstudentaccommodation.com/manchester/wilmslow-park/gold-en-suite-plus",
        "details": [
            "Limited availability",
            "Private room",
            "Double bed",
            "Private bathroom",
            "Shared kitchen",
            "From £270 / week"
        ],
        "id": "93e0ab25e742482cb2966469c757d14b"
    },
    {
        "city": "Manchester",
        "location": "Manchester",
        "room_name": "Lambert & Fairfield House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-07/iQ-Student-Accommodation-Manchester-Wilmslow-Park-Bedrooms-Gold_En_Suite_Plus_27D%283%29.jpg?itok=AB5FlJ9U",
        "room_link": "https://www.iqstudentaccommodation.com/manchester/wilmslow-park/platinum-en-suite-plus",
        "details": [
            "Limited availability",
            "Private room",
            "Double bed",
            "16-17 m²",
            "Private bathroom",
            "From £287 / week"
        ],
        "id": "2da41eebc9474323a30560517e66e3f5"
    },
    {
        "city": "Manchester",
        "location": "Manchester",
        "room_name": "Lambert & Fairfield House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-07/iQ-Student-Accommodation-Manchester-Wilmslow-Park-Bedrooms-Gold_En_Suite_Plus_27D%283%29.jpg?itok=AB5FlJ9U",
        "room_link": "https://www.iqstudentaccommodation.com/manchester/wilmslow-park/diamond-en-suite-plus",
        "details": [
            "Limited availability",
            "Private room",
            "Double bed",
            "TV in shared space",
            "Shower",
            "From £290 / week"
        ],
        "id": "6af003c0a63e4e8f9b270c4db3072f69"
    },
    {
        "city": "Manchester",
        "location": "Manchester",
        "room_name": "Lambert & Fairfield House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-11/Bronze%20Two%20Bed%20Duplex.jpg?itok=BDlO0kKA",
        "room_link": "https://www.iqstudentaccommodation.com/manchester/hollingworth-house/bronze-two-bed-duplex",
        "details": [
            "Limited availability",
            "Private room",
            "Double bed",
            "20-23 m²",
            "Washer/dryer",
            "From £298 / week"
        ],
        "id": "9fec8828b1544942b873aae0fde147e1"
    },
    {
        "city": "Manchester",
        "location": "Manchester",
        "room_name": "Lambert & Fairfield House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-11/Silver%20Two%20Bed%20Duplex.jpg?itok=5B6brkOA",
        "room_link": "https://www.iqstudentaccommodation.com/manchester/hollingworth-house/silver-two-bed-duplex",
        "details": [
            "Limited availability",
            "Private room",
            "Double bed",
            "20-23 m²",
            "Washer/dryer",
            "From £310 / week"
        ],
        "id": "1f8d1d6b5bc64f97a31bc86181bffc3d"
    },
    {
        "city": "Manchester",
        "location": "Manchester",
        "room_name": "Lambert & Fairfield House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-07/iQ-Student-Accommodation-Manchester-Kerria-Apartments-Rooms-14-15-Gold_Two_Bed_K14%283%29_1.jpg?itok=KFFESRgm",
        "room_link": "https://www.iqstudentaccommodation.com/manchester/kerria-apartments/silver-two-bed",
        "details": [
            "Limited availability",
            "Private apartment",
            "Double bed",
            "Shared kitchen",
            "Shared bathroom",
            "From £313 / week"
        ],
        "id": "08ba411bc5504f74bffff3c22ab7a25b"
    },
    {
        "city": "Manchester",
        "location": "Manchester",
        "room_name": "Lambert & Fairfield House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-11/Gold%20Two%20Bed%20Bedroom%20-%20Warehouse%20Two%20%281%20of%2033%29_1.jpg?itok=j3UvWj3n",
        "room_link": "https://www.iqstudentaccommodation.com/manchester/hollingworth-house/gold-two-bed-apartment",
        "details": [
            "Limited availability",
            "Private room",
            "Double bed",
            "20-23 m²",
            "Washer/dryer",
            "From £320 / week"
        ],
        "id": "1b97db3674db459d876393d2c7d7ab54"
    },
    {
        "city": "Nottingham",
        "location": "Nottingham",
        "room_name": "Exchange",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-08/iQ-Student-Accommodation-Nottingham-Exchange-Bedrooms-Bronze_En_Suite%282%29.jpg?itok=rTVeN6yB",
        "room_link": "https://www.iqstudentaccommodation.com/nottingham/exchange/bronze-en-suite",
        "details": [
            "Limited availability",
            "Private room",
            "Single bed",
            "Private bathroom",
            "TV in shared space",
            "From £154 / week"
        ],
        "id": "5a730f91c6f94cfe9c7da4ea55950611"
    },
    {
        "city": "Nottingham",
        "location": "Nottingham",
        "room_name": "Exchange",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2024-04/M016A%20-%203_1.jpg?itok=gzRJFYfX",
        "room_link": "https://www.iqstudentaccommodation.com/nottingham/signal-place/silver-en-suite",
        "details": [
            "Limited availability",
            "Private room",
            "Small double bed",
            "11-12 m²",
            "Private bathroom",
            "From £164 / week"
        ],
        "id": "0faa1ac5fd6e4c76b7e630266c2488fb"
    },
    {
        "city": "Nottingham",
        "location": "Nottingham",
        "room_name": "Exchange",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2024-04/M404A%20-%204_1.jpg?itok=d4T9wIWY",
        "room_link": "https://www.iqstudentaccommodation.com/nottingham/signal-place/bronze-en-suite-plus",
        "details": [
            "Limited availability",
            "Private room",
            "Small double bed",
            "11-12 m²",
            "Floors 1-5",
            "From £165 / week"
        ],
        "id": "221c936aa28f4e2ea4f4ea9f9e384c7c"
    },
    {
        "city": "Nottingham",
        "location": "Nottingham",
        "room_name": "Exchange",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-08/iQ-Student-Accommodation-Nottingham-Exchange-Bedrooms-Silver_En_Suite%286%29.jpg?itok=_1nubSkM",
        "room_link": "https://www.iqstudentaccommodation.com/nottingham/exchange/silver-en-suite",
        "details": [
            "Limited availability",
            "Private room",
            "Small double bed",
            "Private bathroom",
            "TV in shared space",
            "From £166 / week"
        ],
        "id": "a3f270662cd44218ac6586d7fb0cf42a"
    },
    {
        "city": "Nottingham",
        "location": "Nottingham",
        "room_name": "Exchange",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2024-04/C301E%20-%205_1.jpg?itok=GVXx8Qvi",
        "room_link": "https://www.iqstudentaccommodation.com/nottingham/signal-place/silver-en-suite-plus",
        "details": [
            "Limited availability",
            "Private room",
            "Small double bed",
            "11-12 m²",
            "Floors 3-4",
            "From £171 / week"
        ],
        "id": "88a6eb55748e45f18fad6582be28bff9"
    },
    {
        "city": "Nottingham",
        "location": "Nottingham",
        "room_name": "Exchange",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2024-10/Kitchen%20421-10.jpg?itok=zsqeABkO",
        "room_link": "https://www.iqstudentaccommodation.com/nottingham/exchange/gold-en-suite",
        "details": [
            "Limited availability",
            "Private room",
            "Small double bed",
            "Private bathroom",
            "TV in shared space",
            "From £171 / week"
        ],
        "id": "243e65bb74bb4566a293a17f13e7f521"
    },
    {
        "city": "Nottingham",
        "location": "Nottingham",
        "room_name": "Exchange",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2024-04/M128B%20-%209_1.jpg?itok=EPEUY76U",
        "room_link": "https://www.iqstudentaccommodation.com/nottingham/signal-place/bronze-en-suite",
        "details": [
            "Limited availability",
            "Private room",
            "Small double bed",
            "11-12 m²",
            "Floors 1-5",
            "From £172 / week"
        ],
        "id": "f4bbc129df9f44adab0f626db940194f"
    },
    {
        "city": "Nottingham",
        "location": "Nottingham",
        "room_name": "Exchange",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2024-04/C002A%20-%2010_1.jpg?itok=vGEFoNVW",
        "room_link": "https://www.iqstudentaccommodation.com/nottingham/signal-place/gold-en-suite",
        "details": [
            "Limited availability",
            "Private room",
            "Small double bed",
            "11-12 m²",
            "Private bathroom",
            "From £173 / week"
        ],
        "id": "af39fca8789b45aead1ac5ccc5a693e9"
    },
    {
        "city": "Nottingham",
        "location": "Nottingham",
        "room_name": "Exchange",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-08/iQ-Student-Accommodation-Newland-House-Nottingham-Bedrooms-Bronze_Two_Bed_Apartment.jpg?itok=iFXf-c9z",
        "room_link": "https://www.iqstudentaccommodation.com/nottingham/newland-house/bronze-two-bed-apartment",
        "details": [
            "Limited availability",
            "Private apartment",
            "Double bed",
            "TV in shared space",
            "Shared bathroom",
            "From £175 / week"
        ],
        "id": "3fa6071c5b65401880f3b9fee9b0ee40"
    },
    {
        "city": "Nottingham",
        "location": "Nottingham",
        "room_name": "Exchange",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-08/iQ-Student-Accommodation-Nottingham-Exchange-Bedrooms-Platinum_En_Suite%282%29.jpg?itok=oE7UjNht",
        "room_link": "https://www.iqstudentaccommodation.com/nottingham/exchange/platinum-en-suite",
        "details": [
            "Limited availability",
            "Private room",
            "Small double bed",
            "TV in shared space",
            "Private bathroom",
            "From £178 / week"
        ],
        "id": "067ce94c32554117a6547546fe5e07ef"
    },
    {
        "city": "Nottingham",
        "location": "Nottingham",
        "room_name": "Exchange",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-08/iQ-Student-Accommodation-Nottingham-Exchange-Bedrooms-Duplicate-Penthouse_Room%285%29.jpg?itok=yTwKA-yM",
        "room_link": "https://www.iqstudentaccommodation.com/nottingham/exchange/penthouse-room",
        "details": [
            "Limited availability",
            "Private room",
            "Small double bed",
            "City view",
            "Private bathroom",
            "From £179 / week"
        ],
        "id": "9010eb99b2bf4147a0484a50f9251086"
    },
    {
        "city": "Nottingham",
        "location": "Nottingham",
        "room_name": "Exchange",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2024-04/C303B%20-%2013_1.jpg?itok=pDjcozvy",
        "room_link": "https://www.iqstudentaccommodation.com/nottingham/signal-place/gold-en-suite-plus",
        "details": [
            "Limited availability",
            "Private room",
            "Small double bed",
            "11-12 m²",
            "Floors 3-4",
            "From £180 / week"
        ],
        "id": "933ad270f33545b9bf4257f8d1f024bf"
    },
    {
        "city": "Oxford",
        "location": "Oxford",
        "room_name": "Alice House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-09/iQ-Student-Accommodation-Oxford-Alice-House-Bedrooms-Bronze_Studio%284%29.jpg?itok=ncgyt617",
        "room_link": "https://www.iqstudentaccommodation.com/oxford/alice-house/bronze-studio",
        "details": [
            "Private studio",
            "Double bed",
            "18 m²",
            "TV in bedroom",
            "From £341 / week"
        ],
        "id": "58f9289e2bb54b5384cc5f6433938886"
    },
    {
        "city": "Oxford",
        "location": "Oxford",
        "room_name": "Alice House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-09/iQ-Student-Accommodation-Oxford-Alice-House-Bedrooms-Bronze_Studio_Plus%284%29.jpg?itok=xV31Aph1",
        "room_link": "https://www.iqstudentaccommodation.com/oxford/alice-house/bronze-studio-plus",
        "details": [
            "Private studio",
            "Double bed",
            "18 m²",
            "TV in bedroom",
            "From £346 / week"
        ],
        "id": "b41fa41cca7845188551c65727be3095"
    },
    {
        "city": "Oxford",
        "location": "Oxford",
        "room_name": "Alice House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-09/iQ-Student-Accommodation-Oxford-Alice-House-Bedrooms-Silver_Studio%284%29.jpg?itok=vmAOO4C2",
        "room_link": "https://www.iqstudentaccommodation.com/oxford/alice-house/silver-studio",
        "details": [
            "Private studio",
            "Double bed",
            "18 m²",
            "Limited Free Dual Occupancy",
            "From £375 / week"
        ],
        "id": "01e2fc5fa6ea4c22b7a773e6be9fc60c"
    },
    {
        "city": "Oxford",
        "location": "Oxford",
        "room_name": "Alice House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-09/iQ-Student-Accommodation-Oxford-Alice-House-Bedrooms-Silver_Studio_Plus%284%29.jpg?itok=6SWBWfgI",
        "room_link": "https://www.iqstudentaccommodation.com/oxford/alice-house/silver-studio-plus",
        "details": [
            "Private studio",
            "Double bed",
            "18 m²",
            "TV in bedroom",
            "From £380 / week"
        ],
        "id": "8ea640bf4284487bb2dbc3fb28b35e53"
    },
    {
        "city": "Oxford",
        "location": "Oxford",
        "room_name": "Alice House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-09/iQ-Student-Accommodation-Oxford-Alice-House-Bedrooms-Gold_Studio%289%29.jpg?itok=Pb4hhAF9",
        "room_link": "https://www.iqstudentaccommodation.com/oxford/alice-house/gold-studio",
        "details": [
            "Private studio",
            "Double bed",
            "23 m²",
            "Dual occupancy available",
            "From £403 / week"
        ],
        "id": "dcc126bb94b64d0cafc3ed5e5ce06298"
    },
    {
        "city": "Plymouth",
        "location": "Plymouth",
        "room_name": "Astor House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-08/iQ-Student-Accommodation-Plymouth-Astor-House-Bedrooms-Bronze_En_Suite%282%29.jpg?itok=TuDu_E7n",
        "room_link": "https://www.iqstudentaccommodation.com/plymouth/astor-house/bronze-en-suite",
        "details": [
            "Private room",
            "Small double bed",
            "12-13 m²",
            "Private bathroom",
            "From £149 / week"
        ],
        "id": "09d2de50e1394b65bac78305a409457e"
    },
    {
        "city": "Plymouth",
        "location": "Plymouth",
        "room_name": "Astor House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2024-10/89.4_0.jpg?itok=yoEgJrN9",
        "room_link": "https://www.iqstudentaccommodation.com/plymouth/astor-house/bronze-en-suite-plus",
        "details": [
            "Private room",
            "Small double bed",
            "12-13 m²",
            "Private bathroom",
            "From £152 / week"
        ],
        "id": "1efe8d8fd065458981a8b82b9a7b3c92"
    },
    {
        "city": "Plymouth",
        "location": "Plymouth",
        "room_name": "Astor House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-08/iQ-Student-Accommodation-Plymouth-Astor-House-Bedrooms-Bronze_Studio%285%29.jpg?itok=eWJE-_DH",
        "room_link": "https://www.iqstudentaccommodation.com/plymouth/astor-house/bronze-studio",
        "details": [
            "Private studio",
            "Double bed",
            "16-17 m²",
            "Private kitchen",
            "From £174 / week"
        ],
        "id": "9ad4a2e68e22431fac60f51969797ced"
    },
    {
        "city": "Plymouth",
        "location": "Plymouth",
        "room_name": "Astor House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-08/iQ-Student-Accommodation-Plymouth-Astor-House-Bedrooms-Bronze_Studio%285%29.jpg?itok=eWJE-_DH",
        "room_link": "https://www.iqstudentaccommodation.com/plymouth/astor-house/bronze-studio-plus",
        "details": [
            "Private studio",
            "Double bed",
            "16-17 m²",
            "Private kitchen",
            "From £181 / week"
        ],
        "id": "312b5a1940e94b62bc610c6d16fe5563"
    },
    {
        "city": "Plymouth",
        "location": "Plymouth",
        "room_name": "Astor House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-08/iQ-Student-Accommodation-Plymouth-Astor-House-Bedrooms-Copper_Studio%284%29.jpg?itok=7bHZyWc3",
        "room_link": "https://www.iqstudentaccommodation.com/plymouth/astor-house/copper-studio",
        "details": [
            "Private studio",
            "Small double bed",
            "14-15 m²",
            "Private kitchen",
            "From £179 / week"
        ],
        "id": "6f339082b5f9425dbd8470f362da6496"
    },
    {
        "city": "Plymouth",
        "location": "Plymouth",
        "room_name": "Astor House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-08/iQ-Student-Accommodation-Plymouth-Astor-House-Bedrooms-Gold_En_Suite%283%29.jpg?itok=Tpcbf8ri",
        "room_link": "https://www.iqstudentaccommodation.com/plymouth/astor-house/gold-en-suite",
        "details": [
            "Private room",
            "Small double bed",
            "Private bathroom",
            "Shared kitchen",
            "From £195 / week"
        ],
        "id": "cccee8339e7f4b839e4f1cfb347b56a4"
    },
    {
        "city": "Plymouth",
        "location": "Plymouth",
        "room_name": "Astor House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-08/iQ-Student-Accommodation-Plymouth-Astor-House-Bedrooms-Gold_Studio%285%29.jpg?itok=E-17Z6ve",
        "room_link": "https://www.iqstudentaccommodation.com/plymouth/astor-house/gold-studio",
        "details": [
            "Private studio",
            "Double bed",
            "23-26 m²",
            "Dual occupancy available",
            "From £258 / week"
        ],
        "id": "a958a1018fe14962ba33141463463481"
    },
    {
        "city": "Plymouth",
        "location": "Plymouth",
        "room_name": "Astor House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-08/iQ-Student-Accommodation-Plymouth-Astor-House-Bedrooms-Gold_Studio%285%29.jpg?itok=E-17Z6ve",
        "room_link": "https://www.iqstudentaccommodation.com/plymouth/astor-house/gold-studio-plus",
        "details": [
            "Private studio",
            "Double bed",
            "23-26 m²",
            "Dual occupancy available",
            "From £236 / week"
        ],
        "id": "7592b5278ebb4467a472e79a545ab852"
    },
    {
        "city": "Plymouth",
        "location": "Plymouth",
        "room_name": "Astor House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-08/iQ-Student-Accommodation-Plymouth-Astor-House-Bedrooms-Platinum_En_Suite%282%29.jpg?itok=0Ar2tKWk",
        "room_link": "https://www.iqstudentaccommodation.com/plymouth/astor-house/platinum-en-suite",
        "details": [
            "Private room",
            "Small double bed",
            "21-22 m²",
            "Private bathroom",
            "From £214 / week"
        ],
        "id": "0e9ab611dc874cd9af0a7566c227ec72"
    },
    {
        "city": "Plymouth",
        "location": "Plymouth",
        "room_name": "Astor House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-08/iQ-Student-Accommodation-Plymouth-Astor-House-Bedrooms-Platinum_Studio%284%29.jpg?itok=tMfRtsJN",
        "room_link": "https://www.iqstudentaccommodation.com/plymouth/astor-house/platinum-studio",
        "details": [
            "Private studio",
            "Double bed",
            "27-30 m²",
            "Dual occupancy available",
            "From £293 / week"
        ],
        "id": "ff77deb2f7004a2e8114f8f2111cf45b"
    },
    {
        "city": "Plymouth",
        "location": "Plymouth",
        "room_name": "Astor House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-08/iQ-Student-Accommodation-Plymouth-Astor-House-Bedrooms-Silver_En_Suite%282%29.jpg?itok=K4Sdb7X1",
        "room_link": "https://www.iqstudentaccommodation.com/plymouth/astor-house/silver-en-suite",
        "details": [
            "Private room",
            "Small double bed",
            "14-15 m²",
            "Private bathroom",
            "From £164 / week"
        ],
        "id": "9e626a04afbf4bafa2414d999e942988"
    },
    {
        "city": "Plymouth",
        "location": "Plymouth",
        "room_name": "Astor House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2024-10/107.6-16.jpg?itok=k3oTaGoJ",
        "room_link": "https://www.iqstudentaccommodation.com/plymouth/astor-house/silver-en-suite-plus",
        "details": [
            "Private room",
            "Small double bed",
            "14-15 m²",
            "Private bathroom",
            "From £167 / week"
        ],
        "id": "dd79a33c1adc4cb595af5e3e677a28e1"
    },
    {
        "city": "Preston",
        "location": "Preston",
        "room_name": "Kopa",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-07/iQ-Student-Accommodation-Preston-Kopa-Bedrooms-Bronze_En_Suite_Plus_70A%282%29.jpg?itok=279AgJVP",
        "room_link": "https://www.iqstudentaccommodation.com/preston/kopa/bronze-en-suite-plus",
        "details": [
            "Limited availability",
            "Private room",
            "Small double bed",
            "Private bathroom",
            "Shared kitchen",
            "From £116 / week"
        ],
        "id": "16d1cca9baea4330812a8fc9a44957b6"
    },
    {
        "city": "Preston",
        "location": "Preston",
        "room_name": "Kopa",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-07/iQ-Student-Accommodation-Preston-Kopa-Bedrooms-Bronze_En_Suite_48D%283%29.jpg?itok=TkBtRXub",
        "room_link": "https://www.iqstudentaccommodation.com/preston/kopa/bronze-en-suite",
        "details": [
            "Limited availability",
            "Private room",
            "Single bed",
            "Private bathroom",
            "Shared kitchen",
            "From £111 / week"
        ],
        "id": "43f9ae994bbd435e951a0a4f498e4ddf"
    },
    {
        "city": "Preston",
        "location": "Preston",
        "room_name": "Kopa",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-07/iQ-Student-Accommodation-Preston-Kopa-Bedrooms-Gold_En_Suite_59C%283%29.jpg?itok=Ys-go2cQ",
        "room_link": "https://www.iqstudentaccommodation.com/preston/kopa/gold-en-suite",
        "details": [
            "Private room",
            "Small double bed",
            "Private bathroom",
            "Shared kitchen",
            "From £139 / week"
        ],
        "id": "93ecba80e84c42a8a0c4b89315c7605b"
    },
    {
        "city": "Preston",
        "location": "Preston",
        "room_name": "Kopa",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-07/iQ-Student-Accommodation-Preston-Kopa-Bedrooms-Platinum_En_Suite_18E%282%29.jpg?itok=AAHBlDta",
        "room_link": "https://www.iqstudentaccommodation.com/preston/kopa/platinum-en-suite",
        "details": [
            "Private room",
            "Double bed",
            "Private bathroom",
            "Shared kitchen",
            "From £152 / week"
        ],
        "id": "e50bdbb337024356a9ee5f1fe9c1fcd2"
    },
    {
        "city": "Preston",
        "location": "Preston",
        "room_name": "Kopa",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-07/iQ-Student-Accommodation-Preston-Kopa-Bedrooms-Silver_Studio_93S%284%29.jpg?itok=aVXca4TC",
        "room_link": "https://www.iqstudentaccommodation.com/preston/kopa/silver-studio",
        "details": [
            "Private studio",
            "Double bed",
            "Dual occupancy available",
            "Private kitchen",
            "From £173 / week"
        ],
        "id": "b13affceca7142f79dbf545ae831a79f"
    },
    {
        "city": "Salford",
        "location": "Salford",
        "room_name": "Student Quarter",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2022-11/Student-Quarter-Blake-Block-Silver_En_Suite_Plus%286%29.jpg?itok=1FVCOVCf",
        "room_link": "https://www.iqstudentaccommodation.com/salford/student-quarter/gold-en-suite",
        "details": [
            "Private room",
            "Small double bed",
            "15 m²",
            "Recently refurbished",
            "From £222 / week"
        ],
        "id": "6fbabd61f32f485d9ca0a6f2a9c1840d"
    },
    {
        "city": "Salford",
        "location": "Salford",
        "room_name": "Student Quarter",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2020-11/Student%20quarter%20penthouse%20ES%20plus%203_0.jpg?itok=QcgrVI3A",
        "room_link": "https://www.iqstudentaccommodation.com/salford/student-quarter/penthouse-en-suite",
        "details": [
            "Private room",
            "Small double bed",
            "15sqm living space",
            "Recently refurbished",
            "From £227 / week"
        ],
        "id": "7f2d3d2904a947289e136ccd6506fbc5"
    },
    {
        "city": "Salford",
        "location": "Salford",
        "room_name": "Student Quarter",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-09/IQ-STU~3_6.JPG?itok=paSZ2hdO",
        "room_link": "https://www.iqstudentaccommodation.com/salford/student-quarter/silver-en-suite",
        "details": [
            "Limited availability",
            "Private room",
            "Small double bed",
            "15 m²",
            "Recently refurbished",
            "From £207 / week"
        ],
        "id": "38c31b91b7d746a993c738e258ef2527"
    },
    {
        "city": "Salford",
        "location": "Salford",
        "room_name": "Student Quarter",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-09/iQ-Student-Accommodation-Manchester-Student-Quarter-Bronte-Block-Gold_Mini_Studio_7A%282%29.jpg?itok=Mk3B-dPS",
        "room_link": "https://www.iqstudentaccommodation.com/salford/student-quarter/gold-mini-studio",
        "details": [
            "Limited availability",
            "Private room",
            "Double bed",
            "14 m²",
            "Recently refurbished",
            "From £244 / week"
        ],
        "id": "14e2844e51114650b59569fd2ff95041"
    },
    {
        "city": "Salford",
        "location": "Salford",
        "room_name": "Student Quarter",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-09/IQ-STU~3_7.JPG?itok=AfcJao5_",
        "room_link": "https://www.iqstudentaccommodation.com/salford/student-quarter/platinum-mini-studio",
        "details": [
            "Limited availability",
            "Private room",
            "Double bed",
            "Recently refurbished",
            "TV in shared space",
            "From £251 / week"
        ],
        "id": "7cad93602f734298a9b8839b2b553457"
    },
    {
        "city": "Salford",
        "location": "Salford",
        "room_name": "Student Quarter",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2024-08/IQC27A~1_0.jpg?itok=7fiEM24i",
        "room_link": "https://www.iqstudentaccommodation.com/salford/student-quarter/silver-en-suite-plus",
        "details": [
            "Limited availability",
            "Private room",
            "Small double bed",
            "15 m²",
            "TV in shared space",
            "From £215 / week"
        ],
        "id": "b21a26ddb28a4501a51e33a7162b52ed"
    },
    {
        "city": "Salford",
        "location": "Salford",
        "room_name": "Student Quarter",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-09/IQ-STU~2.JPG?itok=2tDbQQaR",
        "room_link": "https://www.iqstudentaccommodation.com/salford/student-quarter/bronze-en-suite",
        "details": [
            "Private room",
            "Single bed",
            "15 m²",
            "Recently refurbished",
            "From £195 / week"
        ],
        "id": "649836b00c3a46d38fe11bf671f57f0d"
    },
    {
        "city": "Sheffield",
        "location": "Sheffield",
        "room_name": "Brocco",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2023-02/iQ-Student-Accommodation-Sheffield-Brocco-Bronze-En-Suite-Accessible-and-Copper-En-Suite-Copper_En_Suite%282%29.jpg?itok=8NaksY5T",
        "room_link": "https://www.iqstudentaccommodation.com/sheffield/brocco/copper-en-suite",
        "details": [
            "Limited availability",
            "Private room",
            "Small double bed",
            "TV in shared space",
            "Shower",
            "From £108 / week"
        ],
        "id": "9574fe20050b4decbb97857b9d03c2b4"
    },
    {
        "city": "Sheffield",
        "location": "Sheffield",
        "room_name": "Brocco",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2023-02/iQ-Student-Accommodation-Sheffield-Brocco-Copper-En-Suite-Accessible-Copper_En_Suite_Accessible%283%29.jpg?itok=I2hxsPMD",
        "room_link": "https://www.iqstudentaccommodation.com/sheffield/brocco/copper-en-suite-accessible",
        "details": [
            "Limited availability",
            "Private room",
            "Small double bed",
            "TV in shared space",
            "Shower",
            "From £108 / week"
        ],
        "id": "be3223a0e47a4c61a29530449fb338a7"
    },
    {
        "city": "Sheffield",
        "location": "Sheffield",
        "room_name": "Brocco",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2023-02/iQ-Student-Accommodation-Sheffield-Brocco-Bronze-En-Suite-Accessible-and-Copper-En-Suite-Copper_En_Suite%282%29.jpg?itok=8NaksY5T",
        "room_link": "https://www.iqstudentaccommodation.com/sheffield/brocco/bronze-en-suite",
        "details": [
            "Limited availability",
            "Private room",
            "Small double bed",
            "TV in shared space",
            "Shower",
            "From £114 / week"
        ],
        "id": "318f3ec05b71429f92bd646951dbe2d8"
    },
    {
        "city": "Sheffield",
        "location": "Sheffield",
        "room_name": "Brocco",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2023-02/iQ-Student-Accommodation-Sheffield-Brocco-Bronze-En-Suite-Accessible-and-Copper-En-Suite-Bronze_En_Suite_Accessible%283%29_0.jpg?itok=mKFt9KWt",
        "room_link": "https://www.iqstudentaccommodation.com/sheffield/brocco/bronze-en-suite-accessible-0",
        "details": [
            "Limited availability",
            "Private room",
            "Small double bed",
            "12 m²",
            "En suite",
            "From £114 / week"
        ],
        "id": "5d3ebebe1f694dceae5278293dbc5278"
    },
    {
        "city": "Sheffield",
        "location": "Sheffield",
        "room_name": "Brocco",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2023-02/iQ-Student-Accommodation-Sheffield-Fenton-House-Bronze-En-Suite-Bronze_En_Suite%287%29.jpg?itok=xhKpIAZ7",
        "room_link": "https://www.iqstudentaccommodation.com/sheffield/fenton-house/bronze-en-suite",
        "details": [
            "Limited availability",
            "Private room",
            "Small double bed",
            "13 m²",
            "En suite",
            "From £118 / week"
        ],
        "id": "e4bb4bbce2064f76b99f12bceb90642e"
    },
    {
        "city": "Sheffield",
        "location": "Sheffield",
        "room_name": "Brocco",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2023-02/iQ-Student-Accommodation-Sheffield-Knight-House-5-Bed-Non-En-Suite-02242023_120253.jpg?itok=zzZgk88H",
        "room_link": "https://www.iqstudentaccommodation.com/sheffield/knight-house/5-bed-silver-room",
        "details": [
            "Limited availability",
            "Private room",
            "Small double bed",
            "10 m²",
            "Shared bathroom",
            "From £120 / week"
        ],
        "id": "671cbabac6904393a88440714443502d"
    },
    {
        "city": "Sheffield",
        "location": "Sheffield",
        "room_name": "Brocco",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2023-02/iQ-Student-Accommodation-Sheffield-Fenton-House-Bronze-En-Suite-Plus-02092023_140834.jpg?itok=QDrRvwvQ",
        "room_link": "https://www.iqstudentaccommodation.com/sheffield/fenton-house/bronze-en-suite-plus",
        "details": [
            "Private room",
            "Small double bed",
            "13.5 m²",
            "En suite",
            "From £122 / week"
        ],
        "id": "bdf0ae47a0b04e1a98a1ed01e81e15ed"
    },
    {
        "city": "Sheffield",
        "location": "Sheffield",
        "room_name": "Brocco",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2023-02/iQ-Student-Accommodation-Sheffield-Brocco-Bronze-and-Silver-En-Suite-Plus-Bronze_En_Suite_Plus%282%29.jpg?itok=KlYdP-XJ",
        "room_link": "https://www.iqstudentaccommodation.com/sheffield/brocco/bronze-en-suite-plus",
        "details": [
            "Limited availability",
            "Private room",
            "Small double bed",
            "12 m²",
            "En suite",
            "From £123 / week"
        ],
        "id": "de537258a5a74d2c9df16bb3e1f4a805"
    },
    {
        "city": "Sheffield",
        "location": "Sheffield",
        "room_name": "Brocco",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2023-02/iQ-Student-Accommodation-Sheffield-Steel-02032023_152103_0.jpg?itok=b9NNHLHl",
        "room_link": "https://www.iqstudentaccommodation.com/sheffield/steel/copper-en-suite",
        "details": [
            "Limited availability",
            "Private room",
            "Small double bed",
            "TV in shared space",
            "Private bathroom",
            "From £124 / week"
        ],
        "id": "982cb3b817704d4db91340ca63fdc4de"
    },
    {
        "city": "Sheffield",
        "location": "Sheffield",
        "room_name": "Brocco",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2024-08/iQ-Student-Accommodation-Sheffield-Steel-Alternative-Bedroom-Flat-3A%283%29.jpg?itok=AJA3-hWK",
        "room_link": "https://www.iqstudentaccommodation.com/sheffield/steel/copper-en-suite-plus",
        "details": [
            "Limited availability",
            "Private room",
            "Small double bed",
            "12 m²",
            "En suite",
            "From £126 / week"
        ],
        "id": "a0c90a39c20844e98b0c0bbc73312270"
    },
    {
        "city": "Sheffield",
        "location": "Sheffield",
        "room_name": "Brocco",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2023-02/iQ-Student-Accommodation-Sheffield-Steel-Bronze_En_Suite%282%29.jpg?itok=V8Pihhi8",
        "room_link": "https://www.iqstudentaccommodation.com/sheffield/steel/bronze-en-suite",
        "details": [
            "Limited availability",
            "Private room",
            "Small double bed",
            "12 m²",
            "Private bathroom",
            "From £127 / week"
        ],
        "id": "fee47e521628492faac930d4168b6561"
    },
    {
        "city": "Sheffield",
        "location": "Sheffield",
        "room_name": "Brocco",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2020-11/iQ-Student-Accommodation-Sheffield-Fenton-House-Bedrooms-Bronze_En_Suite%281%29_2.jpg?itok=wL7Tj_IZ",
        "room_link": "https://www.iqstudentaccommodation.com/sheffield/fenton-house/copper-en-suite",
        "details": [
            "Limited availability",
            "Private room",
            "Small double bed",
            "13.5 m²",
            "En suite",
            "From £127 / week"
        ],
        "id": "08e15e353ecc4f4795c3d6fa2e2bea1a"
    },
    {
        "city": "Wolverhampton",
        "location": "Wolverhampton",
        "room_name": "Fiveways House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-08/iQ-Student-Accommodation-Wolverhampton-Fiveways-Bedrooms-Block-A-Copper_En_Suite%282%29.jpg?itok=NcH5Eygl",
        "room_link": "https://www.iqstudentaccommodation.com/wolverhampton/fiveways-house/copper-en-suite",
        "details": [
            "Private room",
            "Small double bed",
            "13 m²",
            "Shower",
            "From £93 / week"
        ],
        "id": "e9ca2694176f4cf6ab167f802470e10a"
    },
    {
        "city": "Wolverhampton",
        "location": "Wolverhampton",
        "room_name": "Fiveways House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-08/iQ-Student-Accommodation-Wolverhampton-Fiveways-Bedrooms-Block-A-Bronze_En_Suite%282%29.jpg?itok=rxli3fDx",
        "room_link": "https://www.iqstudentaccommodation.com/wolverhampton/fiveways-house/bronze-en-suite",
        "details": [
            "Private room",
            "Double bed",
            "15 m²",
            "Shower",
            "From £94 / week"
        ],
        "id": "5aa14544f2df4744976836418a12ba2e"
    },
    {
        "city": "Wolverhampton",
        "location": "Wolverhampton",
        "room_name": "Fiveways House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-08/iQ-Student-Accommodation-Wolverhampton-Fiveways-Bedrooms-Block-BDG-Silver_En_Suite_B3A%281%29.jpg?itok=qywh9npX",
        "room_link": "https://www.iqstudentaccommodation.com/wolverhampton/fiveways-house/silver-en-suite",
        "details": [
            "Private room",
            "Small double bed",
            "13 m²",
            "Study desk and chair",
            "From £98 / week"
        ],
        "id": "b348d5b7bf5f4c5cb71d2e758363b779"
    },
    {
        "city": "Wolverhampton",
        "location": "Wolverhampton",
        "room_name": "Fiveways House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2024-01/iQ-Student-Accommodation-Wolverhampton-Fiveways-Bedrooms-Block-BDG-Gold_En_Suite_B3B%281%29%20%281%29_0.jpg?itok=dxxY2y_P",
        "room_link": "https://www.iqstudentaccommodation.com/wolverhampton/fiveways-house/gold-en-suite",
        "details": [
            "Private room",
            "Double bed",
            "Floors G-4",
            "15sqm living space",
            "From £104 / week"
        ],
        "id": "e1d7288697f445a7ba2bceffb961092a"
    },
    {
        "city": "Wolverhampton",
        "location": "Wolverhampton",
        "room_name": "Fiveways House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-08/iQ-Student-Accommodation-Wolverhampton-Fiveways-Bedrooms-Block-BDG-Platinum_En_Suite_G01A%283%29_0.jpg?itok=q7oQ5yGa",
        "room_link": "https://www.iqstudentaccommodation.com/wolverhampton/fiveways-house/platinum-en-suite",
        "details": [
            "Private room",
            "Small double bed",
            "13sqm living space",
            "13sqm living space",
            "From £108 / week"
        ],
        "id": "10ef1bee0fa247b8ac7b7042e6305d72"
    },
    {
        "city": "Wolverhampton",
        "location": "Wolverhampton",
        "room_name": "Fiveways House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2018-11/Diamond%20-%20Fiveways_0.jpg?itok=TZ_93y2D",
        "room_link": "https://www.iqstudentaccommodation.com/wolverhampton/fiveways-house/diamond-en-suite",
        "details": [
            "Private room",
            "Double bed",
            "15 m²",
            "Shower",
            "From £113 / week"
        ],
        "id": "8907cf93bbfe4ceab0293ada386065a4"
    },
    {
        "city": "Wolverhampton",
        "location": "Wolverhampton",
        "room_name": "Fiveways House",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2021-08/iQ-Student-Accommodation-Wolverhampton-Fiveways-Bedrooms-Block-BDG-Two_Bed_Apartment%287%29.jpg?itok=emME6dLh",
        "room_link": "https://www.iqstudentaccommodation.com/wolverhampton/fiveways-house/two-bed-apartment",
        "details": [
            "Private apartment",
            "Small double bed",
            "11-13 m²",
            "Microwave",
            "From £153 / week"
        ],
        "id": "de8e5dac7da6452d92e17e5fe01ca474"
    },
    {
        "city": "York",
        "location": "York",
        "room_name": "The Brickworks",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2020-12/iQ-Student-Accommodation-York-The-Brickworks-Bedrooms-Gold_Studio%283%29.jpg?itok=RIX0W-CD",
        "room_link": "https://www.iqstudentaccommodation.com/york/the-brickworks/gold-studio",
        "details": [
            "Limited availability",
            "Private studio",
            "Double bed",
            "21-25 m²",
            "Dual occupancy available",
            "From £307 / week"
        ],
        "id": "17098979cd684a5a965ef8d282ead33f"
    },
    {
        "city": "York",
        "location": "York",
        "room_name": "The Brickworks",
        "image_url": "https://www.iqstudentaccommodation.com/sites/default/files/styles/default/public/2020-12/iQ-Student-Accommodation-York-The-Brickworks-Bedrooms-Platinum_Studio_42%288%29_0.jpg?itok=GqPjKeVc",
        "room_link": "https://www.iqstudentaccommodation.com/york/the-brickworks/platinum-studio",
        "details": [
            "Private studio",
            "Double bed",
            "25-29 m²",
            "Dual occupancy available",
            "From £345 / week"
        ],
        "id": "647477258ced4cc4a7e9dacc8aea448d"
    }
]