// src/components/RelivAnalyticsDashboard.js
import React, { useRef } from 'react';
import { motion } from 'framer-motion';
import { 
  IoEyeSharp, 
  IoHeart, 
  IoChatboxEllipses, 
  IoChevronBack, 
  IoChevronForward 
} from 'react-icons/io5';
import angelDen from '../../assets/vid/AngelsDen.mp4';
import Video1 from '../../assets/img/video1.mp4';
import Video2 from '../../assets/img/video2.mp4';
import Video3 from '../../assets/img/video3.mp4';
import Video4 from '../../assets/img/video4.mp4';
import { Line, Bar, Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

// Register Chart.js modules
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const RelivAnalyticsDashboard = () => {
  const videos = [
    { title: 'Asking Students about their experience', user: 'WorldLynk', views: 12000, likes: 1200, comments: 450, src: Video1 },
    { title: 'Students about the problems they face in UK', user: 'WorldLynk', views: 8900, likes: 980, comments: 320, src: Video2 },
    { title: 'WorldLynk is an Experience!', user: 'WorldLynk', views: 5600, likes: 470, comments: 150, src: Video3 },
    { title: 'What is WorldLynk?', user: 'WorldLynk', views: 11000, likes: 1300, comments: 480, src: Video4 },
    { title: 'Angels Den', user: 'AngelsDen', views: 3036, likes: 670, comments: 210, src: angelDen },
  ];

  const universities = [
    { name: 'Royal Holloway University', views: 500 },
    { name: 'Middlesex University', views: 800 },
    { name: 'London School of Economics', views: 1200 },
    { name: 'Warwick University', views: 700 },
    { name: 'London University', views: 900 },
  ];

  // Calculate stats
  const totalViews = videos.reduce((acc, video) => acc + video.views, 0);
  const totalLikes = videos.reduce((acc, video) => acc + video.likes, 0);
  const totalComments = videos.reduce((acc, video) => acc + video.comments, 0);
  const totalEngagement = totalLikes + totalComments;
  const impressions = Math.round(totalViews * 1.5);
  const engagementRate = ((totalEngagement / totalViews) * 100).toFixed(1);
  const profileViews = Math.round(totalViews * 0.2);

  const stats = [
    { title: 'Total Reach', value: totalViews, icon: <IoEyeSharp />, delta: '+15%', color: 'text-green-400' },
    { title: 'Impressions', value: impressions, icon: <IoHeart />, delta: '+8%', color: 'text-green-400' },
    { title: 'Engagement Rate', value: `${engagementRate}%`, icon: <IoChatboxEllipses />, delta: '-3%', color: 'text-red-400' },
    { title: 'Profile Views', value: profileViews, icon: <IoEyeSharp />, delta: '+22%', color: 'text-green-400' },
  ];

  const scrollRef = useRef(null);

  const scrollLeft = () => scrollRef.current.scrollBy({ left: -300, behavior: 'smooth' });
  const scrollRight = () => scrollRef.current.scrollBy({ left: 300, behavior: 'smooth' });

  // University Views Data (Pie Chart)
  const pieData = {
    labels: universities.map((uni) => uni.name),
    datasets: [
      {
        label: 'Views by University',
        data: universities.map((uni) => uni.views),
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'],
      },
    ],
  };

  // Interested vs Not Interested Data (Bar Chart)
  const interestData = {
    labels: ['Interested', 'Not Interested'],
    datasets: [
      {
        label: 'Users Interest',
        data: [20, 80], // Example: 80% interested, 20% not interested
        backgroundColor: ['#4CAF50', '#F44336'],
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: { display: true, position: 'top', labels: { color: '#fff' } },
    },
    scales: {
      x: { ticks: { color: '#a3a3a3' }, grid: { color: 'rgba(255, 255, 255, 0.1)' } },
      y: { ticks: { color: '#a3a3a3' }, grid: { color: 'rgba(255, 255, 255, 0.1)' } },
    },
  };

  return (
    <div className="dark:bg-gray-900 min-h-screen p-6">
      <h1 className="text-3xl font-bold text-white mb-6">Analytics Dashboard</h1>

      {/* Stats Section */}
      <motion.div
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="grid grid-cols-1 md:grid-cols-4 gap-6"
      >
        {stats.map((stat, index) => (
          <div key={index} className="bg-gray-800 p-6 rounded-xl shadow-lg flex flex-col items-center">
            <div className="text-5xl text-white mb-2">{stat.icon}</div>
            <h2 className="text-lg font-semibold text-gray-300">{stat.title}</h2>
            <p className="text-3xl font-bold text-white mt-2">{stat.value.toLocaleString()}</p>
            <p className={`mt-1 ${stat.color} font-medium`}>{stat.delta}</p>
          </div>
        ))}
      </motion.div>
    {/* Video Performance Section */}
    <div className="mt-10 relative">
        <div className="flex items-center justify-between">
          <h2 className="text-2xl font-semibold text-white mb-4">Top Videos</h2>
          <div className="flex space-x-2">
            <button onClick={scrollLeft} className="p-2 rounded-full bg-gray-700 hover:bg-gray-600 text-white">
              <IoChevronBack size={24} />
            </button>
            <button onClick={scrollRight} className="p-2 rounded-full bg-gray-700 hover:bg-gray-600 text-white">
              <IoChevronForward size={24} />
            </button>
          </div>
        </div>

        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.2, duration: 0.5 }} ref={scrollRef} className="grid grid-flow-col auto-cols-[300px] gap-6 overflow-x-auto scrollbar-hide">
          {videos.map((video, index) => (
            <div key={index} className="bg-gray-800 p-4 rounded-xl shadow-lg flex flex-col">
              <div className="flex-grow">
                <h3 className="text-lg font-semibold text-gray-300">{video.title}</h3>
                <p className="text-white mt-2">Views: {video.views.toLocaleString()}</p>
                {/* <p className="text-white mt-1">Likes: {video.likes.toLocaleString()}</p>
                <p className="text-white mt-1">Comments: {video.comments.toLocaleString()}</p> */}
              </div>
              <div className="mt-4 w-full h-64 rounded-md overflow-hidden shadow-lg">
                <video src={video.src} controls className="w-full h-full object-cover" />
              </div>
            </div>
          ))}
        </motion.div>
      </div>
      {/* Engagement Graphs */}
      <div className="mt-10 flex justify-between">
        <div className="w-1/2 mr-4">
          <h2 className="text-2xl font-semibold text-white mb-4">Views by University</h2>
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.4, duration: 0.5 }} className="bg-gray-800 p-6 rounded-xl shadow-lg">
            <Pie data={pieData} options={chartOptions} />
          </motion.div>
        </div>

        <div className="w-1/2 ml-4">
          <h2 className="text-2xl font-semibold text-white mb-4">User Interest Overview</h2>
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.4, duration: 0.5 }} className="bg-gray-800 p-6 rounded-xl shadow-lg">
            <Bar data={interestData} options={chartOptions} />
            <p className="text-white mt-4">Most common reason for lack of interest: <span className="font-bold">Price</span></p>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default RelivAnalyticsDashboard;
