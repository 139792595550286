import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs } from "firebase/firestore";
import { firestore } from "../../../firebase";
import { motion } from 'framer-motion';
import Slider1 from './Slider';
import eventbgimg from '../../../assets/img/events_cover.jpg';
import academicEvents from './img/academic-events.jpg';
import cultural from './img/cultural-events.jpg';
import social from './img/social-events.jpg';
import sports from './img/sports-events.jpg';
import professionalDev from './img/professional-development.jpg';
import healthAndWellness from './img/health-and-wellness.jpg';
import orientation from './img/orientation.png';
import art from './img/art.jpg';
import tech from './img/tech.jpg';

const categories = [
  { name: "Academic", imageUrl: academicEvents },
  { name: "Cultural", imageUrl: cultural },
  { name: "Social", imageUrl: social },
  { name: "Sports", imageUrl: sports },
  { name: "Professional", imageUrl: professionalDev },
  { name: "Health", imageUrl: healthAndWellness },
  { name: "Orientation", imageUrl: orientation },
  { name: "Arts", imageUrl: art },
  { name: "Tech", imageUrl: tech }
];

const EventsPage = () => {
  const navigate = useNavigate();
  const [eventData, setEventData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const eventsCollectionRef = collection(firestore, "events");
        const eventsQuerySnapshot = await getDocs(eventsCollectionRef);
        if (!eventsQuerySnapshot.empty) {
          const data = eventsQuerySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          setEventData(data);
        } else {
          console.log("No events found");
        }
      } catch (error) {
        console.error("Error fetching events data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchEventData();
  }, []);

  const handleCategoryClick = (categoryName) => {
    navigate(`all-events/${categoryName}`);
  };

  const handleBannerClick = () => {
    navigate('all-events');
  };

  return (
    <div className="bg-gray-900 text-white min-h-screen">
      {/* Banner Section */}
      {/* <div 
        className="relative bg-cover bg-center text-white py-16" 
        style={{ backgroundImage: `url(${eventbgimg})` }}
      >
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="container mx-auto text-center relative z-5">
          <h1 className="text-4xl md:text-6xl font-bold mb-4 leading-tight">Discover Your Next Adventure</h1>
          <p className="text-lg md:text-xl mb-6 max-w-2xl mx-auto">Explore a world of opportunities and experiences waiting for you.</p>
          <button 
            onClick={handleBannerClick} 
            className="bg-white text-gray-900 px-6 py-3 rounded-full text-base font-semibold hover:bg-gray-200 transition duration-300"
          >
            Find an Event
          </button>
        </div>
      </div> */}
      <div className="relative bg-gray-800 py-20 bg-cover bg-center" style={{ backgroundImage: `url(${eventbgimg})` }}>
        <div className="relative z-10 container mx-auto px-4">
          <h1 className="text-4xl sm:text-5xl font-bold text-white mb-4">Discover Your Next Adventure</h1>
          <p className="text-xl text-gray-300">Explore a world of opportunities and experiences waiting for you.</p>
          <button 
            onClick={handleBannerClick} 
            className="bg-white text-gray-900 px-6 py-3 rounded-full text-base font-semibold hover:bg-gray-200 transition duration-300"
          >
            Find an Event
          </button>
        </div>
        
        <div className="absolute inset-0 bg-black opacity-30"></div>
      </div>
      {/* Popular Categories Section */}
      <div className="container mx-auto py-8">
        <h2 className="text-3xl font-bold mb-8 text-center">Popular Categories</h2>
        <div className="flex justify-center space-x-4 overflow-x-auto pb-4">
          {categories.map((category, index) => (
            <motion.div
              key={index}
              className="flex flex-col items-center cursor-pointer"
              onClick={() => handleCategoryClick(category.name)}
            >
              <div className="w-16 h-16 md:w-24 md:h-24 lg:w-28 lg:h-28 rounded-full overflow-hidden border-2 border-white mb-2">
                <motion.img 
                  src={category.imageUrl} 
                  alt={category.name} 
                  className="w-full h-full object-cover"
                  whileHover={{ scale: 1.1 }}
                  transition={{ duration: 0.3 }}
                />
              </div>
              <p className="text-xs md:text-sm lg:text-base font-medium">{category.name}</p>
            </motion.div>
          ))}
        </div>
      </div>

      {/* Upcoming Events Section */}
      <div className="container mx-auto py-2">
        <h2 className="text-3xl font-bold mb-8 text-center">Upcoming Events</h2>
        {loading ? (
          <div className="flex justify-center">
            <div className="spinner-border animate-spin inline-block w-10 h-10 border-4 rounded-full border-t-transparent"></div>
          </div>
        ) : (
          <Slider1 events={eventData} />
        )}
      </div>
    </div>
  );
};

export default EventsPage;
