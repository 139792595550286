import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FaArrowLeft, FaSpinner } from 'react-icons/fa';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { firestore } from '../../firebase';
import { collection, doc, getDoc } from 'firebase/firestore';
const RelivAndBooking = () => {
  const [experienceData, setExperienceData] = useState(null);
  const [postsData, setPostsData] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // Added state to track loading

  const { experienceId } = useParams();
  useEffect(() => {
    // Fetch the experience data and another data from 'reliv-posts' collection
    const fetchExperienceAndPostsData = async () => {
      setIsLoading(true); // Set loading to true before fetching data
      // Fetch experience data
      const experienceDocRef = doc(collection(firestore, 'reliv-experiences'), experienceId);
      const experienceDocSnap = await getDoc(experienceDocRef);
      if (experienceDocSnap.exists()) {
        setExperienceData(experienceDocSnap.data());
      } else {
        console.error("No such experience found!");
      }

      // Fetch posts data
      const postsDocRef = doc(collection(firestore, 'reliv-posts'), experienceId);
      const postsDocSnap = await getDoc(postsDocRef);
      if (postsDocSnap.exists()) {
        // Assuming there's a state to store posts data
        setPostsData(postsDocSnap.data());
      } else {
        console.error("No such posts found!");
      }
      setIsLoading(false); // Set loading to false after fetching data
    };

    fetchExperienceAndPostsData();

    // Toast notification for under development feature
    toast.info('This feature is still under development.', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        background: '#333',
        color: '#fff',
      },
    });
  }, []);
  console.log(experienceData)
  if (!experienceData || isLoading) { // Check if data is not loaded or if it's still loading
    return (
      <div className="flex justify-center items-center h-screen">
        <FaSpinner className="animate-spin text-5xl text-white" />
      </div>
    ); // Display loader
  }

  const { experiences, name, notes, picture, link, time, type } = experienceData;
  console.log(postsData);
  return (
    <div className="min-h-screen bg-gray-900 text-white flex flex-col items-center py-8 px-4 relative">
      <button
        onClick={() => window.history.back()}
        className="absolute top-4 left-4 flex items-center space-x-2 text-white text-lg font-semibold"
      >
        <FaArrowLeft />
      </button>

      {/* Header */}
      <h1 className="text-4xl font-extrabold mb-8">{postsData?.title || 'N/A'}</h1>

      {/* Experience Section */}
      <div className="mb-16 max-w-4xl md:max-w-4xl">
        <h2 className="text-2xl font-semibold mb-4">Experience </h2>
        <p className="leading-7 text-base mb-4 md:text-base md:leading-8">
          {postsData?.description || 'N/A'}
        </p>


      </div>

      {/* Itinerary Section */}
      <div className="mb-16 max-w-4xl md:max-w-4xl">
        <h2 className="text-2xl font-semibold mb-6 uppercase">Itinerary</h2>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 items-start">
          {/* Left Column: Time and Details */}
          <div className="col-span-2 space-y-10">
            {experiences && experiences.map((item, index) => (
              <div key={index} className="flex items-start space-x-6">
                {/* Time Selection */}
                <div className="flex flex-col items-start">
                  <label className="uppercase text-sm font-semibold mb-2">
                    Time
                  </label>
                  <select
                    className="bg-gray-900 text-white font-semibold rounded px-4 py-2 focus:outline-none border border-orange-500"
                    value={item.time || time}
                  >
                    <option value={item.time}>{item.time}</option>
                  </select>
                </div>

                {/* Dotted Line */}
                <div className="flex flex-col items-center">
                  {index < experiences.length - 1 && (
                    <div className="h-16 border-l-2 border-dashed border-gray-500"></div>
                  )}
                </div>

                {/* Details */}
                <div className="flex-1 space-y-2">
                  <h3 className="text-xl font-semibold">{item.name}</h3>
                  <p className="text-base text-gray-400">{item.type}</p>
                  {item.notes && (
                    <div className="mt-2">
                      <h4 className="text-base font-semibold text-gray-400">Notes:</h4>
                      <ul className="list-disc list-inside mt-1 space-y-1">
                        {item.notes.map((note, index) => (
                          <li key={index} className="text-sm text-gray-300">{note}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>

          {/* Right Column: Clickable Images */}
          <div className="space-y-12">
            {experiences && experiences.map((item, index) => (
              <div key={index} className="relative group cursor-pointer">
                <img
                  src={item.picture}
                  alt={item.name}
                  className="rounded-lg shadow-lg w-full h-auto object-cover aspect-video"
                />
                <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                  <span className="text-white font-semibold">CLICK TO VIEW</span>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Book This Button */}
        <div className="text-center mt-8">
          <button className="bg-orange-500 text-black font-bold py-3 px-8 rounded-lg hover:bg-orange-600 transition-all">
            BOOK THIS
          </button>
        </div>
      </div>

      {/* Recommendations Section */}
      {/* Assuming recommendations are part of the experience schema */}
      <div className="mb-16 max-w-4xl md:max-w-4xl">
        <h2 className="text-2xl font-semibold mb-6">Recommendations</h2>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {experiences && experiences.map((rec, index) => (
            <div key={index} className="relative group cursor-pointer">
              <a href={rec.link} target="_blank" rel="noopener noreferrer">
                <img
                  src={rec.picture}
                  alt={rec.name}
                  className="rounded-lg shadow-lg w-48 h-48 object-cover"
                />
                <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                  <span className="text-white font-semibold">{rec.name}</span>
                </div>
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RelivAndBooking;
