import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth, firestore } from "../../../firebase";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../../../auth/userProvider/AuthProvider";
import gsap from "gsap";
import lottie from "lottie-web";
import animationData from "../../../assets/lotties/students.json";
import googleSvg from "../../../assets/img/googleSvg.svg";
import linkedin from "../../../assets/img/linkedin.svg";
import meta from "../../../assets/img/meta.svg";
import { GoogleAuthProvider } from "firebase/auth";
import { signInWithEmailAndPassword, signInWithPopup, createUserWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { getDoc, doc, setDoc } from "firebase/firestore";
import { signOut } from "firebase/auth";
import { serverTimestamp } from "firebase/firestore";

const LoginPage = () => {
  const { getUserRole, userRole } = useAuth();
  const navigate = useNavigate();
  const container = useRef(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showSignUpForm, setShowSignUpForm] = useState(false);
  const [showForgotPasswordForm, setShowForgotPasswordForm] = useState(false);
  const [formData, setFormData] = useState({
    name:"",
    email: "",
    password: ""
  });
  const handleSignUp = async () => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, formData.email, formData.password);
      const user = userCredential.user;

      await setDoc(doc(firestore, "users", user.uid), {
        userId: user.uid,
        name: formData.name,
        // lastName: formData.lastName,
        email: formData.email,
        role: "student",
        createdAt:serverTimestamp(), // Added createdAt field
      });

      toast.success("Signup successful!", { position: "top-center", autoClose: 3000, theme: "dark" });

      // Wait for AuthProvider to update currentUser and userRole
      let attempts = 0;
      const maxAttempts = 10;
      const delay = 300; // milliseconds

      while (attempts < maxAttempts) {
        const userRole = await getUserRole(user.uid);
        if (userRole) {
          navigate("/user-dashboard/events");
          return;
        }
        attempts++;
        await new Promise((resolve) => setTimeout(resolve, delay));
      }

      toast.error("Failed to retrieve user role. Please try again.", { position: "top-center", autoClose: 5000, theme: "dark" });
    } catch (error) {
      console.error("Signup Error:", error);
      let errorMessage = "Signup failed. Please try again.";

      switch (error.code) {
        case "auth/email-already-in-use":
          errorMessage = "Email is already in use. Please use a different email.";
          break;
        case "auth/invalid-email":
          errorMessage = "Invalid email address. Please enter a valid email.";
          break;
        case "auth/weak-password":
          errorMessage = "Password is too weak. Please enter a stronger password.";
          break;
        case "auth/operation-not-allowed":
          errorMessage = "Operation not allowed. Please contact support.";
          break;
        default:
          errorMessage = `Signup failed: ${error.message}`;
          break;
      }

      toast.error(errorMessage, { position: "top-center", autoClose: 5000, theme: "dark" });
    }
  };
  useEffect(() => {
    if (userRole) {
      if (userRole === "admin") {
        navigate("/business/overview");
      } else if (userRole === "student") {
        navigate("/user-dashboard/events");
      }
    }
  }, [userRole, navigate]);

  useEffect(() => {
    const animation = lottie.loadAnimation({
      container: container.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData,
    });

    gsap.fromTo(
      container.current,
      { opacity: 0, scale: 0.5 },
      { opacity: 1, scale: 1, duration: 1, ease: "power3.out", delay: 0.5 }
    );

    return () => {
      animation.destroy();
    };
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const toggleSignUpForm = () => {
    setShowSignUpForm(!showSignUpForm);
    setShowForgotPasswordForm(false);
  };

  const toggleForgotPasswordForm = () => {
    setShowForgotPasswordForm(!showForgotPasswordForm);
    setShowSignUpForm(false);
  };

  const handleLogin = async () => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user.uid;
      const role = await getUserRole(user);

      if (role === "admin") {
        navigate("/business/overview");
      } else if (role === "student") {
        navigate("/user-dashboard/events");
      } else {
        await signOut(auth);
        throw new Error("Access denied: User is not a student or admin");
      }

      toast.success("Login successful!", {
        position: "top-right",
        autoClose: 1200,
        theme: "dark"
      });
    } catch (error) {
      console.error("Login Error:", error);
      toast.error("Invalid credentials. Please try again.", {
        position: "top-center",
        autoClose: 1500,
        theme: "dark"
      });
    }
  };

  const handleGoogleLogin = async () => {
    try {
        const result = await signInWithPopup(auth, new GoogleAuthProvider());
        const user = result.user;
        const userDocRef = doc(firestore, "users", user.uid);

        // Check if the user document exists
        let userDoc = await getDoc(userDocRef);

        if (!userDoc.exists()) {
            // If the user document doesn't exist, create a new one with a 'student' role.
            await setDoc(userDocRef, {
                userId: user.uid,
                role: "student",
                email: user.email,
                name: user.displayName,
                createdAt: serverTimestamp(),
            });

            // Fetch the document again after creating it
            userDoc = await getDoc(userDocRef);
            toast.success("Registration successful!", { theme: "dark" });
        }

        // Now, fetch the role from the user document
        const userData = userDoc.data();
        const role = userData.role;

        // Ensure the role is set correctly before navigation
        if (role === "admin") {
            navigate("/business/overview");
        } else if (role === "student") {
            navigate("/user-dashboard/events");
        } else {
            throw new Error("Unexpected role. Access denied.");
        }

        toast.success("Login with Google successful!", { theme: "dark" });
    } catch (error) {
        console.error("Google Login Error:", error);
        toast.error(`Google Login Error: ${error.message}`, { theme: "dark" });
    }
};

 

  const handleForgotPassword = async () => {
    try {
      await sendPasswordResetEmail(auth, email);
      toast.success("Password reset email sent. Please check your inbox.", {
        position: "top-center",
        autoClose: 5000,
        theme: "dark"
      });
      setShowForgotPasswordForm(false);
    } catch (error) {
      console.error("Forgot Password Error:", error);
      toast.error("Failed to send password reset email. Please try again.", {
        position: "top-center",
        autoClose: 5000,
        theme: "dark"
      });
    }
  };

  return (
    <div className="min-h-screen bg-white">
      <div className="flex flex-col md:flex-row h-screen">
        {/* Lottie container - hidden on mobile */}
        <div className="hidden md:flex md:w-1/2 justify-center items-center bg-white">
          <div ref={container} className="min-h-[300px] min-w-[300px] opacity-100"></div>
        </div>
        
        {/* Form container - full width on mobile */}
        <div className="w-full md:w-1/2 flex justify-center items-center bg-black text-white min-h-screen md:min-h-0">
          <div className="w-11/12 max-w-[360px] border-2 border-white p-8 rounded-lg my-8 md:my-0">
            {showSignUpForm ? (
              <form>
                <h1 className="text-2xl font-bold mb-6">SignUp</h1>
                <div className="mb-4">
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Full Name"
                    className="w-full p-2 border border-gray-300 rounded text-black"
                  />
                </div>
                {/* <div className="mb-4">
                  <input
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    placeholder="Last Name"
                    className="w-full p-2 border border-gray-300 rounded text-black"
                  />
                </div> */}
                <div className="mb-4">
                  <input
                    type="text"
                    name="email"
                    id="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="E-mail Address"
                    autoComplete="username"
                    className="w-full p-2 border border-gray-300 rounded text-black"
                  />
                </div>
                <div className="mb-4">
                  <input
                    type="password"
                    name="password"
                    id="password"
                    value={formData.password}
                    onChange={handleChange}
                    placeholder="Password"
                    autoComplete="current-password"
                    className="w-full p-2 border border-gray-300 rounded text-black"
                  />
                </div>
                <div className="mt-6">
                  <button
                    type="button"
                    className="w-full p-2 bg-green-600 text-white rounded hover:bg-green-700"
                    onClick={handleSignUp}
                  >
                    Register
                  </button>
                </div>
                <div className="mt-4 text-center">
                  <p>
                    Already a member?{" "}
                    <span className="text-blue-600 cursor-pointer" onClick={toggleSignUpForm}>Login now</span>
                  </p>
                </div>
                <div className="mt-6 text-center">
                  <p className="mb-2">Or login with</p>
                  <div className="flex justify-center space-x-4">
                    <div className="w-8 h-8 rounded-full overflow-hidden shadow-md cursor-pointer" onClick={handleGoogleLogin}>
                      <img src={googleSvg} alt="Google" className="w-full h-full" />
                    </div>
                    <div className="w-8 h-8 rounded-full overflow-hidden shadow-md cursor-pointer" onClick={() => toast.info("LinkedIn login is not available right now. Please use another method.", { position: "top-center", autoClose: 3000, theme: "dark" })}>
                      <img src={linkedin} alt="LinkedIn" className="w-full h-full" />
                    </div>
                    <div className="w-8 h-8 rounded-full overflow-hidden shadow-md cursor-pointer" onClick={() => toast.info("Meta login is not available right now. Please use another method.", { position: "top-center", autoClose: 3000, theme: "dark" })}>
                      <img src={meta} alt="Meta" className="w-full h-full" />
                    </div>
                  </div>
                </div>
              </form>
            ) : showForgotPasswordForm ? (
              <form>
                <h1 className="text-2xl font-bold mb-6">Forgot Password</h1>
                <div className="mb-4">
                  <input
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="E-mail Address"
                    className="w-full p-2 border border-gray-300 rounded text-black"
                    autoComplete="username"
                  />
                </div>
                <div className="mt-6">
                  <button
                    type="button"
                    className="w-full p-2 bg-green-600 text-white rounded hover:bg-green-700"
                    onClick={handleForgotPassword}
                  >
                    Reset Password
                  </button>
                </div>
                <div className="mt-4 text-center">
                  <p>
                    Remember your password?{" "}
                    <span className="text-blue-600 cursor-pointer" onClick={toggleForgotPasswordForm}>Login now</span>
                  </p>
                </div>
              </form>
            ) : (
              <form>
                <h1 className="text-2xl font-bold mb-6">Login</h1>
                <div className="mb-4">
                  <input
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="E-mail Address"
                    className="w-full p-2 border border-gray-300 rounded text-black"
                    autoComplete="username"
                  />
                </div>
                <div className="mb-4">
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                    className="w-full p-2 border border-gray-300 rounded text-black"
                    autoComplete="current-password"
                  />
                </div>
                <div className="mt-6">
                  <button
                    type="button"
                    className="w-full p-2 bg-green-600 text-white rounded hover:bg-green-700"
                    onClick={handleLogin}
                  >
                    Login
                  </button>
                </div>
                <div className="mt-4 text-center">
                  <p>
                    Not a member?{" "}
                    <span className="text-blue-600 cursor-pointer" onClick={toggleSignUpForm}>Sign up now</span>
                  </p>
                </div>
                <div className="mt-6 text-center">
                  <p className="mb-2">Or login with</p>
                  <div className="flex justify-center space-x-4">
                    <div className="w-8 h-8 rounded-full overflow-hidden shadow-md cursor-pointer" onClick={handleGoogleLogin}>
                      <img src={googleSvg} alt="Google" className="w-full h-full" />
                    </div>
                    <div className="w-8 h-8 rounded-full overflow-hidden shadow-md cursor-pointer" onClick={() => toast.info("LinkedIn login is not available right now. Please use another method.", { position: "top-center", autoClose: 3000, theme: "dark" })}>
                      <img src={linkedin} alt="LinkedIn" className="w-full h-full" />
                    </div>
                    <div className="w-8 h-8 rounded-full overflow-hidden shadow-md cursor-pointer" onClick={() => toast.info("Meta login is not available right now. Please use another method.", { position: "top-center", autoClose: 3000, theme: "dark" })}>
                      <img src={meta} alt="Meta" className="w-full h-full" />
                    </div>
                  </div>
                </div>
                <div className="mt-4 text-center">
                  <span className="text-red-600 cursor-pointer" onClick={toggleForgotPasswordForm}>Forgot Password?</span>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default LoginPage;
