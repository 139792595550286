import React, { useRef, useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import { IoChevronBack, IoChevronForward } from 'react-icons/io5';
import { useAuth } from '../../auth/userProvider/AuthProvider';
import { firestore, storage } from '../../firebase';
import { scheduleData, friendsImages } from './utils';
import EditProfileModal from './EditProfileModal';
import { collection, query, where, orderBy, getDocs } from 'firebase/firestore';

const Profile = () => {
  const [selectedDate, setSelectedDate] = useState('');
  const [videos, setVideos] = useState([]);

  const [dates, setDates] = useState([]);
  const videoRefs = useRef([]);
  const [startDate, setStartDate] = useState(new Date());
  const { userData, setUserData } = useAuth();
  console.log(userData)
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPosts = async () => {
      if (!userData || !userData.userId) {
        console.log('userData or userId is missing:', userData);
        return;
      }
  
      console.log('Fetching posts for userId:', userData.userId);
      const postsRef = collection(firestore, 'reliv-posts');
      const q = query(
        postsRef,
        where('userId', '==', userData.userId),
        orderBy('createdAt', 'desc')
      );
  
      const querySnapshot = await getDocs(q);
      const posts = [];
      querySnapshot.forEach((doc) => {
        posts.push({ ...doc.data(), src: doc.data().videoUrl });
      });
      console.log('Fetched posts:', posts);
      setVideos(posts);
    };
  
    fetchPosts();
  }, [userData]);
  


  useEffect(() => {
    generateDates(startDate);
  }, [startDate]);

  // Function to generate week dates
  const generateDates = (start) => {
    const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const tempDates = [];

    for (let i = 0; i < 7; i++) {
      const nextDate = new Date(start.getFullYear(), start.getMonth(), start.getDate() + i);
      const day = weekDays[nextDate.getDay()];
      const date = nextDate.getDate();
      const fullDate = `${day} ${date < 10 ? '0' : ''}${date}`;
      tempDates.push(fullDate);
    }

    setDates(tempDates);
    setSelectedDate(tempDates[0]); // Set first date as default selected date
  };

  const getMonthAndYear = (date) => {
    const options = { month: 'long', year: 'numeric' };
    return new Intl.DateTimeFormat('en-US', options).format(date); // E.g., 'October 2023'
  };

  const scrollRef = useRef(null);

  const handleMouseEnter = async (index) => {
    const videoElement = videoRefs.current[index];
    if (videoElement) {
      try {
        await videoElement.play();
      } catch (error) {
        console.log('Video play was blocked due to autoplay policy:', error);
      }
    }
  };

  const handleMouseLeave = (index) => {
    const videoElement = videoRefs.current[index];
    if (videoElement) {
      videoElement.pause();
    }
  };

  const scrollLeft = () => scrollRef.current.scrollBy({ left: -300, behavior: 'smooth' });
  const scrollRight = () => scrollRef.current.scrollBy({ left: 300, behavior: 'smooth' });

  return (
    <div className="min-h-screen bg-gray-900 text-white flex flex-col items-center relative">
      {/* Modal for Editing Profile */}

      {/* Page content */}
      <div className="w-full">
        <button
          onClick={() => window.history.back()}
          className="absolute top-4 left-4 flex items-center space-x-2 text-white text-lg font-semibold"
        >
          <FaArrowLeft />
        </button>

        <div className="max-w-5xl w-full mx-auto px-4 sm:px-6">
          {/* Page Heading */}
          <h1 className="text-3xl md:text-4xl font-bold tracking-wider mb-6 md:mb-8 text-center md:text-left">Your World</h1>

          {/* Info and Profile Picture */}
          <div className="text-white max-w-4xl mx-auto mb-12">
            <div className="flex flex-col md:flex-row items-center md:items-start justify-between mb-10">

              {/* Profile Image Section */}
              <div className="md:w-1/3 flex flex-col items-center md:items-start justify-center md:justify-start mb-6 md:mb-0">
                <div className="relative w-32 h-32 md:w-48 md:h-48 mb-2">
                  <img
                    src={userData?.profileImage || 'https://via.placeholder.com/150'}
                    alt="Profile"
                    className="w-full h-full rounded-full object-cover shadow-md border-4 border-gray-700"
                  />
                  <button
                    onClick={() => navigate('/user-dashboard/profile')}
                    className="absolute bottom-2 right-2 bg-purple-600 hover:bg-purple-700 text-sm px-4 py-1 rounded-full text-white font-semibold transition-transform transform hover:scale-105"
                  >
                    Edit
                  </button>
                </div>
                {/* Username centered */}

              </div>



              {/* User Info Section */}
              <div className="md:w-2/3 w-full bg-gray-800 p-4 rounded-lg shadow-lg">
                <h2 className="text-2xl font-bold text-center md:text-left mb-4 text-gradient bg-gradient-to-r from-purple-400 to-pink-500 bg-clip-text text-transparent">
                  User Information
                </h2>
                <div className="space-y-3">
                  <div className="flex justify-between border-b border-gray-700 py-1">
                    <span className="text-md font-semibold text-gray-300">Name</span>
                    <span className="text-md font-normal text-gray-400">{userData?.name || 'N/A'}</span>
                  </div>
                  <div className="flex justify-between border-b border-gray-700 py-1">
                    <span className="text-md font-semibold text-gray-300">Email</span>
                    <span className="text-md font-normal text-gray-400">{userData?.email || 'N/A'}</span>
                  </div>
                  <div className="flex justify-between border-b border-gray-700 py-1">
                    <span className="text-md font-semibold text-gray-300">Phone</span>
                    <span className="text-md font-normal text-gray-400">{userData?.phone || 'N/A'}</span>
                  </div>
                  <div className="flex justify-between border-b border-gray-700 py-1">
                    <span className="text-md font-semibold text-gray-300">University</span>
                    <span className="text-md font-normal text-gray-400">{userData?.university || 'N/A'}</span>
                  </div>
                  <div className="flex justify-between border-b border-gray-700 py-1">
                    <span className="text-md font-semibold text-gray-300">Address</span>
                    <span className="text-md font-normal text-gray-400">{userData?.city || 'N/A'}, {userData?.country || 'N/A'}</span>
                  </div>

                </div>
              </div>
            </div>
          </div>


          <div className="mt-8 md:mt-12 w-full text-left">
            <h2 className="text-2xl font-bold mb-4">Friends</h2>

            {/* Horizontal Scrollable Friends */}
            <div className="flex space-x-4 overflow-x-auto pb-4 scrollbar-hide">
              {friendsImages.map((src, i) => (
                <img key={i} src={src} alt={`Friend ${i + 1}`} className="w-16 h-16 md:w-24 md:h-24 rounded-full object-cover flex-shrink-0" />
              ))}
              <div className="w-16 h-16 md:w-24 md:h-24 rounded-full bg-gray-700 flex items-center justify-center text-sm flex-shrink-0">
                +154
              </div>
            </div>
          </div>

          {/* Reliv Experiences */}
          <div className="mt-10 relative w-full">
      <div className="flex items-center justify-between">
        <h2 className="text-2xl font-semibold text-white mb-4">Reliv Experiences</h2>
        <div className="flex space-x-2">
          <button onClick={scrollLeft} className="p-2 rounded-full bg-gray-700 hover:bg-gray-600 text-white">
            <IoChevronBack size={24} />
          </button>
          <button onClick={scrollRight} className="p-2 rounded-full bg-gray-700 hover:bg-gray-600 text-white">
            <IoChevronForward size={24} />
          </button>
        </div>
      </div>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.2, duration: 0.5 }}
        ref={scrollRef}
        className="grid grid-flow-col auto-cols-[250px] md:auto-cols-[300px] gap-4 md:gap-6 overflow-x-auto scrollbar-hide w-full"
      >
        <div className="flex flex-col items-center w-full">
          {videos.length > 0 ? (
            videos.length === 1 ? (
              // Render a single video with adjusted styling
              <div className="w-full max-w-[300px] flex flex-col items-center">
                <div className="mt-4 w-full h-56 md:h-64 rounded-md overflow-hidden shadow-lg relative">
                  <video
                    ref={(el) => (videoRefs.current[0] = el)}
                    src={videos[0].src}
                    className="w-full h-full object-cover absolute top-0 left-0"
                    onMouseEnter={() => handleMouseEnter(0)}
                    onMouseLeave={() => handleMouseLeave(0)}
                    muted
                  />
                </div>
              </div>
            ) : (
              // Render multiple videos in grid format
              <div className="grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 w-full justify-items-center">
                {videos.map((video, index) => (
                  <div key={index} className="flex flex-col items-center">
                    <div className="mt-4 w-full h-56 md:h-64 rounded-md overflow-hidden shadow-lg relative">
                      <video
                        ref={(el) => (videoRefs.current[index] = el)}
                        src={video.src}
                        className="w-full h-full object-cover absolute top-0 left-0"
                        onMouseEnter={() => handleMouseEnter(index)}
                        onMouseLeave={() => handleMouseLeave(index)}
                        muted
                      />
                    </div>
                  </div>
                ))}
              </div>
            )
          ) : (
            // Fallback message when no videos are available
            <div className="text-center text-gray-400 mt-6">
              <p>No Reliv Experience yet.</p>
              <p>Start sharing your moments with the world!</p>
            </div>
          )}
        </div>
      </motion.div>
    </div>
  

          {/* Schedule Section */}
          <div className="mt-12 w-full">
            <h2 className="text-2xl font-bold mb-4">Schedule</h2>
            <div className="flex items-center space-x-4 overflow-x-auto pb-4">
              {/* Left Arrow */}
              <button onClick={() => setStartDate(new Date(startDate.setDate(startDate.getDate() - 7)))}>
                <IoChevronBack size={24} />
              </button>

              {/* Dates (Calendar Tabs) */}
              {dates.map((date, index) => (
                <div
                  key={index}
                  className={`text-center px-4 py-2 rounded-full cursor-pointer ${selectedDate === date ? 'bg-green-200 text-black' : 'text-gray-400'}`}
                  onClick={() => setSelectedDate(date)}
                >
                  <p>{date.split(' ')[0]}</p>
                  <p>{date.split(' ')[1]}</p>
                </div>
              ))}

              {/* Right Arrow */}
              <button onClick={() => setStartDate(new Date(startDate.setDate(startDate.getDate() + 7)))}>
                <IoChevronForward size={24} />
              </button>

              {/* Month and Year Display */}
              <div className="ml-auto text-gray-400 text-lg font-bold">
                {getMonthAndYear(startDate)}
              </div>
            </div>

            {/* Event Details */}
            <div className="mt-6 bg-gray-800 p-4 rounded-lg">
              <p className="text-lg font-bold">{scheduleData[selectedDate]?.title || 'No event'}</p>
              <p className="text-sm text-gray-400">{scheduleData[selectedDate]?.time}</p>

              {scheduleData[selectedDate] && (
                <>
                  <div className="flex items-center mt-4">
                    <img
                      src={scheduleData[selectedDate]?.imageUrl}
                      alt="Meeting"
                      className="w-12 h-12 rounded-full object-cover mr-4"
                    />
                    <div>
                      <p className="text-sm">Meeting with</p>
                      <p className="font-semibold">{scheduleData[selectedDate]?.meetingWith}</p>
                    </div>
                  </div>

                  <div className="flex space-x-4 mt-4">
                    <button className="bg-gray-700 text-white py-2 px-4 rounded-lg hover:bg-gray-600">Ignore</button>
                    <button className="bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-green-600">View</button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );



};


export default Profile;
