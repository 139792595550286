import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MapComponent from '../Maps/GoogleMaps';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

// Helper function to calculate distance using Haversine formula
const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const toRadians = (degree) => (degree * Math.PI) / 180;
    const R = 6371; // Radius of the Earth in km
    const dLat = toRadians(lat2 - lat1);
    const dLon = toRadians(lon2 - lon1);
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in km
};

const ExploreMain = () => {
    const navigate = useNavigate();
    const restaurants = useSelector((state) => state.restaurants.list); // Get restaurants from Redux
    const [nearbyRestaurants, setNearbyRestaurants] = useState([]);
    const [userLocation, setUserLocation] = useState(null);

    const cities = [
        { name: 'London', img: 'https://www.vietnamairlines.com/~/media/Images/Discovery/England/London/canh%20dep/986x906/London_canhdep_986x906.jpg' },
        { name: 'Manchester', img: 'https://www.travelandleisure.com/thmb/ARcRibKLvT9pZPXamzhYKm4f8Ao=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/TAL-manchester-england-MANCHESTER1222-99951de972f34549a1fcc5cf4a33a93e.jpg' },
        { name: 'Birmingham', img: 'https://www.parkregisbirmingham.co.uk/wp-content/uploads/2018/04/park-regis-historical-sites-birmingham.jpg' },
        // { name: 'Leeds', img: 'https://images.ctfassets.net/szez98lehkfm/5prAePQsrtpYKWmzToz5dh/af7415b7d439c0b58263e1d1840b2cce/MyIC_Article_93781' },
        // { name: 'Liverpool', img: 'https://i.natgeofe.com/n/b01c37b9-a201-484b-89ae-c922c61f417b/W6FKHM_16x9.jpg' }
    ];

    const foodVarieties = [
        { name: 'Pizza', img: 'https://cdn.pixabay.com/photo/2017/12/09/08/18/pizza-3007395_1280.jpg' },
        { name: 'Sushi', img: 'https://images.jdmagicbox.com/quickquotes/listicle/listicle_1696928641763_u06xl_4608x3072.jpg' },
        { name: 'Burger', img: 'https://cdn.pixabay.com/photo/2016/03/05/19/02/hamburger-1238246_1280.jpg' },
        { name: 'Pasta', img: 'https://media.licdn.com/dms/image/D5612AQHK3e4GbhqeXQ/article-cover_image-shrink_720_1280/0/1710999090391?e=2147483647&v=beta&t=IkHYotKPLOtuVaNDSdsWEZSyYPldYjbqH6tMkoEy85A' },
        { name: 'Tacos', img: 'https://www.thespruceeats.com/thmb/ereeBcFkDEbDT2VSlDe34sqXO_8=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/chicken-tinga-tinga-de-pollo-4773239-Hero_01-1bd1d960c02a4fdb812323b8c60fd55b.jpg' }
    ];

    const getSliderSettings = (itemCount) => ({
        dots: false,
        infinite: itemCount > 3,
        speed: 500,
        slidesToShow: Math.min(3, itemCount),
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: Math.min(2, itemCount),
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    });

    useEffect(() => {
        const storedLocation = JSON.parse(localStorage.getItem('userLocation'));
        // console.log("User Location from localStorage:", storedLocation);
        if (storedLocation) {
            // Map latitude and longitude to lat and lng for consistency
            setUserLocation({
                lat: storedLocation.latitude,
                lng: storedLocation.longitude
            });
        } else {
            console.log("No user location found in localStorage.");
        }
    }, []);
    
    useEffect(() => {
        // console.log("Current userLocation:", userLocation); 
        // console.log("Restaurants fetched from Redux:", restaurants); 
    
        if (userLocation && restaurants.length > 0) {
            const nearby = restaurants.map(restaurant => {
                const restaurantLat = restaurant.latitude;
                const restaurantLng = restaurant.longitude;
    
                // Log lat/lng to debug missing or invalid values
                // console.log(`Restaurant: ${restaurant.name}, Lat: ${restaurantLat}, Lng: ${restaurantLng}`);
    
                if (restaurantLat && restaurantLng) {
                    const distance = calculateDistance(
                        userLocation.lat,
                        userLocation.lng,
                        restaurantLat,
                        restaurantLng
                    );
                    // console.log(`Distance to ${restaurant.name}:`, distance);
                    // Return restaurant info along with calculated distance
                    return {
                        ...restaurant,
                        distance
                    };
                } else {
                    console.warn(`Missing latitude or longitude for restaurant: ${restaurant.name}`);
                    return null;
                }
            }).filter(restaurant => restaurant && restaurant.distance <= 15); // Only include restaurants within 10 km radius
            
            // console.log("Nearby Restaurants:", nearby);
            setNearbyRestaurants(nearby);
        }
    }, [userLocation, restaurants]);
    
    return (
        <div className="text-white container mx-auto px-2 py-1">
            {/* Map Section */}
            <div className="hero-section">
                <h1 className="text-2xl font-bold ">Explore Amazing Restaurants</h1>
                <MapComponent />
            </div>

            {/* Nearby Restaurants Section */}
            {userLocation && nearbyRestaurants.length > 0 && (
                <div className="nearby-restaurants-section py-8 px-8">
                    <h2 className="text-2xl font-bold text-center mb-4">Nearby Restaurants</h2>
                    <div className="flex justify-center">
                        <div className="w-full">
                            <Slider {...getSliderSettings(nearbyRestaurants.length, { preventScroll: true, dots: false })}>
                                {nearbyRestaurants.map((restaurant, index) => (
                                    <div key={index} className="px-2">
                                        <div className="bg-gray-800 text-white rounded-lg shadow-md p-4 cursor-pointer h-full" onClick={() => navigate(`/user-dashboard/restaurant/${restaurant.uuid}`)}>
                                            <img
                                                src={restaurant.images[0].url || 'default_image_url.jpg'}
                                                alt={restaurant.name}
                                                className="w-full h-32 object-cover rounded-md mb-2"
                                            />
                                            <h2 className="text-xl font-bold truncate max-w-full" title={restaurant.name}>{restaurant.name}</h2>
                                            <p className="text-gray-400">{restaurant.description}</p>
                                            <p className="text-gray-300">{restaurant.distance.toFixed(2)} km away</p>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
            )}
            {/* Cities Carousel Section */}
            <div className="cities-section py-8 px-8">
                <h2 className="text-2xl font-bold text-center mb-4">Cities to Explore</h2>
                <Slider {...getSliderSettings(cities.length)}>
                    {cities.map((city, index) => (
                        <div key={index} className="city-card px-2">
                            <div className="bg-gray-800 text-white rounded-lg shadow-md p-4 cursor-pointer" onClick={() => navigate(`/user-dashboard/explore/c/${city.name}`)}>
                                <img
                                    src={city.img}
                                    alt={city.name}
                                    className="w-full h-32 object-cover rounded-md mb-2"
                                />
                                <h2 className="text-xl font-bold">{city.name}</h2>
                                <p className="text-gray-400">Explore the flavors of {city.name}</p>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>

            {/* Food Varieties Carousel Section */}
            <div className="food-varieties-section py-8 px-8 bg-gray-800">
                <h2 className="text-2xl font-bold text-center mb-4">Food Varieties</h2>
                <Slider {...getSliderSettings(foodVarieties.length)}>
                    {foodVarieties.map((food, index) => (
                        <div key={index} className="food-card px-2">
                            <div className="bg-gray-700 text-white rounded-lg shadow-md p-4">
                                <img
                                    src={food.img}
                                    alt={food.name}
                                    className="w-full h-32 object-cover rounded-md mb-2"
                                />
                                <h2 className="text-xl font-bold">{food.name}</h2>
                                <p className="text-gray-400">Explore the flavors of {food.name}</p>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
};

export default ExploreMain;
