import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import restaurantData from './resturants_data.json';
import { FaMapMarkerAlt, FaClock } from 'react-icons/fa';
import { Pagination } from 'swiper/modules';
const RestaurantPage = () => {
  const {uuid}=useParams();
  const data = restaurantData[uuid];
  const {
    name, avgRating, ratingsCount, address, city, images,
    tags, deals, menuGroups, openingHours, reviews,zipCode
  } = data;

  // Refs for sections to enable smooth scrolling
  const dealsRef = useRef(null);
  const menuRef = useRef(null);
  const hoursRef = useRef(null);
  const locationRef = useRef(null);
  const reviewsRef = useRef(null);

  // Smooth scrolling function
  const handleScroll = (ref) => {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="bg-gray-900 text-white min-h-screen p-4 md:p-8 space-y-6 md:space-y-8">
      {/* Image Slider Section */}
      {/* <Swiper
        slidesPerView={1}
        spaceBetween={10}
        loop={true}
        pagination={{ clickable: true }}
        className="rounded-lg md:hidden" // Only show on mobile
      >
        {images.slice(0, 3).map((img, index) => (
          <SwiperSlide key={index}>
            <img
              src={img.url}
              alt={`Slide ${index}`}
              className="w-full h-48 object-cover rounded-lg"
            />
          </SwiperSlide>
        ))}
      </Swiper> */}

      {/* Desktop Image Slider (hidden on mobile) */}
      <Swiper
      slidesPerView={1}
      spaceBetween={10}
      loop={true}
      pagination={{ clickable: true }}
      modules={[Pagination]}
      breakpoints={{
        // when window width is >= 768px (md breakpoint in Tailwind)
        768: {
          slidesPerView: 3,
        },
      }}
      className="rounded-lg"
    >
      {images.map((img, index) => (
        <SwiperSlide key={index}>
          <img
            src={img.url}
            alt={`Slide ${index}`}
            className="w-full h-48 md:h-60 object-cover rounded-lg"
          />
        </SwiperSlide>
      ))}
    </Swiper>

      {/* Header Section */}
      <div className="flex flex-col md:flex-row md:justify-between md:items-center mt-4 md:mt-6">
        {/* Left Section: Title, Rating, Tags, and Price */}
        <div>
          <h1 className="text-3xl md:text-4xl font-bold">{name}</h1>

          <div className="flex flex-wrap items-center space-x-2 mt-1">
            <span className="flex items-center text-green-500 text-xl font-semibold">
              <span>⭐</span>
              <span className="ml-1">{avgRating}</span>
            </span>
            <span className="text-gray-500">({ratingsCount} Reviews)</span>
            <span className="text-gray-500 hidden md:inline">|</span>
            <span className="text-gray-500 w-full md:w-auto">
              {tags.map((tag, index) => (
                <span key={index}>
                  {tag.name}
                  {index < tags.length - 1 && ', '}
                </span>
              ))}
            </span>
            <span className="text-gray-500">| ££££</span>
          </div>
        </div>

        <div className="flex flex-col items-start md:items-end space-y-1 mt-2 md:mt-0">
          <div className="flex items-center gap-2">
            <FaMapMarkerAlt className="text-gray-400" style={{ fontSize: '16px' }} />
            <p className="text-gray-400 text-base md:text-lg leading-none">
             {address},{zipCode}
            </p>
          </div>
          <div className="flex items-center gap-2">
            <FaClock className="text-gray-400" style={{ fontSize: '16px' }} />
            <p className="text-gray-400 text-base md:text-lg leading-none">
              Timings <span className="ml-1">
  {`${openingHours[0].openingTime} - ${openingHours[0].closingTime}`}
</span>
            </p>
          </div>
        </div>
      </div>

      {/* Sub-navigation Tabs */}
      <div className="flex flex-wrap justify-start md:justify-start space-x-4 md:space-x-6 border-b border-gray-700 pb-2 mt-4 overflow-x-auto">
        <button onClick={() => handleScroll(dealsRef)} className="text-white focus:border-b-2 border-green-400 whitespace-nowrap">
          Deals
        </button>
        <button onClick={() => handleScroll(menuRef)} className="text-white focus:border-b-2 border-green-400 whitespace-nowrap">
          Menu
        </button>
        <button onClick={() => handleScroll(hoursRef)} className="text-white focus:border-b-2 border-green-400 whitespace-nowrap">
          Opening hours
        </button>
        <button onClick={() => handleScroll(locationRef)} className="text-white focus:border-b-2 border-green-400 whitespace-nowrap">
          Location
        </button>
        <button onClick={() => handleScroll(reviewsRef)} className="text-white focus:border-b-2 border-green-400 whitespace-nowrap">
          Reviews
        </button>
      </div>

      {/* Deals Section */}
      <div ref={dealsRef} className="mt-6 md:mt-8">
        <h2 className="text-xl md:text-2xl font-semibold mb-4">Exclusive WorldLynk Deals</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {deals.map((deal) => (
            <div key={deal.uuid} className="bg-orange-600 p-4 rounded-lg shadow-md">
              <h3 className="text-lg font-bold">{deal.name}</h3>
              <p className="mt-2">{deal.conditions}</p>
              <div className="flex justify-between mt-2 text-sm text-gray-100">
                <span>Value: £{deal.value}</span>
                <span>{deal.daysToReset} days</span>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Menu Section */}
      <div ref={menuRef} className="mt-6 md:mt-8">
        <h2 className="text-xl md:text-2xl font-semibold mb-4">Menu</h2>
        {menuGroups.map((group) => (
          <div key={group.uuid} className="mb-6">
            <h3 className="text-lg md:text-xl font-bold">{group.name}</h3>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-2">
              {group.menuItems.map((item) => (
                <div key={item.uuid} className="bg-gray-800 p-4 rounded-lg shadow-md">
                  <h4 className="text-lg font-semibold">{item.name}</h4>
                  <p className="mt-1 text-gray-300">{item.description}</p>
                  <span className="block mt-2 font-bold">£{item.price.toFixed(2)}</span>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      {/* Opening Hours Section */}
      <div ref={hoursRef} className="mt-6 md:mt-8">
        <h2 className="text-xl md:text-2xl font-semibold mb-4">Opening Hours</h2>
        <ul className="space-y-1">
          {openingHours.map((hour) => (
            <li key={hour.uuid} className="flex items-center gap-4">
              <span className="capitalize w-24 md:w-32">{hour.day}</span>
              <span>{hour.openingTime} - {hour.closingTime}</span>
            </li>
          ))}
        </ul>
      </div>

      {/* Location Section */}
      <div ref={locationRef} className="mt-6 md:mt-8">
        <h2 className="text-xl md:text-2xl font-semibold mb-4">Location</h2>
        <p className="text-gray-300">{address}, {city.name}</p>
      </div>

      {/* Reviews Section */}
      <div ref={reviewsRef} className="mt-6 md:mt-8">
        <h2 className="text-xl md:text-2xl font-semibold mb-4">Reviews</h2>
        <div className="text-green-400 text-3xl md:text-4xl font-bold">⭐ {avgRating}</div>
        <p className="text-gray-400 mb-4">({ratingsCount} Reviews, {reviews.length} written)</p>
        <div className="space-y-4">
          {reviews.map((review) => (
            <div key={review.uuid} className="bg-gray-800 p-4 rounded-lg shadow-md">
              <h4 className="text-lg font-bold">{review.name}</h4>
              <p className="mt-2 text-gray-300">{review.review}</p>
              <span className="block mt-2 text-green-400">⭐ {review.rating}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RestaurantPage;