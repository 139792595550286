import React from 'react';
import { useNavigate } from 'react-router-dom';

const ProfileUpdateDialog = ({ closeDialog }) => {
  const navigate = useNavigate();

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-gray-800 p-6 rounded-lg shadow-lg w-96 text-center text-white">
        <h2 className="text-xl font-bold mb-4">Profile Update Required</h2>
        <p className="mb-6">Please update your profile for a better experience.</p>

        <div className="flex justify-center space-x-4">
          {/* Update Profile Button */}
          <button
            onClick={() => {
              closeDialog(); // Close the dialog
              navigate('/user-dashboard/profile'); // Navigate to update page
            }}
            className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
          >
            Update Profile
          </button>

          {/* Skip Button */}
          <button
            onClick={() => closeDialog(true)} // Skip and pass "true" to store skip state
            className="bg-gray-600 text-white px-4 py-2 rounded hover:bg-gray-500"
          >
            Skip
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfileUpdateDialog;
